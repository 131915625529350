import React, { Component } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

const readCookie = require('../../../../cookie.js').readCookie;
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../config/urlConfigs.js';
import { Input } from 'antd';

export default class GetParticipantModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search :"",
            isResponse:true,
            searchValue:"",
            participants: null,
            opportunityId :props.opportunityId,
            isSelected :false,
            searchData:false
		}
		this.submitApply = this.submitApply.bind(this);
        this.search = this.search.bind(this);
        
	
	}

	notifyError = (text) => toast.error(text);

	submitApply() {
		this.setState({ isResponse : false })
            try {             
                this.props.Action(this.state.participants)  
            }catch(error){
                console.log("error =========",error);
                swal({
                    title:"Oops...",
                    text: "Something went wrong!",
                    type: "error",
                    showCancelButton: true,
                    showConfirmButton: false,
                    // confirmButtonText: "Yes, I backout!",
                    cancelButtonText: "Ok",
                  })
            }
            
        }
        
    search() {

        let urlParams = this.state.searchValue;
        
        fetch(configs.BACKEND_API_URL + `/employee-by-ecn/${urlParams}`, {
            method: "GET",
            headers: {
                'Auth': JSON.parse(readCookie('access_token')).access_token
            },
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            if(data.status === "ok"){

                console.log(data,'data');
                    
                this.setState({searchData:data.data,
                    participants:data.data
                })
            }  else {
                // this.notifyError("User with this ECN not found");
                // this.setState({participants: null,isSelected:false});
                swal({
                    title: "User with this ECN not found",
                    // text: "Please contact your admin",
                    // type: "warning",
                    showCancelButton: true,
                    showConfirmButton: false,
                    // confirmButtonText: "Yes, I backout!",
                    cancelButtonText: "Ok",
                  })
                  .then((result)=>{
                    console.log("result =====",result);
                    if(result){
                        this.props.HideGetParicipantModal();
                    }
                  });
            }
        }.bind(this));
    }

    handleDivClick = () => {
        this.setState(prevState => ({ isSelected: !prevState.isSelected }));
      }


	render() {
		return (
			<Modal show={true} onHide={this.props.HideGetParicipantModal} className="square-modal" bsSize='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Search Employee</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
					
						<Col md={10} >
                            <div style={{  
                                "display": "flex", 
                                "flexDirection": "column",
                                "justifyContent": "center",
                                "alignItems": "stretch"}}>
                                    <Row style={{display:"flex",flexDirection:"row"}}>
                                    <p style={{marginTop:"5px"}}>Search By ECN</p> 
                                    <Input placeholder='Search by ECN ' 
                                    style={{width:"70%",marginLeft:"4%"}} 
                                    value={this.state.searchValue}
                                    onChange={(e) =>  {
                                        this.setState({ searchValue:e.target.value });
                                        // this.search(e.target.value);
                                
                                }}
                                    />
                                    <Button className="btn btn-success btn-primary" onClick={this.search} 
                                    style={{ marginLeft:"14px", height: "27px",fontSize:"12px" }}>Search </Button>
                                    </Row>
                                    <note style={{fontWeight:700,marginLeft: "2px",marginTop: "10px" }}>Note: Kindly type full ECN.</note>
                          </div>
						</Col>
                       
                        <Row style={{margin: 0 }}>
                        {this.state.participants && this.state.participants.userId ? 
                        <>
                        <Col md={3} sm={2} style={{ padding: 0 }}>
                       <label className="control-label ml5" style={{ fontWeight: 'bold' }} >ECN</label>
                           </Col>
                        <Col md={3} sm={2} style={{ padding: 0 }}>
                       <label className="control-label ml5" style={{ fontWeight: 'bold' }} >Name</label>
                     </Col>
                   <Col md={3} sm={2} style={{ padding: 0 }}>
                       <label className="control-label ml5" style={{ fontWeight: 'bold' }}  >Department</label>
                   </Col>
                   <Col md={3} sm={2} style={{ padding: 0 }}>
                       <label className="control-label ml5" style={{ fontWeight: 'bold' }}  >Office</label>
                   </Col> </> : null}
                   
           {this.state.participants && this.state.participants ?
                      <div  onClick={() => this.setState(prevState => ({ isSelected: !prevState.isSelected }))} style={{cursor:"pointer"}}>
                      <Col md={3} sm={2} style={this.state.isSelected ? { padding: 0, backgroundColor: '#84181F',cursor:"pointer" } : { padding: 0,cursor:"pointer" }}>
                       <label className="control-label ml5" style={this.state.isSelected ? {color :"white",fontWeight :"bold",cursor:"pointer",marginTop: "4px",height: "54px"}:{ fontWeight: 'bold',cursor:"pointer", marginTop: "4px",height: "54px"}} >{this.state.participants.employeeId}</label>
                           </Col>
                        <Col md={3} sm={2} style={this.state.isSelected ? { padding: 0, backgroundColor: '#84181F',cursor:"pointer" } :{ padding: 0,cursor:"pointer" }}>
                       <label className="control-label ml5" style={this.state.isSelected ? {color :"white",fontWeight :"bold",cursor:"pointer",marginTop: "4px",height: "54px"}:{ fontWeight: 'bold',cursor:"pointer",marginTop: "4px" ,height: "54px"}} >{this.state.participants.fullName}</label>
                     </Col>
                   <Col md={3} sm={2} style={this.state.isSelected ? { padding: 0, backgroundColor: '#84181F',cursor:"pointer" } :{ padding: 0,cursor:"pointer" }}>
                       <label className="control-label ml5" style={this.state.isSelected ? {color :"white",fontWeight :"bold",cursor:"pointer" ,marginTop: "4px",height: "54px"}:
                       { fontWeight: 'bold' ,cursor:"pointer",marginTop: "4px",height: "54px"}}  >{this.state.participants.department.substring(0,30)}</label>
                   </Col>
                   <Col md={2} sm={2} style={this.state.isSelected ? { padding: 0, backgroundColor: '#84181F',cursor:"pointer" } : { padding: 0,cursor:"pointer" }}>
                       <label className="control-label ml5" style={this.state.isSelected ? {color :"white",fontWeight :"bold",cursor:"pointer",marginTop: "4px",height: "54px"}:
                       { fontWeight: 'bold',cursor:"pointer",marginTop: "4px",height: "54px" }}  >
                        {this.state.participants.office.substring(0,30)}</label>
                   </Col>
                  </div>
                    :null}
                        </Row>
					</Row>
				</Modal.Body>
				<Modal.Footer>
				<Button type="button"
                    className="btn btn-primary " 
                style={{marginRight: "36%"}}onClick={this.submitApply} disabled={!this.state.isSelected}>{this.props?.SearchButtonText?this.props.SearchButtonText:'Add the above employee As Admin '}</Button>
                </Modal.Footer>
                <ToastContainer
						position="bottom-center"
						autoClose={50000}
						hideProgressBar={true}
						closeOnClick={false}
						newestOnTop={false}
						pauseOnHover={true}
					/>
			</Modal>
		);
	}
}
