import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import swal from "sweetalert2";

import CreateListModal from "./CreateListModal";

import "sweetalert2/dist/sweetalert2.min.css";
import { OrgDataContext } from "../../../../routes";
import configs from "../../../../config/urlConfigs";
import AllEmployeesTable from "./AllEmployeesTable";
import { Switch } from "antd";
import { CSVDownload, CSVLink } from "react-csv";
import { edit } from "react-icons-kit/fa/edit.js";

const readCookie = require("../../../../cookie.js").readCookie;

export default class ManageTeams extends Component {
  constructor() {
    super();
    this.state = {
      createListModalVisibility: false,
      lists: [],
	  alllists:[],
      subdomain: configs.SUBDOMAIN || "",
      memberCount: 0,
      allEmployees: [],
      departmentsCsv: [],
      showDepts: false,
      searchmember: "",
	edit:false
    };

    this.searchInputRef = React.createRef();


    this.showCreateListModal = this.showCreateListModal.bind(this);
    this.hideCreateListModal = this.hideCreateListModal.bind(this);
	this.handleSearch=this.handleSearch.bind(this)
  }
  componentDidUpdate(){
	if(this.state.edit){
		this.searchInputRef.current.focus()
	}
  }
  getDepartmentsList() {
    fetch(configs.BACKEND_API_URL + "/list", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          const departmentsCsv = (data.lists || []).map((x) => ({
            "Department Name": x.name,
            Count: x.memberCount,
          }));
          this.setState({
			alllists:data.lists,
            lists: data.lists,
            memberCount: data.memberCount || 0,
            departmentsCsv,
          });
        }.bind(this)
      );
  }
  getAllEmployeesList() {
    fetch(configs.BACKEND_API_URL + "/all-employees-details", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.setState({ allEmployees: data.allEmployees });
        }.bind(this)
      );
  }
  componentDidMount() {
    this.getDepartmentsList();
    this.getAllEmployeesList();
  }

  showCreateListModal() {
    this.setState({ createListModalVisibility: true });
  }

  deleteList(index) {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete!",
      cancelButtonText: "No, Cancel!",
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
    }).then(
      function (result) {
        if (result) {
          let list_id = this.state.lists[index].id;
          fetch(configs.BACKEND_API_URL + "/list/" + list_id, {
            method: "DELETE",
            headers: {
              Auth: JSON.parse(readCookie("access_token")).access_token,
            },
          })
            .then((response) => response.json())
            .then(
              function (data) {
                if (data.status === "ok") {
                  fetch(configs.BACKEND_API_URL + "/list", {
                    method: "GET",
                    headers: {
                      Auth: JSON.parse(readCookie("access_token")).access_token,
                    },
                  })
                    .then(function (response) {
                      return response.json();
                    })
                    .then(
                      function (data) {
                        this.setState({ alllists:data.lists,
								lists: data.lists });
                      }.bind(this)
                    );
                }
              }.bind(this)
            );
        }
      }.bind(this)
    );
  }

  hideCreateListModal() {
    this.setState({ createListModalVisibility: false });
    fetch(configs.BACKEND_API_URL + "/list", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.setState({ alllists:data.lists,
			lists: data.lists });
        }.bind(this)
      );
  }

  handleSearch(event) {
    const searchQuery = event.target.value.toLowerCase();
      
	 console.log(this.searchInputRef.current);
	 
 
      const filteredlist = this.state.alllists.filter(item =>
        item.name.toLowerCase().includes(searchQuery)
      );
       
	  this.setState({
		searchmember:searchQuery,
		lists:filteredlist,
		edit:true
	  })
	  
     
  }

  render() {
	console.log(this.state.alllists,'sad');
	
    const ToggleSwitch = () => (
      <span
        className="toggle-switch-members"
        style={{
          position: "absolute !important",
          right: "80px !important",
          fontSize: "14px !important",
        }}
      >
        Department View
        <Switch
          onChange={(e) => this.setState({ showDepts: e })}
          checked={this.state.showDepts}
        />
        Member View
      </span>
    );
    const ListsComponent = ({ organisationData }) => (
      <div className="ee-page-container manage-team-lists-page">
        <div className="header">
          {this.state.subdomain === "indusind" ? (
            <p className="ee-page-title">
              Department ({this.state.memberCount} members)
              <ToggleSwitch />
            </p>
          ) : (
            <p className="ee-page-title">Office/City</p>
          )}
          {organisationData.ssoEnabled ? null : (
            <button
              className="btn btn-primary"
              onClick={this.showCreateListModal}
            >
              Add Office/City
            </button>
          )}
        </div>
        <div className="lists-table-container">
          <div style={{ display:"flex",justifyContent:'space-between',flexDirection:'row',alignItems:'center'}}>
            <CSVLink
              data={this.state.departmentsCsv}
              filename="departmentwise_member_count.csv"
              // className='hidden'
              // ref={csvLink}
              target="_blank"
            >
              <button
                type="button"
                className="btn btn-success react-bs-table-csv-btn  hidden-print"
                style={{
                  marginBottom: "5px",
                  background: "var(--themeColor)",
                  borderColor: "var(--themeColor)",
                  boxShadow: "rgb(81, 83, 86) 1px 1px 1px",
                }}
              >
                <span>
                  <i className="fa glyphicon glyphicon-export fa-download"></i>{" "}
                  Export to CSV
                </span>
                {/* <CSVDownload data={this.state.departmentsCsv} target="_blank" /> */}
              </button>
            </CSVLink>
            {/* <CSVLink
					data={this.state.lists}
					filename='departments_breakup.csv'
					className='hidden'
					ref={csvLink}
					target='_blank'
					/> */}
					<div>
            <input  ref={this.searchInputRef}
				style={{
				    height: '30px',
					padding: '5px 10px',
					backgroundColor:' #fff',
    				border:' 1px solid #ccc',
					boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
    				transition: 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
					width:250,
					marginRight:40

			}} value={this.state.searchmember} placeholder="Search by Department Name"
			onChange={this.handleSearch} type="text" />
          </div>
          </div>
           
          <div className="lists-table">
            {this.state.subdomain === "indusind" ? (
              this.state.lists.length === 0 ? (
                <h4 className="no-list">No Department added.</h4>
              ) : null
            ) : this.state.lists.length === 0 ? (
              <h4 className="no-list">
                No Offices added. Add Office/City to view them.
              </h4>
            ) : null}
            {this.state.lists.map(
              function (list, index) {
                return (
                  <div className="list-conatiner" key={list.id}>
                    <div className="list-details-container">
                      <a href={"/admin/team-management/" + list.id}>
                        <span className="list-name">{list.name}</span>
                      </a>
                      {list.hasOwnProperty("created_at") ? (
                        <span className="list-created-at">
                          Created {list.created_at}
                        </span>
                      ) : null}
                    </div>
                    {list.hasOwnProperty("subscribers") ? (
                      <div className="list-subscribers-container">
                        <span>{list.subscribers}</span>
                        <span>Subscribers</span>
                      </div>
                    ) : (
                      <div className="list-subscribers-container"></div>
                    )}
                    {list.hasOwnProperty("opens") ? (
                      <div className="list-opens-container">
                        <span>{list.opens + "%"}</span>
                        <span>Opens</span>
                      </div>
                    ) : (
                      <div className="list-opens-container"></div>
                    )}
                    {list.hasOwnProperty("clicks") ? (
                      <div className="list-clicks-container">
                        <span>{list.clicks + "%"}</span>
                        <span>Clicks</span>
                      </div>
                    ) : (
                      <div className="list-clicks-container"></div>
                    )}
                    {list.hasOwnProperty("memberCount") ? (
                      <div className="list-count-container">
                        <a href={"/admin/team-management/" + list.id}>
                          <span>{list.memberCount}</span>
                        </a>
                        <span>
                          {list.memberCount === 1 ? "Member" : "Members"}
                        </span>
                      </div>
                    ) : (
                      <div className="list-count-container"></div>
                    )}
                    {list.hasOwnProperty("groups") ? (
                      <div className="list-count-container groups">
                        <a href={"/lists/" + list.id + "/segments"}>
                          <span>{list.groups}</span>
                        </a>
                        <span>
                          {list.groups === 1 ? "Segment" : "Segments"}
                        </span>
                      </div>
                    ) : (
                      <div className="list-count-container groups"></div>
                    )}
                    {/*	<div className="list-count-container">
											<a href={"/lists/" + list.id + "/segments"}><span>0</span></a>
											<span>Segments</span>
										</div>
									)*/}
                    <div className="list-delete-container">
                      {list.hasOwnProperty("system_generated") &&
                      list.system_generated ? null : organisationData.ssoEnabled ? null : (
                        <button
                          className="btn btn-primary"
                          onClick={this.deleteList.bind(this, index)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                );
              }.bind(this)
            )}
          </div>
        </div>
        {this.state.createListModalVisibility ? (
          <CreateListModal
            ngo_id="asdad"
            hideCreateListModal={this.hideCreateListModal}
            showCreateListModal={this.state.createListModalVisibility}
          />
        ) : null}
      </div>
    );

    const EmployeeTableComponent = ({ organisationData }) => (
      <div className="ee-page-container manage-team-lists-page">
        <div className="header">
          {this.state.subdomain === "indusind" ? (
            <p className="ee-page-title">
              Department ({this.state.memberCount} members)
              <ToggleSwitch />
            </p>
          ) : (
            <p className="ee-page-title">Office/City</p>
          )}
          {organisationData.ssoEnabled ? null : (
            <button
              className="btn btn-primary"
              onClick={this.showCreateListModal}
            >
              Add Office/City
            </button>
          )}
        </div>
        {this.state.allEmployees ? (
          <Row>
            <AllEmployeesTable employeesData={this.state.allEmployees || []}
            memberCount={this.state.memberCount} />
          </Row>
        ) : null}
      </div>
    );
    return (
      <OrgDataContext.Consumer>
        {(organisationData) => {

          return !this.state.showDepts ? (
            <ListsComponent organisationData={organisationData} />
          ) : (
            <EmployeeTableComponent organisationData={organisationData}
            memberCount={this.state.memberCount||0} />
          );
        }}
      </OrgDataContext.Consumer>
    );
  }
}
