import React, { Component } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Modal,
  Button,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DocumentTitle from "react-document-title";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import swal from "sweetalert2";
import Icon from "react-icons-kit";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import { Menu, Rate } from "antd";
import io from "socket.io-client";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { calendar } from "react-icons-kit/fa/calendar";
import { bus } from "react-icons-kit/fa/bus";
import { dotCircleO } from "react-icons-kit/fa/dotCircleO";
import { tshirt } from "react-icons-kit/ionicons/tshirt";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "sweetalert2/dist/sweetalert2.min.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import * as action from "../../../../../actions/EEDiscoverOpportunitiesAction";
import AdminDeclareModal from "./AdminDeclareModal";
import EmployeeTimeModal from "./EmployeeTimeModal";
import DeclareModal from "../../MyVolunteerHistory/DeclareModal";

import SingleOpportunityMap from "./SingleOpportunityMap";
import SingleOpportunityApplyModal from "./SingleOpportunityApplyModal";
import SingleOpportunityDiscussion from "./SingleOpportunityDiscussion";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";
import { arrows_circle_remove } from "react-icons-kit/linea/arrows_circle_remove";
import SvgIcon from "react-icons-kit";
import { close } from "react-icons-kit/fa/close";
import { check } from "react-icons-kit/fa/check";
import { question } from "react-icons-kit/fa/question";
import { ic_hourglass_empty } from "react-icons-kit/md/ic_hourglass_empty";
import { edit } from "react-icons-kit/fa/edit";
import { link } from "react-icons-kit/icomoon/link";
import parse from "html-react-parser";
import SingleOpportunityGallery from "./SingleOpportunityGallery";
import SingleOpportunityAnalytics from "../../../Admin/4-ProjectManagement/VolunteerOpportunities/SingleOpportunityAnalytics";
import SingleOpportunityBackoutModal from "./SingleOpportunityBackoutModal";
import DisclaimerModal from "./DisclaimerModal";
import SingleOpportunityParticipantsList from "./SingleOpportunityParticipantsList";
import configs from "../../../../../config/urlConfigs";
const readCookie = require("../../../../../cookie.js").readCookie;
import { OrgDataContext } from "../../../../../routes";
import FamilyGuestFriendCertificateModal from "./FamilyGuestCertificateModal";
import { generatePreSignedGetUrl } from "../../../../../s3PresignedUrls";
import { Rating } from "react-simple-star-rating";
import AddParticipantsModal from "./AddParticipantsModal";
import 'quill/dist/quill.snow.css'; 
let checkStartTime;

const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];
const ACTIVETABINDEX = ["", "analytics", "gallery", "participants", "feedback"];
class SingleOpportunity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalEmployeeHours: "00:00",
      totalGuestHours: "00:00",
      showAdminDeclareModal: false,
      showEmployeeTimeModal: false,
      showEmployeeTimeModal: false,
      Declared_Attendance: "Pending",
      attendanceValidation: true,
      showReserveModal: false,
      showViewPeopleModal: false,
      address: "",
      key: 1,
      feedbacks: [],
      showAddToCalendarModal: false,
      addToCalendarEvent: null,
      currentLatitude: null,
      currentLongitude: null,
      endTime: null,
      editingRowIndex: null,
      distance: null,
      startTimerConfirmModalVisibility: false,
      cannotStartTimerModalVisibility: false,
      startedTimerModalVisibility: false,
      endTimerModalVisibility: false,
      submitFeedbackModalVisibility: false,
      onlySubmitFeedback: false,
      wontAttendModalVisibility: false,
      wontAttendReason: "",
      showOptionEditModal: false,
      propsData: null,
      adminDeclaredAttendance: true,
      selectedParticipant: null,
      saveChanges: false,
      attendeeId: null,
      participantsData: null,
      adminName: "N/A",
      attendeesData: null,
      facilities: [],
      safetyProtocol: "",
      dos: [],
      error: [],
      projectLeaderContact: "",
      registrationsAllowed: 0,
      helpfulDocument: null,
      backoutWindow: 0,
      activeTab: 0,
      displayBackoutModal: false,
      friendsList: [],
      showDropdown: false,
      haveAnotherReservation: false,
      employeeAttendanceOverride: false,
      friendsList: [],
      ShowFriendsCertificateModal: false,
      presentAttendeeNumber: null,
      userRole: props.role,
      tableData: [],
      images: [],
      currentUserUploadsCount: 0,
      loading: true,
      imageArray: [],
      mergeData: [],
      friendsListData: [],
      getGallery: true,
      getFeedback: true,
      getfriendList: true,
      isImages: false,
      isFeedBack: false,
      isAddParticipants :false,
			userdata:false,
      volunteerOpportunitydetail:false,
      addGroupsWithinActivity:false,
			activityGroups:false,
			addTransportRoutes:false,
			transportRoutes:false,
			addUnitOfMeasurement:false,
			unitOfMeasurement:false,
      allowfeedback:true
    };
    // this.optionHandleChangeAttendance = this.optionHandleChangeAttendance.bind(this);
    // this.approverName=this.approverName.bind(this);
    this.closeReserveModal = this.closeReserveModal.bind(this);
    this.openReserveModal = this.openReserveModal.bind(this);
    this.closeViewPeopleModal = this.closeViewPeopleModal.bind(this);
    this.openViewPeopleModal = this.openViewPeopleModal.bind(this);
    this.getVolunteeringHours = this.getVolunteeringHours.bind(this);
    this.editButton = this.editButton.bind(this);
    this.adminDeclaredAttendance = this.adminDeclaredAttendance.bind(this);
    this.adminName = this.adminName.bind(this);
    this.declaredAttendance = this.declaredAttendance.bind(this);
    this.getTimeDiff = this.getTimeDiff.bind(this);
    this.goBack = this.goBack.bind(this);
    this.fetchOrgData = this.fetchOrgData.bind(this);
    this.fetchFeedbacks = this.fetchFeedbacks.bind(this);
    this.fetchImages = this.fetchImages.bind(this);
    this.fetchOpportunityDetails = this.fetchOpportunityDetails.bind(this);
    this.fetchUpdateOpportunityDetails =
      this.fetchUpdateOpportunityDetails.bind(this);
      this.closeAddParticipantsModal = this.closeAddParticipantsModal.bind(this);
      this.openAddParticipantsModal = this.openAddParticipantsModal.bind(this);
    // this.showTimeModal =this.showTi
    // this.optionEdit= this.optionEdit.bind(this);
    // this.pullData= this.pullData.bind(this);
    // this.onChange = this.onChange.bind(this);
  }

  closeAddParticipantsModal() {
    this.setState({ isAddParticipants : false });
  }
  openAddParticipantsModal() {
    this.setState({ isAddParticipants: true });
  }

  closeReserveModal() {
    this.setState({ showReserveModal: false });
  }

  openReserveModal() {
    console.log(this.state.volunteerOpportunitydetail.config);
    if( this.state.volunteerOpportunitydetail && this.state.userdata.city && !this.state.volunteerOpportunitydetail.config.cities.includes(this.state.userdata.city) &&
    this.state.volunteerOpportunitydetail.physicalOpportunity){

      swal({
        type: "warning",
        title: "Location Mismatch",
        html: `<p style='font-size:18px'>The city you are mapped to doesn't match the city of the event. Do you still want to register?</p>`,
        showCancelButton: true,
        confirmButtonText: "Apply Anyway",
      }).then((result) => {
        // console.log(result,'dsvs');
       if(result){
        this.setState({ showReserveModal: true });
       }
      }).catch((e) => { console.log(e);});
    }
    else{

      this.setState({ showReserveModal: true });
    }
    
  }

  closeViewPeopleModal() {
    this.setState({ showViewPeopleModal: false });
  }

  openViewPeopleModal() {
    this.setState({
      showViewPeopleModal: true,
      propsData: this.props,
      participantsData: this.props.selectedOpportunity.attendees,
    });
  }

  fetchOrgData() {
    fetch(configs.BACKEND_API_URL + "/get-company-preference", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          if (Object.keys(data.data).length) {
            this.setState({
              attendanceValidation: data.data.enableEmployeeAttendance,
              allowHistoricalEventCreation:
                data.data.allowHistoricalEventCreation === undefined
                  ? false
                  : data.data.allowHistoricalEventCreation,
            });
          }
        }.bind(this)
      );
  }
  componentDidMount() {
    this.fetchOrgData();
    let path = this.props.match.params.name;
    this.fetchOpportunityDetails();

    let activeTab = 0;
    path = path.toLowerCase();
    if (
      this.props.location.hash &&
      this.props.location.hash.includes("#") &&
      this.props.location.hash.split("#")[1] &&
      ACTIVETABINDEX.includes(this.props.location.hash.split("#")[1])
    ) {
      activeTab = ACTIVETABINDEX.indexOf(
        this.props.location.hash.split("#")[1]
      );

      this.fetchOrgData();
      this.fetchOpportunityDetails();
      this.fetchImages();
      this.fetchFeedbacks(this.props.match.params.name);

      this.setState({ activeTab });
    }
    let endPoint = "/get-user-data"
		fetch(configs.BACKEND_API_URL + endPoint, {
			method: "get",
			headers: {
				Auth: JSON.parse(readCookie("access_token")).access_token,
			},
		})
			.then((data) => data.json())
			.then((data) => {
				if (data.data) {
					// console.log("🚀 ~ file: TeamMember.js:175 ~ TeamMember ~ .then ~ data.data:", data.data)
					this.setState({
						userdata:data.data
					})
				}
			});
  }

  fetchOpportunityDetails() {
    let path = this.props.match.params.name;
      //  console.log("path =============",path);
    if (!this.props.selectedOpportunity.hasOwnProperty("customUrl")) {
      fetch(configs.BACKEND_API_URL + "/ee/volunteer-opportunity/" + path, {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      })
        .then((data) => data.json())
        .then(
          function (data) {
            this.setState({
              volunteerOpportunitydetail:data.data,
              facilities: data.data?.facilities ? data.data.facilities : [],
              safetyProtocol: data.data.safetyProtocol
                ? data.data.safetyProtocol
                : "",
              dos: data.data.dos ? data.data.dos : [],
              projectLeaderContact: data.data.projectLeaderContact
                ? data.data.projectLeaderContact
                : "",
              registrationsAllowed: data.data.registrationsAllowed
                ? data.data.registrationsAllowed
                : 0,
              helpfulDocument: data.data.helpfulDocument
                ? data.data.helpfulDocument
                : null,
              backoutWindow: data.data.backoutWindow
                ? data.data.backoutWindow
                : 0,
              haveAnotherReservation: data.data.haveAnotherReservation
                ? data.data.haveAnotherReservation
                : false,
              totalGuestHours: data?.tableData[0]?.guestHours,
              totalEmployeeHours: data.tableData[0].employeeHours,
              tableData: data.tableData,
              addGroupsWithinActivity: data.data.hasOwnProperty('addGroupsWithinActivity')?data.data.addGroupsWithinActivity?'Yes':'No':false,
              activityGroups: data.data.activityGroups?data.data.activityGroups:['',''],
              addTransportRoutes: data.data.hasOwnProperty('addTransportRoutes')?data.data.addTransportRoutes?'Yes':'No':false,
              transportRoutes: data.data.transportRoutes?data.data.transportRoutes:['',''],
              unitOfMeasurement: data.data.unitOfMeasurement?data.data.unitOfMeasurement:'',
              addUnitOfMeasurement: data.data.hasOwnProperty('addUnitOfMeasurement')?data.data.addUnitOfMeasurement?'Yes':'No':false,
         
            });
            this.setState({ attendeesData: data.data.attendees });
            path = path.toLowerCase();
            let activeTab = 0;
            if (
              this.props.location.hash &&
              this.props.location.hash.includes("#") &&
              this.props.location.hash.split("#")[1] &&
              ACTIVETABINDEX.includes(this.props.location.hash.split("#")[1])
            ) {
              activeTab = ACTIVETABINDEX.indexOf(
                this.props.location.hash.split("#")[1]
              );
              this.fetchOrgData();
              this.fetchImages(data.data.id);
              this.fetchFeedbacks(this.props.match.params.name);
              this.setState({ activeTab });
            }
            if (data.hasOwnProperty("data")) {
              this.initSocket(this);

              for (let i = 0; i < data.data.length; i++) {
                data.data.comments[i]["showReply"] = false;
              }
              let event = {
                title:
                  subdomain.toLowerCase() === "indusind"
                    ? "IBL " + data.data.title
                    : "Letsengage " + data.data.title,
                description: data.data.description,
                location: data.data.address1 || "",
                // start :"2019-12-29 09:00:00 +0100"
                start: moment(
                  moment(data.data.date, "DD/MM/YYYY").format("YYYY-MM-DD") +
                    " " +
                    data.data.startTime
                ).format("YYYY-MM-DD HH:mm:ss"),
                end: moment(
                  moment(data.data.date, "DD/MM/YYYY").format("YYYY-MM-DD") +
                    " " +
                    data.data.endTime
                ).format("YYYY-MM-DD HH:mm:ss"),
              };
              this.setState({ addToCalendarEvent: event });
              this.props.actions.updateSelectedOpportunity(data["data"]);
              if (data.data.address2) {
                this.setState({
                  address:
                    data.data.address1 +
                    ", " +
                    data.data.address2 +
                    ", " +
                    data.data.city +
                    ", " +
                    data.data.state +
                    ", " +
                    data.data.country +
                    ". " +
                    data.data.pincode,
                });
              } else {
                this.setState({
                  address:
                    data.data.address1 +
                    ", " +
                    data.data.city +
                    ", " +
                    data.data.state +
                    ", " +
                    data.data.country +
                    ". " +
                    data.data.pincode,
                });
              }

              if (data["data"].isOpen && data["data"].isUserAttendee) {
                var that = this;
                checkStartTime = setInterval(function () {
                  if (
                    data.data.date === moment().format("DD/MM/YYYY") &&
                    moment
                      .duration(
                        moment(data.data.startTime, "HH:mm").diff(moment())
                      )
                      .asMinutes() <= 0
                  ) {
                    data["data"].isOpen = false;
                    data["data"].status = "Ongoing";
                    that.props.actions.updateSelectedOpportunity(data["data"]);
                    that.forceUpdate();
                    clearInterval(checkStartTime);
                  }
                }, 10000);
              }

              fetch(
                configs.BACKEND_API_URL +
                  "/ee/friends-list/total/" +
                  data.data.id,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Auth: JSON.parse(readCookie("access_token")).access_token,
                  },
                }
              )
                .then(function (response) {
                  return response.json();
                })
                .then(
                  function (friends) {

                    let mergeData = data?.data?.attendees.map((itm) => ({
                      ...friends.data.find((item) => {
                        if (item.volunteerId === itm.id && item) {
                          // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:261 ~ SingleOpportunityParticipantsList ~ {mergeData ~ item:", item, itm)
                          let friendsNum = 0;
                          let sumFriendsTime = 0;
                          if (
                            this.props.selectedOpportunity.offsetHours &&
                            this.props.selectedOpportunity.offsetHours
                              .friendsAndFamily
                          ) {
                            sumFriendsTime = moment
                              .duration(
                                `${this.props.selectedOpportunity.offsetHours.friendsAndFamily.hours}:00`
                              )
                              .asMinutes();
                            friendsNum =
                              friendsNum +
                              (this.props.selectedOpportunity.offsetHours
                                .friendsAndFamily.count || 0);
                          }
                          item.friends.map((friendsData) => {
                            if (
                              friendsData.hasOwnProperty("attended") &&
                              friendsData.attended &&
                              (friendsData.adminUpdatedAttendance ||
                                itm.updatedParticipatedAttendence)
                            ) {
                              friendsNum++;
                            }
                          });
                          // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:272 ~ item.friends.map ~ friendsNum:", friendsNum)

                          if (itm.hasOwnProperty("hoursClocked")) {
                            let attendeeHours = itm.hoursClocked.split(":");
                            // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:276 ~ {mergeData ~ attendeeHours:", attendeeHours)
                            let attendeeUpdatedHours =
                              itm.updatedHoursTime !== ""
                                ? itm.updatedHoursTime.split(":")
                                : null;
                            // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:277 ~ {mergeData ~ attendeeUpdatedHours:", attendeeUpdatedHours)
                            let hoursClocked =
                              attendeeHours[0] * friendsNum +
                              ":" +
                              attendeeHours[1] * friendsNum;
                            // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:278 ~ SingleOpportunityParticipantsList ~ {mergeData ~ hoursClocked:", hoursClocked)
                            let updatedHours =
                              attendeeUpdatedHours !== null
                                ? attendeeUpdatedHours[0] * friendsNum +
                                  ":" +
                                  attendeeUpdatedHours[1] * friendsNum
                                : null;
                            sumFriendsTime += moment
                              .duration(updatedHours || hoursClocked)
                              .asMinutes();
                            let h = sumFriendsTime / 60,
                              m = sumFriendsTime % 60;

                            delete item["startTime"];
                            delete item["endTime"];
                            const guestHours = String(parseInt(h));
                            //  const guestMins = String(((m||0)%1)*60);
                            //  console.log("🚀 ~ file: SingleOpportunity ~ {mergeData ~ guestHours:", guestHours, guestMins, m);

                            item["guesthour"] = `${
                              guestHours.length > 1
                                ? guestHours
                                : "0" + guestHours
                            }:${String(m).length > 1 ? m : "0" + m}`;
                            return item["guesthour"];
                          } else if (itm.hasOwnProperty("updatedHoursTime")) {
                            let attendeeUpdatedHours =
                              itm.updatedHoursTime !== ""
                                ? itm.updatedHoursTime.split(":")
                                : null;
                            let updatedHours =
                              attendeeUpdatedHours !== null
                                ? attendeeUpdatedHours[0] * friendsNum +
                                  ":" +
                                  attendeeUpdatedHours[1] * friendsNum
                                : null;
                            sumFriendsTime += moment
                              .duration(updatedHours)
                              .asMinutes();
                            let h = sumFriendsTime / 60,
                              m = sumFriendsTime % 60;
                            delete item["startTime"];
                            delete item["endTime"];
                            item["guesthour"] = `${
                              h.length > 1 ? h : "0" + h
                            }:${m.length > 1 ? m : "0" + m}`;
                            //  console.log("----------------------------------------------------------------")
                            //  console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:286 ~ SingleOpportunityParticipantsList ~ mergeData ~ item[guesthour]:", item["guesthour"])
                            //  console.log("----------------------------------------------------------------")

                            return item["guesthour"];
                            //  return item["guesthour"] = moment().hours(h).minutes(m).format('HH:mm'), item["guestAttended"] =friendsNum,delete item['startTime'],delete item['endTime'];
                          }
                          return;
                        }
                      }),
                      ...itm,
                    }));

                    this.setState({ mergeData: mergeData });
                    this.setState({ friendsListData: friends.data });
                    this.setState({ getfriendList: false });
                    {
                      var num = 0;
                      //  var friendsVolunteer = 0;
                      if (
                        this.props.selectedOpportunity.offsetHours &&
                        this.props.selectedOpportunity.offsetHours
                          .friendsAndFamily
                      ) {
                        num =
                          num +
                          (this.props.selectedOpportunity.offsetHours
                            .friendsAndFamily.count || 0);
                      }
                    }
                    {
                      var sumTime = 0;
                      if (
                        this.props.selectedOpportunity.offsetHours &&
                        this.props.selectedOpportunity.offsetHours
                          .friendsAndFamily
                      ) {
                        sumTime = moment
                          .duration(
                            `${this.props.selectedOpportunity.offsetHours.friendsAndFamily.hours}:00`
                          )
                          .asMinutes();
                      }
                    }
                    friends.data.map((data) => {
                      let num = 0;
                      let userId = JSON.parse(readCookie("userData")).id;
                      if (data.volunteerId === userId) {
                        this.setState({ friendsList: data });
                        data.friends.map((data) => {
                          if (data.attended) {
                            num++;
                          }
                        });
                        this.setState({ presentAttendeeNumber: num });
                      }
                    });
                  }.bind(this)
                );
            } else {
              this.goBack();
            }
          }.bind(this)
        );

      // this.fetchFeedbacks(path);
    }
  }

  fetchUpdateOpportunityDetails() {
    let path = this.props.match.params.name;
    fetch(configs.BACKEND_API_URL + "/ee/volunteer-opportunity/" + path, {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then((data) => data.json())
      .then(
        function (data) {
          this.setState({
            facilities: data.data.facilities ? data.data.facilities : [],
            safetyProtocol: data.data.safetyProtocol
              ? data.data.safetyProtocol
              : "",
            dos: data.data.dos ? data.data.dos : [],
            projectLeaderContact: data.data.projectLeaderContact
              ? data.data.projectLeaderContact
              : "",
            registrationsAllowed: data.data.registrationsAllowed
              ? data.data.registrationsAllowed
              : 0,
            helpfulDocument: data.data.helpfulDocument
              ? data.data.helpfulDocument
              : null,
            backoutWindow: data.data.backoutWindow
              ? data.data.backoutWindow
              : 0,
            haveAnotherReservation: data.data.haveAnotherReservation
              ? data.data.haveAnotherReservation
              : false,
            totalGuestHours: data.tableData[0].guestHours,
            totalEmployeeHours: data.tableData[0].employeeHours,
            tableData: data.tableData,
          });
          this.setState({ attendeesData: data.data.attendees });
          path = path.toLowerCase();
          let activeTab = 0;
          if (
            this.props.location.hash &&
            this.props.location.hash.includes("#") &&
            this.props.location.hash.split("#")[1] &&
            ACTIVETABINDEX.includes(this.props.location.hash.split("#")[1])
          ) {
            activeTab = ACTIVETABINDEX.indexOf(
              this.props.location.hash.split("#")[1]
            );
            this.fetchOrgData();
            this.fetchImages(data.data.id);
            this.fetchFeedbacks(this.props.match.params.name);
            this.setState({ activeTab });
          }
          if (data.hasOwnProperty("data")) {
            this.initSocket(this);

            for (let i = 0; i < data.data.length; i++) {
              data.data.comments[i]["showReply"] = false;
            }
            let event = {
              title:
                subdomain.toLowerCase() === "indusind"
                  ? "IBL " + data.data.title
                  : "Letsengage " + data.data.title,
              description: data.data.description,
              location: data.data.address1 || "",
              // start :"2019-12-29 09:00:00 +0100"
              start: moment(
                moment(data.data.date, "DD/MM/YYYY").format("YYYY-MM-DD") +
                  " " +
                  data.data.startTime
              ).format("YYYY-MM-DD HH:mm:ss"),
              end: moment(
                moment(data.data.date, "DD/MM/YYYY").format("YYYY-MM-DD") +
                  " " +
                  data.data.endTime
              ).format("YYYY-MM-DD HH:mm:ss"),
            };
            this.setState({ addToCalendarEvent: event });
            this.props.actions.updateSelectedOpportunity(data["data"]);
            if (data.data.address2) {
              this.setState({
                address:
                  data.data.address1 +
                  ", " +
                  data.data.address2 +
                  ", " +
                  data.data.city +
                  ", " +
                  data.data.state +
                  ", " +
                  data.data.country +
                  ". " +
                  data.data.pincode,
              });
            } else {
              this.setState({
                address:
                  data.data.address1 +
                  ", " +
                  data.data.city +
                  ", " +
                  data.data.state +
                  ", " +
                  data.data.country +
                  ". " +
                  data.data.pincode,
              });
            }

            if (data["data"].isOpen && data["data"].isUserAttendee) {
              var that = this;
              checkStartTime = setInterval(function () {
                if (
                  data.data.date === moment().format("DD/MM/YYYY") &&
                  moment
                    .duration(
                      moment(data.data.startTime, "HH:mm").diff(moment())
                    )
                    .asMinutes() <= 0
                ) {
                  data["data"].isOpen = false;
                  data["data"].status = "Ongoing";
                  that.props.actions.updateSelectedOpportunity(data["data"]);
                  that.forceUpdate();
                  clearInterval(checkStartTime);
                }
              }, 10000);
            }

            fetch(
              configs.BACKEND_API_URL +
                "/ee/friends-list/total/" +
                data.data.id,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Auth: JSON.parse(readCookie("access_token")).access_token,
                },
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(
                function (friends) {

                  let mergeData = data?.data?.attendees.map((itm) => ({
                    ...friends.data.find((item) => {
                      if (item.volunteerId === itm.id && item) {
                        // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:261 ~ SingleOpportunityParticipantsList ~ {mergeData ~ item:", item, itm)
                        let friendsNum = 0;
                        let sumFriendsTime = 0;
                        if (
                          this.props.selectedOpportunity.offsetHours &&
                          this.props.selectedOpportunity.offsetHours
                            .friendsAndFamily
                        ) {
                          sumFriendsTime = moment
                            .duration(
                              `${this.props.selectedOpportunity.offsetHours.friendsAndFamily.hours}:00`
                            )
                            .asMinutes();
                          friendsNum =
                            friendsNum +
                            (this.props.selectedOpportunity.offsetHours
                              .friendsAndFamily.count || 0);
                        }
                        item.friends.map((friendsData) => {
                          if (
                            friendsData.hasOwnProperty("attended") &&
                            friendsData.attended &&
                            (friendsData.adminUpdatedAttendance ||
                              itm.updatedParticipatedAttendence)
                          ) {
                            friendsNum++;
                          }
                        });
                        // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:272 ~ item.friends.map ~ friendsNum:", friendsNum)

                        if (itm.hasOwnProperty("hoursClocked")) {
                          let attendeeHours = itm.hoursClocked.split(":");
                          // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:276 ~ {mergeData ~ attendeeHours:", attendeeHours)
                          let attendeeUpdatedHours =
                            itm.updatedHoursTime !== ""
                              ? itm.updatedHoursTime.split(":")
                              : null;
                          // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:277 ~ {mergeData ~ attendeeUpdatedHours:", attendeeUpdatedHours)
                          let hoursClocked =
                            attendeeHours[0] * friendsNum +
                            ":" +
                            attendeeHours[1] * friendsNum;
                          // console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:278 ~ SingleOpportunityParticipantsList ~ {mergeData ~ hoursClocked:", hoursClocked)
                          let updatedHours =
                            attendeeUpdatedHours !== null
                              ? attendeeUpdatedHours[0] * friendsNum +
                                ":" +
                                attendeeUpdatedHours[1] * friendsNum
                              : null;
                          sumFriendsTime += moment
                            .duration(updatedHours || hoursClocked)
                            .asMinutes();
                          let h = sumFriendsTime / 60,
                            m = sumFriendsTime % 60;

                          delete item["startTime"];
                          delete item["endTime"];
                          const guestHours = String(parseInt(h));
                          //  const guestMins = String(((m||0)%1)*60);
                          //  console.log("🚀 ~ file: SingleOpportunity ~ {mergeData ~ guestHours:", guestHours, guestMins, m);

                          item["guesthour"] = `${
                            guestHours.length > 1
                              ? guestHours
                              : "0" + guestHours
                          }:${String(m).length > 1 ? m : "0" + m}`;
                          //  console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
                          //  console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:286 ~ SingleOpportunityParticipantsList ~ mergeData ~ item[guesthour]:", item["guesthour"], h, m)
                          //  console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
                          return item["guesthour"];
                          //  return item["guesthour"] = moment().hours(h).minutes(m).format('HH:mm');
                          //  return item["guesthour"] = moment().hours(h).minutes(m).format('HH:mm'),item["guestAttended"] =friendsNum,delete item['startTime'],delete item['endTime'];
                        } else if (itm.hasOwnProperty("updatedHoursTime")) {
                          let attendeeUpdatedHours =
                            itm.updatedHoursTime !== ""
                              ? itm.updatedHoursTime.split(":")
                              : null;
                          let updatedHours =
                            attendeeUpdatedHours !== null
                              ? attendeeUpdatedHours[0] * friendsNum +
                                ":" +
                                attendeeUpdatedHours[1] * friendsNum
                              : null;
                          sumFriendsTime += moment
                            .duration(updatedHours)
                            .asMinutes();
                          let h = sumFriendsTime / 60,
                            m = sumFriendsTime % 60;
                          delete item["startTime"];
                          delete item["endTime"];
                          item["guesthour"] = `${h.length > 1 ? h : "0" + h}:${
                            m.length > 1 ? m : "0" + m
                          }`;
                          //  console.log("----------------------------------------------------------------")
                          //  console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:286 ~ SingleOpportunityParticipantsList ~ mergeData ~ item[guesthour]:", item["guesthour"])
                          //  console.log("----------------------------------------------------------------")

                          return item["guesthour"];
                          //  return item["guesthour"] = moment().hours(h).minutes(m).format('HH:mm'), item["guestAttended"] =friendsNum,delete item['startTime'],delete item['endTime'];
                        }
                        return;
                      }
                    }),
                    ...itm,
                  }));

                  this.setState({ mergeData: mergeData });
                  this.setState({ friendsListData: friends.data });
                  this.setState({ getfriendList: false });
                  {
                    var num = 0;
                    //  var friendsVolunteer = 0;
                    if (
                      this.props.selectedOpportunity.offsetHours &&
                      this.props.selectedOpportunity.offsetHours
                        .friendsAndFamily
                    ) {
                      num =
                        num +
                        (this.props.selectedOpportunity.offsetHours
                          .friendsAndFamily.count || 0);
                    }
                  }
                  {
                    var sumTime = 0;
                    if (
                      this.props.selectedOpportunity.offsetHours &&
                      this.props.selectedOpportunity.offsetHours
                        .friendsAndFamily
                    ) {
                      sumTime = moment
                        .duration(
                          `${this.props.selectedOpportunity.offsetHours.friendsAndFamily.hours}:00`
                        )
                        .asMinutes();
                    }
                  }
                  friends.data.map((data) => {
                    let num = 0;
                    let userId = JSON.parse(readCookie("userData")).id;
                    if (data.volunteerId === userId) {
                      this.setState({ friendsList: data });
                      data.friends.map((data) => {
                        if (data.attended) {
                          num++;
                        }
                      });
                      this.setState({ presentAttendeeNumber: num });
                    }
                  });
                }.bind(this)
              );
          } else {
            this.goBack();
          }
        }.bind(this)
      );
  }

  fetchFeedbacks(path) {
    fetch(
      configs.BACKEND_API_URL +
        "/ee/volunteer-opportunity/" +
        path +
        "/feedback",
      {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then((data) => data.json())
      .then(
        function (data) {
          this.setState({ feedbacks: data.data || [] });
          this.setState({ getFeedback: false });
          this.setState({ isFeedBack: true });
        }.bind(this)
      );
  }

  initSocket() {
    const socket = io(configs.BACKEND_API_URL);
    socket.on("connect", () => {
      socket.emit("authentication", {
        token: JSON.parse(readCookie("access_token")).access_token,
      });
      socket.on(
        "authenticated",
        function () {
          let socketData = {
            case: "volunteerOpportunityViewed",
            voId: this.props.selectedOpportunity.id,
          };
          socket.emit("VOLUNTEER_OPPORTUNITY_VIEWED", socketData);
        }.bind(this)
      );
    });
  }

  getDistance(lat1, lon1, lat2, lon2) {
    var R = 6371e3; // metres
    var φ1 = (lat1 * Math.PI) / 180;
    var φ2 = (lat2 * Math.PI) / 180;
    var Δφ = ((lat2 - lat1) * Math.PI) / 180;
    var Δλ = ((lon2 - lon1) * Math.PI) / 180;

    var a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return parseInt(d);
  }

  componentWillUnmount() {
    if (checkStartTime) clearInterval(checkStartTime);
  }

  checkEmpty(cell, row) {
    if (cell === "") return "N/A";
    else return cell;
  }

  backout() {
    // TODO - if (startTime - TimeNow) < backOutWindow  ? title: "We are afraid the event is too close for you to backout." ,
    // text: "Please contact your admin",
    // button: ONly cancel button (label: OK)
    const that = this;
    var allowBackout = true;
    if (this.state.backoutWindow) {
      const startMonth = this.props.selectedOpportunity.date.substring(3, 5);
      const startDate = this.props.selectedOpportunity.date.substring(0, 2);
      const startYear = this.props.selectedOpportunity.date.substring(6, 10);
      const newStartDate =
        `${startYear}-${startMonth}-${startDate}` +
        "T" +
        this.props.selectedOpportunity.startTime +
        ":00";

      const currentTime = moment().format("YYYY-MM-DD HH:MM");
      const currentTimeDate = currentTime.substring(0, 10);
      const currentTimeHours = currentTime.substring(11);
      const curretTimeFormat = currentTimeDate + "T" + currentTimeHours + ":00";

      var start = moment(newStartDate); //now
      var now = moment(curretTimeFormat);
      const diffInHours = start.diff(now, "hours");

      allowBackout = diffInHours > parseInt(this.state.backoutWindow);
    }
    if (allowBackout) {
      swal({
        title:
          "Do you wish to backout of this event or do you wish to update your guest list?",
        // text: '',
        type: "warning",
        showCancelButton:
          parseInt(this.props.selectedOpportunity.registrationsAllowed) > 0,
        confirmButtonText: "I want to backout",
        cancelButtonText: "I want to edit my guest list",
        showCloseButton: true,
      }).then(
        function () {
          that.backoutCompletely();
        },
        function (dismiss) {
          if (dismiss == "cancel") {
            that.setState({ displayBackoutModal: true });
          }
        }
      );
    } else {
      swal({
        title: "We are afraid the event is too close for you to backout.",
        text: "Please contact your admin",
        type: "warning",
        showCancelButton: true,
        showConfirmButton: false,
        // confirmButtonText: "Yes, I backout!",
        cancelButtonText: "Ok",
      });
    }
  }

  // editFriendsList() {
  //   this.setState({ displayBackoutModal: true })
  // }
  fetchImages(id) {
    let voId = id || this.props.selectedOpportunity.id;

    fetch(
      configs.BACKEND_API_URL +
        "/ee/volunteer-opportunity/" +
        voId +
        "/gallery-images",
      {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then((data) => data.json())
      .then(
        function (data) {
          if (data.status === "ok") {
            var that = this;
            const userData = JSON.parse(readCookie("userData"));
            let imageArray = data.images || [],
              updatedImages = [];
            let currentUserUploadsCount = 0;
            for (let i = 0; i < imageArray.length; i++) {
              const img = imageArray[i];
              if (userData.id === img.uploadedBy.id) {
                currentUserUploadsCount++;
              }
              const imgObject = {
                src: img.imageUrl,
                actualUploadedImageUrl: img.actualUploadedImageUrl,
                thumbnail: img.compressedImageUrl || img.imageUrl,
                thumbnailWidth: 400,
                thumbnailHeight: 300,
                isSelected: JSON.parse(readCookie("access_token")).id
                  ? true
                  : false,
                // caption: `${img.caption} - uploaded by: ${img.uploadedBy.name}`,
                caption: img.caption,
                uploadedBy: img.uploadedBy,
                likeDislike:img.hasOwnProperty('likeDislike')?img.likeDislike:{}
              };
              updatedImages.push(imgObject);
            }
            this.setState({ images: updatedImages });
            this.setState({ currentUserUploadsCount: currentUserUploadsCount });
            this.setState({ imageArray: imageArray.length });
            // this.decideAllowUpload(userData, currentUserUploadsCount, imageArray.length)
          }
          this.setState({ loading: false });
          this.setState({ isImages: true });
        }.bind(this)
      )
      .catch(() => {
        this.setState({ loading: false });
      });
  }
  backoutCompletely() {
    swal({
      title: "Sorry to see you going. ",
      text: "Please note that your guest list will also be deleted if you backout. Please try to find your replacement for this event.",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "I want to backout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result) {
        fetch(
          configs.BACKEND_API_URL +
            "/ee/volunteer-opportunity/" +
            this.props.selectedOpportunity.id +
            "/backout",
          {
            method: "GET",
            headers: {
              Auth: JSON.parse(readCookie("access_token")).access_token,
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(
            function (data) {
              if (data.status === "ok") {
                window.location.reload();
              }
            }.bind(this)
          );
      }
    });
  }

  handleSelect(key) {
    this.setState({ key });
  }

  goBack() {
    // if (document.referrer !== "" && document.referrer !== window.location.href)
    //   window.location.href = document.referrer;
    // else {
    if (this.props.role === "admin")
      window.location.pathname = "/volunteer-management";
    else window.location.pathname = "/discover-opportunities";
    // }
  }

  openLocationInMap(url) {
    window.open(url, "_blank");
  }

  checkEmpId(cell, row) {
    if (cell) {
      if (
        JSON.parse(readCookie("userData")).role === "admin" &&
        row.listId &&
        row.id
      ) {
        return (
          <a
            href={"/admin/team-management/" + row.listId + "/" + row.id}
            target="_blank"
          >
            {cell}
          </a>
        );
      } else return cell;
    } else return "N/A";
  }

  // All new Admin things
  onChange(e) {
    let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`);
    if (commentRegex.test(e.target.value) === true || true) {
      this.setState({ feedback: e.target.value });
    } else {
      this.notifyError(
        "Only letters, numbers and special characters are allowed"
      );
    }
  }

  adminDeclaredAttendance(cell, row) {
    if (row["updatedParticipatedAttendence"] === true) {
      return (
        <div className="declarationTag" disabled>
          Approved
        </div>
      );
    } else if (row["updatedParticipatedAttendence"] === false) {
      return (
        <div className="declarationTag" disabled>
          Declined
        </div>
      );
      // }
    } else if (row["adminName"] === "-") {
      return (
        <div className="declarationTag" disabled>
          Pending Approval
        </div>
      );
    }
  }

  checkName(cell, row) {
    if (cell) {
      if (
        JSON.parse(readCookie("userData")).role === "admin" &&
        row.listId &&
        row.id
      ) {
        return (
          <a
            href={"/admin/team-management/" + row.listId + "/" + row.id}
            target="_blank"
          >
            {cell}
          </a>
        );
      } else return cell;
    } else return "N/A";
  }

  hideDeclareModal(row) {
    this.setState({ showAdminDeclareModal: false, attendeeId: null });
  }

  hideEmployeeTimeModal() {
    this.setState({ showEmployeeTimeModal: false,
      allowfeedback:true
     });
  }
  showTimeModal(value=true) {
    console.log(value);
    this.setState({ showEmployeeTimeModal: true,
      allowfeedback:value
     });
  }

  showDisclaimerModal() {
    this.setState({ disclaimerModal: true });
  }
  hideDisclaimerModal() {
    this.setState({ disclaimerModal: false });
  }

  getCertificate() {
    let userId = JSON.parse(readCookie("userData")).id;
    let id = this.props.participantsData.filter((data) => data.id === userId);
    let voId = this.props.selectedOpportunity.id;
    fetch(
      configs.BACKEND_API_URL +
        "/ee/volunteer-opportunity/get-certificates/" +
        voId +
        "/" +
        id[0].id,
      {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then((data) => data.json())
      .then(
        function (data) {
          var a = document.createElement("a");
          a.href = data.pdfUrl;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
        }.bind(this)
      );
  }

  showDeclareModal(attendeeDetails, attendeeIndex) {
    this.setState({
      showAdminDeclareModal: true,
      attendeeId: attendeeDetails,
      editingRowIndex: attendeeIndex,
    });
  }
  setParticipants(data) {
    this.setState({
      propsData: { ...this.state.propsData, participantsData: data },
      participantsData: data,
    });
  }
  editButton(cell, row, _, index) {
    if (
      JSON.parse(readCookie("userData")).role === "admin" ||
      (row.listId && row.id)
    ) {
      return (
        <Button
          className="btn-sq btn-cancel"
          onClick={this.showDeclareModal.bind(this, row, index)}
        >
          Edit
        </Button>
      );
    } else if (this.state.selectedParticipant === row.id) {
      return (
        <Button
          type="submit"
          class="btn-sq btn-success save"
          value="save"
          id="save"
        >
          Save Changes
        </Button>
      );
    }
  }

  tshirtCollectedFormatter(cell, row) {
    if (cell === "No") {
      return (
        <div className="tshirt-not-collected">
          No
          <button
            className="btn tshirt-collected-btn"
            onClick={this.tshirtCollected.bind(this, row.id)}
          >
            Collect
          </button>
        </div>
      );
    } else return cell;
  }

  tshirtCollected(userId) {
    swal({
      title: "T-shirt Collected",
      html: '<span class="is-imp">Please select the t-shirt size</span><select id="swal-input1" class="form-control swal2-input" value=""><option value="">Select one size</option><option value="sm">XS</option><option value="s">S</option><option value="m">M</option><option value="l">L</option><option value="xl">XL</option><option value="xxl">XXL</option></select>',
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
    })
      .then(
        function (result) {
          let size = $("#swal-input1").val();
          if (size !== "" && result) {
            swal({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, Collected!",
              cancelButtonText: "No, cancel!",
            })
              .then(
                function (result2) {
                  if (result2) {
                    fetch(
                      configs.BACKEND_API_URL +
                        "/ee/volunteer-opportunity/tshirt-collected",
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Auth: JSON.parse(readCookie("access_token"))
                            .access_token,
                        },
                        body: JSON.stringify({
                          tshirtSize: size,
                          employeeId: userId,
                          voId: this.props.selectedOpportunity.id,
                        }),
                      }
                    )
                      .then((response) => response.json())
                      .then(
                        function (data) {
                          if (data["status"] === "ok") {
                            swal("Great", "T-shirt collected!", "success")
                              .then(function () {
                                window.location.reload();
                              })
                              .catch(function () {
                                window.location.reload();
                              });
                          } else {
                            swal("Oops...", "Something went wrong!", "error");
                          }
                        }.bind(this)
                      );
                  }
                }.bind(this)
              )
              .catch(swal.noop);
          }
        }.bind(this)
      )
      .catch(swal.noop);
  }

  getTimeDiff(startTime, endTime) {
    var time = "";
    var hours = parseInt(
      moment
        .duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm")))
        .asHours()
    );
    if (hours > 1) time = hours + "  hours ";
    else if (hours === 1) time = hours + "  hour ";
    var mins = parseInt(
      moment
        .duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm")))
        .asMinutes() % 60
    );
    if (mins > 1) time += mins + " minutes ";
    else if (mins === 1) time += mins + " minute ";
    if (time === "") return false;
    return time;
  }
  declaredAttendance(cell, row) {
    if (row.participated === true) {
      return (
        <div className="declarationTag" disabled>
          Declared
        </div>
      );
    } else if (row.participated === false && row.declared === true) {
      return (
        <div className="declarationTag" disabled>
          Missed
        </div>
      );
    } else if (row.participated === "") {
      return (
        <div className="declarationTag" disabled>
          Pending Declaration
        </div>
      );
    }
  }

  getHours(cell, row) {
    if (cell && cell !== "N/A") {
      if (row["startTime"] && row["endTime"])
        return (
          row["startTime"] + " - " + row["endTime"] + " (" + cell + " hours)"
        );
      else return cell + " hours";
    } else return "N/A";
  }

  adminName() {
    // this.state.attendeesData.map((data) => {
    // 	return (<p key={data.id}>{data.adminName}</p>)
    // });
  }

  showAddToCalendarModal() {
    this.setState({ showAddToCalendarModal: true });
  }

  closeAddToCalendarModal() {
    this.setState({ showAddToCalendarModal: false });
  }

  checkStartTimer() {
    if (navigator.geolocation) {
      var that = this;
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let distance = that.getDistance(
            that.props.selectedOpportunity.latitude,
            that.props.selectedOpportunity.longitude,
            position.coords.latitude,
            position.coords.longitude
          );
          that.setState({
            currentLatitude: position.coords.latitude,
            currentLongitude: position.coords.longitude,
            distance,
          });
          if (distance && distance !== null) {
            if (distance < 400) that.startTimerConfirmed(that);
            else if (distance >= 400 && distance < 750) {
              that.setState({ startTimerConfirmModalVisibility: true });
            } else {
              that.setState({ cannotStartTimerModalVisibility: true });
            }
          } else {
            swal(
              "Oops!",
              "An error occurred in fetching your current location. Please try again.",
              "error"
            );
          }
        },
        function (error) {
          if (error.code == error.PERMISSION_DENIED) {
            swal(
              "",
              "This feature requires permission to access you location.",
              "error"
            );
          }
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 60000 }
      );
    }
  }

  startTimerConfirmed() {
    let today = new Date();
    let hours = today.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let minutes =
      today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();

    fetch(
      configs.BACKEND_API_URL + "/ee/volunteer-opportunity/attendee-started",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
        body: JSON.stringify({
          startTime: `${hours}:${minutes}`,
          participated: true,
          voId: this.props.selectedOpportunity.id,
        }),
      }
    )
      .then((data) => data.json())
      .then(
        function (data) {
          let path = this.props.match.params.name;
          fetch(configs.BACKEND_API_URL + "/ee/volunteer-opportunity/" + path, {
            method: "GET",
            headers: {
              Auth: JSON.parse(readCookie("access_token")).access_token,
            },
          })
            .then((data) => data.json())
            .then(
              function (data) {
                if (data.hasOwnProperty("data")) {
                  for (let i = 0; i < data.data.length; i++) {
                    data.data.comments[i]["showReply"] = false;
                  }
                  this.props.actions.updateSelectedOpportunity(data["data"]);
                  this.forceUpdate();
                  this.setState(
                    { startTimerConfirmModalVisibility: false },
                    function () {
                      swal(
                        "CONGRATULATIONS! Your volunteering day has begun.",
                        "You have " +
                          this.props.selectedOpportunity.activities.length +
                          " activities to finish.",
                        "success"
                      );
                    }
                  );
                }
              }.bind(this)
            );
        }.bind(this)
      );
  }

  closeStartTimerConfirmModal() {
    this.setState({ startTimerConfirmModalVisibility: false });
  }

  closeCannotStartTimerModal() {
    this.setState({ cannotStartTimerModalVisibility: false });
  }

  checkEndTimer() {
    this.setState({ endTimerModalVisibility: true, endTime: moment() });
  }

  getVolunteeringHours(endTimeType) {
    let startTime, endTime;

    if (this.state.onlySubmitFeedback)
      startTime = moment(
        this.props.selectedOpportunity.attendeeDeclaration.startTime,
        "HH:mm"
      );
    else
      startTime = moment(
        this.props.selectedOpportunity.attendeeStarted.startTime,
        "HH:mm"
      );

    if (endTimeType === "now") endTime = moment();
    else endTime = moment(this.state.endTime, "HH:mm");

    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return hours + " hour and " + minutes + " minutes";
  }

  closeEndTimerModal() {
    this.setState({ endTimerModalVisibility: false });
  }

  endTimerConfirmed() {
    let today = new Date();
    let hours = today.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let minutes =
      today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
    this.setState(
      { endTime: `${hours}:${minutes}` },
      function () {
        let data = {
          voId: this.props.selectedOpportunity.id,
          endTime: this.state.endTime,
          participated: true,
        };

        fetch(
          configs.BACKEND_API_URL +
            "/ee/volunteer-opportunity/attendee-completed",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Auth: JSON.parse(readCookie("access_token")).access_token,
            },
            body: JSON.stringify(data),
          }
        )
          .then((response) => response.json())
          .then(
            function (data) {
              let path = this.props.match.params.name;
              fetch(
                configs.BACKEND_API_URL + "/ee/volunteer-opportunity/" + path,
                {
                  method: "GET",
                  headers: {
                    Auth: JSON.parse(readCookie("access_token")).access_token,
                  },
                }
              )
                .then((data) => data.json())
                .then(
                  function (data) {
                    if (data.hasOwnProperty("data")) {
                      for (let i = 0; i < data.data.length; i++) {
                        data.data.comments[i]["showReply"] = false;
                      }
                      this.props.actions.updateSelectedOpportunity(
                        data["data"]
                      );
                      this.forceUpdate();
                      this.setState({
                        endTimerModalVisibility: false,
                        submitFeedbackModalVisibility: true,
                        onlySubmitFeedback: true,
                      });
                    }
                  }.bind(this)
                );
            }.bind(this)
          );
      }.bind(this)
    );
  }

  closeSubmitFeedbackModal() {
    this.setState({ endTime: null, submitFeedbackModalVisibility: false });
  }

  feedbackConfirmed() {
    let data = {
      experience: this.state.feedback,
      voId: this.props.selectedOpportunity.id,
    };
    if (
      (this.state.onlySubmitFeedback && this.state.feedback) ||
      !this.state.onlySubmitFeedback
    ) {
      fetch(
        configs.BACKEND_API_URL +
          "/ee/volunteer-opportunity/attendee-declaration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Auth: JSON.parse(readCookie("access_token")).access_token,
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then(
          function (data) {
            let path = this.props.match.params.name;
            fetch(
              configs.BACKEND_API_URL + "/ee/volunteer-opportunity/" + path,
              {
                method: "GET",
                headers: {
                  Auth: JSON.parse(readCookie("access_token")).access_token,
                },
              }
            )
              .then((data) => data.json())
              .then(
                function (data) {
                  if (data.hasOwnProperty("data")) {
                    for (let i = 0; i < data.data.length; i++) {
                      data.data.comments[i]["showReply"] = false;
                    }
                    this.props.actions.updateSelectedOpportunity(data["data"]);
                    this.forceUpdate();
                  }
                }.bind(this)
              );

            fetch(
              configs.BACKEND_API_URL +
                "/ee/volunteer-opportunity/" +
                path +
                "/feedback",
              {
                method: "GET",
                headers: {
                  Auth: JSON.parse(readCookie("access_token")).access_token,
                },
              }
            )
              .then((data) => data.json())
              .then(
                function (data) {
                  this.setState(
                    { feedbacks: data.data },
                    function () {
                      this.forceUpdate();
                    }.bind(this)
                  );
                }.bind(this)
              );
            this.setState({
              submitFeedbackModalVisibility: false,
              onlySubmitFeedback: false,
            });
          }.bind(this)
        );
    } else {
      if (this.state.onlySubmitFeedback && this.state.feedback === "")
        this.notifyError("Please fill in the feedback to submit.");
    }
  }

  notifyError = (text) => toast.error(text);
  notifySuccess = (text) => toast.success(text);

  showOnlyFeedbackModal() {
    this.setState({
      onlySubmitFeedback: true,
      submitFeedbackModalVisibility: true,
      endTime: this.props.selectedOpportunity.attendeeDeclaration.endTime,
      feedback: "",
    });
  }

  showWontAttendModal() {
    this.setState({ wontAttendModalVisibility: true });
  }

  confirmWontAttend() {
    let error = [];
    if (!this.state.wontAttendReason) {
      error.push("reason");
      this.setState({ error });
    }

    if (this.state.wontAttendReason !== "") {
      let data = {
        participated: false,
        voId: this.props.selectedOpportunity.id,
        reason: this.state.wontAttendReason,
      };
      fetch(
        configs.BACKEND_API_URL +
          "/ee/volunteer-opportunity/attendee-declaration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Auth: JSON.parse(readCookie("access_token")).access_token,
          },
          body: JSON.stringify(data),
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(
          function (data) {
            if (data.status === "ok") {
              swal(
                "😞",
                "Hope you find a suitable volunteering opportunity for yourself soon!",
                "success"
              );
              this.hideWontAttendModal();
              fetch(
                configs.BACKEND_API_URL +
                  "/ee/volunteer-opportunity/" +
                  this.props.match.params.name,
                {
                  method: "GET",
                  headers: {
                    Auth: JSON.parse(readCookie("access_token")).access_token,
                  },
                }
              )
                .then((data) => data.json())
                .then(
                  function (data) {
                    if (data.hasOwnProperty("data")) {
                      for (let i = 0; i < data.data.length; i++) {
                        data.data.comments[i]["showReply"] = false;
                      }
                      this.props.actions.updateSelectedOpportunity(
                        data["data"]
                      );
                      this.forceUpdate();
                    }
                  }.bind(this)
                );
            }
          }.bind(this)
        );
    } else {
      this.notifyError("Please fill in the reason to submit.");
    }
  }

  hideWontAttendModal() {
    this.setState({ wontAttendModalVisibility: false });
  }

  gotoAnalyticsPage() {
    window.location.pathname = window.location.pathname + "/analytics";
  }

  gotoGalleryPage() {
    window.location.pathname = window.location.pathname + "/gallery";
  }
  gotoParticipantsPage() {
    window.location.pathname = window.location.pathname + "/participants";
  }
  approvedHours(cell, row) {
    if (cell && cell !== "N/A") {
      if (
        row["updatedParticipatedAttendence"] === true &&
        row["updatedStartTime"] !== "" &&
        row["updatedEndTime"] !== ""
      ) {
        return (
          row["updatedStartTime"] +
          " - " +
          row["updatedEndTime"] +
          " (" +
          cell +
          " hours)"
        );
      } else if (
        row["updatedParticipatedAttendence"] === true &&
        row["updatedStartTime"] === "" &&
        row["updatedEndTime"] === ""
      ) {
        return (
          row["Start Time"] +
          " - " +
          row["End Time"] +
          "(" +
          row["Hours Clocked"] +
          " hours)"
        );
      } else {
        return "-";
      }
    } else return "-";
  }
  showFamilyFriendsCertificateModal() {
    this.setState({ ShowFriendsCertificateModal: true });
  }

  hideFamilyFriendsCertificateModal() {
    this.setState({ ShowFriendsCertificateModal: false });
  }

  render() {
    if (this.props.selectedOpportunity.hasOwnProperty("title")) {
      return (
        <OrgDataContext.Consumer>
          {(organisationData) => {
            return (
              <div className="ee-page-container single-opportunity-page">
                <Col md={12} className="campTitle mb10 mt20">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingRight: "10px",
                    }}
                  >
                    <span className="title-name single-project-title-name">
                      <i
                        className="fa fa-chevron-left mr5"
                        onClick={this.goBack}
                      ></i>
                      {this.props.selectedOpportunity.title}
                      <span
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        title="Click to copy opportunity link"
                      >
                        <CopyToClipboard
                          text={(window.location.href || "").replace(
                            "/admin/",
                            "/"
                          )}
                          onCopy={() =>
                            this.notifySuccess("Opportunity link copied!")
                          }
                        >
                          <i class="fa fa-share-alt" aria-hidden="true"></i>
                        </CopyToClipboard>
                      </span>{" "}
                    </span>
                    {/* (this.props.role === 'admin'||(this.props.selectedOpportunity.addAdmin=='Yes' && this.props.selectedOpportunity?.adminList?.findIndex(item => item?._id === this.state.currentUserData._id))) */}
                    {this.props.role === 'admin' && this.props.selectedOpportunity.type === "Internal" &&
                    this.props.selectedOpportunity.hasOwnProperty("status") ? (
                      // && (opportunity.status !== 'Completed')
                      // <a className="btn-pad-5-20 btn-sq btn-cancel ml5 mr5" href={"/admin/volunteer-opportunity/" + opportunity.id}>
                      // 	Edit
                      // </a>
                       <button
                        onClick={() =>
                          (window.location.pathname =
                            "/admin/volunteer-opportunity/" + this.props.selectedOpportunity.id)
                        }
                        style={{height:'40px',
                        }}
                        type="button"
                        class="btn btn-outline-primary ml20 mr5"
                      >
                        Edit
                      </button>
                    ) : null}
                  </div>

                  <p className="eyebrow-text left">
                    {this.props.selectedOpportunity.physicalOpportunity ===
                    false ? (
                      this.props.selectedOpportunity.virtualOpportunityUrl ? (
                        <span>
                          Link to join the opportunity:{" "}
                          <a
                            href={
                              this.props.selectedOpportunity
                                .virtualOpportunityUrl
                            }
                            target="_blank"
                            style={{ marginLeft: 5 }}
                          >
                            {
                              this.props.selectedOpportunity
                                .virtualOpportunityUrl
                            }
                          </a>
                        </span>
                      ) : null
                    ) : (
                      <span
                        className="camp-location"
                        onClick={
                          this.props.selectedOpportunity.physicalOpportunity &&
                          this.showDisclaimerModal.bind(this)
                        }
                      >
                        <i className="fa fa-map-marker-alt"></i>
                        <span>{this.state.address}</span>
                      </span>
                    )}
                  </p>
                </Col>

                {/* ========================= tabs =====================  */}
                <ul role="tablist" className="nav nav-tabs">
                  <li
                    role="presentation"
                    className={this.state.activeTab == 0 ? "active" : null}
                  >
                    <a
                      id="settings-profile"
                      role="tab"
                      aria-controls="settings-profile"
                      onClick={() => this.setState({ activeTab: 0 })}
                    >
                      <div className="settings-profile">
                        <div style={{ display: "inline-block" }}></div>
                        <span>Overview</span>
                      </div>
                    </a>
                  </li>

                  {/* {(this.props.role ==="admin" && JSON.parse(readCookie("userData")).role === "admin")
              && <li role="presentation" className={this.state.activeTab == 1 ? "active" : null}>
                <a
                  id="settings-profile"
                  role="tab"
                  aria-controls="settings-profile"
                  onClick={() => this.setState({ activeTab: 1 })}
                // onClick={this.gotoAnalyticsPage.bind(this)}
                >
                  <div className="settings-profile">
                    <div style={{ display: "inline-block" }}></div>
                    <span>Analytics</span>
                  </div>
                </a>
              </li>} */}

                  <li
                    role="presentation"
                    className={this.state.activeTab == 2 ? "active" : null}
                  >
                    <a
                      id="settings-profile"
                      role="tab"
                      aria-controls="settings-profile"
                      onClick={() => {
                        if (this.state.isImages) {
                          this.setState({ activeTab: 2 });
                        } else {
                          this.setState({ activeTab: 2 });
                          this.fetchImages();
                        }
                      }}
                    >
                      <div className="settings-profile">
                        <div style={{ display: "inline-block" }}></div>
                        <span>Gallery</span>
                      </div>
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={this.state.activeTab == 3 ? "active" : null}
                  >
                    <a
                      id="settings-profile"
                      role="tab"
                      aria-controls="settings-profile"
                      onClick={() => this.setState({ activeTab: 3 })}
                    >
                      <div className="settings-profile">
                        <div style={{ display: "inline-block" }}></div>
                        <span>Participants</span>
                      </div>
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={this.state.activeTab == 4 ? "active" : null}
                  >
                    <a
                      id="settings-profile"
                      role="tab"
                      aria-controls="settings-profile"
                      onClick={() => {
                        let path = this.props.match.params.name;
                        if (this.state.isFeedBack) {
                          this.setState({ activeTab: 4 });
                        } else {
                          this.setState({ activeTab: 4 });
                          this.fetchFeedbacks(path);
                        }
                      }}
                    >
                      <div className="settings-profile">
                        <div style={{ display: "inline-block" }}></div>
                        <span>Volunteer Feedback</span>
                      </div>
                    </a>
                  </li>
                </ul>
                {/* ========================= tabs =====================  */}

                {this.state.activeTab === 0 && (
                  <>
                    <DocumentTitle
                      title={this.props.selectedOpportunity.title}
                    />
                    {/* <div className="single-vo-menu-container">
              <Menu selectedKeys={["overview"]} mode="horizontal">
              <Menu.Item key="overview">
                <span>Overview</span>
              </Menu.Item>

              {window.location.pathname.match("/admin/") !== null &&
                JSON.parse(readCookie("userData")).role === "admin" ? (
                <Menu.Item key="analytics">
                  <span onClick={this.gotoAnalyticsPage.bind(this)}>
                    Analytics
                  </span>
                </Menu.Item>
              ) : null}
              <Menu.Item key="gallery">
                <span onClick={this.gotoGalleryPage.bind(this)}>Gallery</span>
              </Menu.Item>
            </Menu> 
            </div> */}
                    <Row>
                      <ToastContainer
                        position="bottom-center"
                        autoClose={4000}
                        hideProgressBar={true}
                        closeOnClick={false}
                        newestOnTop={false}
                        pauseOnHover={true}
                      />

                      <Col
                        md={12}
                        className="campContainer"
                        style={{ marginTop: "1%" }}
                      >
                        <div className="col-md-8 opportunity-desc">
                          <Row
                            className="mediaContainer"
                            style={{ marginBottom: "15px" }}
                          >
                            {this.props.selectedOpportunity.image ? (
                              <div
                                className="img-container"
                                style={{
                                  // backgroundImage:  `${"url(" + generatePreSignedGetUrl(this.props.selectedOpportunity.image) + ")"}`,
                                  width: "100%",
                                  minHeight: "415px",
                                  borderRadius: "4px",
                                  overflow: "hidden",
                                  display: "flex",
                                  position: "relative",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    generatePreSignedGetUrl(
                                      this.props.selectedOpportunity.image
                                    )
                                    // `https://le-uploaded-image-bucket.s3.amazonaws.com/ee/5b3a4856fab56a510b7bffe8/B5dQNrIwO2wlgzapidvvxjpiavbcfl15395874035834.png`
                                  }
                                  style={{
                                    minHeight: "100%",
                                    minWidth: "100%",
                                    height: "auto",
                                    width: "auto",
                                    objectFit: "cover",
                                  }}
                                  className="opportunity-img"
                                />
                              </div>
                            ) : (
                              <div
                                className="img-container"
                                style={{
                                  backgroundImage: `${"url(https://assets.letsendorse.com/sahaj/project-placeholder.jpg)"}`,
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                  width: "100%",
                                  height: "350px",
                                  borderRadius: "4px",
                                }}
                              ></div>
                            )}
                          </Row>
                          <Row
                            style={{
                              marginBottom: "30px",
                              borderRadius: "4px",
                              border: "solid 1px #eee",
                              padding: "10px",
                            }}
                          >
                            <Col>
                              {
                                this.props.selectedOpportunity
                                  .isIndicativeImage === false ? null : (
                                  <p className="representation-text">
                                    *This is an indicative image only
                                  </p>
                                )
                                // <p className="representation-text">
                                //   *The visual content above is for
                                //   representational purpose and may not correspond
                                //   to this project.
                                // </p>
                              }
                            </Col>
                            {this.props.selectedOpportunity
                              .physicalOpportunity && (
                              <Row>
                                {this.state.facilities.includes("toilet") ? (
                                  <Col md={4}>
                                    <div class="form-group form-check">
                                      <Icon
                                        size={16}
                                        icon={
                                          this.state.facilities.includes(
                                            "toilet"
                                          )
                                            ? check
                                            : close
                                        }
                                        style={{
                                          color: this.state.facilities.includes(
                                            "toilet"
                                          )
                                            ? "green"
                                            : "red",
                                        }}
                                        className="list-icon"
                                      />
                                      <label
                                        class="form-check-label"
                                        style={{ marginLeft: 10 }}
                                        for="toilet"
                                      >
                                        Toilet
                                      </label>
                                    </div>
                                  </Col>
                                ) : null}
                                {this.state.facilities.includes(
                                  "drinkingWater"
                                ) ? (
                                  <Col md={4}>
                                    <div class="form-group form-check">
                                      <Icon
                                        size={16}
                                        icon={
                                          this.state.facilities.includes(
                                            "drinkingWater"
                                          )
                                            ? check
                                            : close
                                        }
                                        style={{
                                          color: this.state.facilities.includes(
                                            "drinkingWater"
                                          )
                                            ? "green"
                                            : "red",
                                        }}
                                        className="list-icon"
                                      />
                                      <label
                                        class="form-check-label"
                                        style={{ marginLeft: 10 }}
                                        for="drinkingWater"
                                      >
                                        Drinking Water
                                      </label>
                                    </div>
                                  </Col>
                                ) : null}
                                {this.state.facilities.includes(
                                  "mealOrSnack"
                                ) ? (
                                  <Col md={4}>
                                    <div class="form-group form-check">
                                      <Icon
                                        size={16}
                                        icon={
                                          this.state.facilities.includes(
                                            "mealOrSnack"
                                          )
                                            ? check
                                            : close
                                        }
                                        style={{
                                          color: this.state.facilities.includes(
                                            "mealOrSnack"
                                          )
                                            ? "green"
                                            : "red",
                                        }}
                                        className="list-icon"
                                      />
                                      <label
                                        class="form-check-label"
                                        style={{ marginLeft: 10 }}
                                        for="mealOrSnack"
                                      >
                                        Meal/Snacks
                                      </label>
                                    </div>
                                  </Col>
                                ) : null}
                                {this.props.selectedOpportunity.config
                                  .isTransportRequired ? (
                                  <Col md={4}>
                                    <div class="form-group form-check">
                                      <Icon
                                        size={16}
                                        icon={
                                          this.props.selectedOpportunity.config
                                            .isTransportRequired
                                            ? check
                                            : close
                                        }
                                        style={{
                                          color: this.props.selectedOpportunity
                                            .config.isTransportRequired
                                            ? "green"
                                            : "red",
                                        }}
                                        className="list-icon"
                                      />
                                      <label
                                        class="form-check-label"
                                        style={{ marginLeft: 10 }}
                                        for="drinkingWater"
                                      >
                                        Transport Available
                                      </label>
                                    </div>
                                  </Col>
                                ) : null}
                                {this.props.selectedOpportunity.config
                                  .isTshirtProvided ? (
                                  <Col md={4}>
                                    <div class="form-group form-check">
                                      <Icon
                                        size={16}
                                        icon={
                                          this.props.selectedOpportunity.config
                                            .isTshirtProvided
                                            ? check
                                            : close
                                        }
                                        style={{
                                          color: this.props.selectedOpportunity
                                            .config.isTshirtProvided
                                            ? "green"
                                            : "red",
                                        }}
                                        className="list-icon"
                                      />
                                      <label
                                        class="form-check-label"
                                        style={{ marginLeft: 10 }}
                                        for="drinkingWater"
                                      >
                                        T-shirt provided
                                      </label>
                                    </div>
                                  </Col>
                                ) : null}
                              </Row>
                            )}
                            <Col className="desc-container desc-style">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "8px",
                                }}
                              >
                                <span
                                  className="dot"
                                  style={{
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50px",
                                  }}
                                ></span>
                                <h2 className="about-proj-text">
                                  About the Project:
                                </h2>
                              </div>
                              {/* <p
                                style={{
                                  padding: "0 0 0 15px",
                                  color: "#707070",
                                }}
                              >
                                {this.props.selectedOpportunity.description}
                              </p> */}
                              <div
                                className="ql-editor"
                                style={{
                                  padding: "0 0 0 15px",
                                  color: "#707070",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.props.selectedOpportunity.description,
                                }}
                              />
                            </Col>
                            <Col className="desc-container desc-style">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "8px",
                                }}
                              >
                                <span
                                  className="dot"
                                  style={{
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50px",
                                  }}
                                ></span>
                                <h2 className="about-proj-text">
                                  Expected Impact:
                                </h2>
                              </div>
                              {/* <p
                                style={{
                                  padding: "0 0 0 15px",
                                  color: "#707070",
                                }}
                              >
                                {this.props.selectedOpportunity.expectedImpact}
                              </p> */}

                              <div
                                className="ql-editor"
                                style={{
                                  padding: "0 0 0 15px",
                                  color: "#707070",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.props.selectedOpportunity
                                      .expectedImpact,
                                }}
                              />
                            </Col>
                            {this.state.safetyProtocol && (
                              <Col className="desc-container desc-style">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <span
                                    className="dot"
                                    style={{
                                      width: "8px",
                                      height: "8px",
                                      borderRadius: "50px",
                                    }}
                                  ></span>
                                  <h2 className="about-proj-text">
                                    Safety Protocol:
                                  </h2>
                                </div>
                                <p
                                  style={{
                                    padding: "0 0 0 15px",
                                    color: "#707070",
                                  }}
                                >
                                  {parse(this.state.safetyProtocol)}
                                </p>
                              </Col>
                            )}
                            {this.state.helpfulDocument && (
                              <Col className="desc-container desc-style">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <h2 className="about-proj-text">
                                    <a
                                      href={this.state.helpfulDocument}
                                      target="_blank"
                                    >
                                      Helpful Document{" "}
                                      <Icon icon={link} className="list-icon" />
                                    </a>
                                  </h2>
                                </div>
                              </Col>
                            )}
                          </Row>

                          <Row>
                            <Tabs
                              activeKey={this.state.key}
                              onSelect={this.handleSelect.bind(this)}
                              id="controlled-tab-example"
                            >
                              <Tab
                                eventKey={1}
                                title="Discussion"
                                tabClassName="hello"
                                bsClass="test"
                              >
                                <Col md={12} className="mt15 mb20">
                                  <SingleOpportunityDiscussion
                                    selectedOpportunity={
                                      this.props.selectedOpportunity
                                    }
                                    updateSelectedOpportunity={
                                      this.props.actions
                                        .updateSelectedOpportunity
                                    }
                                    volunteerOpportunityId={
                                      this.props.selectedOpportunity.id
                                    }
                                    comments={
                                      this.props.selectedOpportunity.comments
                                    }
                                  />
                                </Col>
                              </Tab>
                              {/* <Tab eventKey={2} title="Feedback">
                        <Col md={12} className="mt15 mb20">
                          <div className="ee-discussion-container mb10">
                            { this.state.feedbacks.length > 0 ? (this.state.attendeesData || []).map(
                              function (feedback, index) {
                               if(feedback.experience !=="" && feedback.participated === true){
                                return (
                                  <Col
                                    md={12}
                                    className="comments-sec"
                                    key={index}
                                  >
                                    <ul>
                                      <li className="single-comment">
                                        <Row>
                                          <Col md={12}>
                                            <Row className="flex">
                                              <Col className="comment" md={12}>
                                                <div className="just-flex">
                                                  <img
                                                    src={
                                                      feedback.profilePicture
                                                        ? generatePreSignedGetUrl(feedback.profilePicture)
                                                        : "/img/user.png"
                                                    }
                                                    style={{
                                                      width: 50,
                                                      height: 50,
                                                      background: "#e0e0e0",
                                                      padding: 3,
                                                    }}
                                                    alt=""
                                                    className="img-circle img-responsive mr10"
                                                  />
                                                  {/* <Row><Col md={12}></Col> </Row>  */}
                              {/* <Row>
                                                    <Row>
                                                      <Col
                                                        md={12}
                                                        className="feedback-name"
                                                      >
                                                        
                                                        {feedback.id ? <a href={"/profile/" + feedback.id} target="_blank">
                                                        {feedback.fullName} */}
                              {/* {cell} */}
                              {/* </a> : feedback.fullName} */}
                              {/* </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col
                                                        md={12}
                                                        className="feedback-time"
                                                      >
                                                        Duration: {" "}
                                                        {this.getTimeDiff(
                                                          feedback?.updatedStartTime || feedback?.startTime || this.state.selectedOpportunity?.startTime,
                                                          feedback?.updatedEndTime || feedback?.endTime || this.state.selectedOpportunity?.endTime
                                                        )}{" "}
                                                        | Experience submitted on: {" "}
                                                        {feedback.hasOwnProperty(
                                                          "createdOn"
                                                        ) ? (
                                                          <span className="writer-date">
                                                            {moment(
                                                              feedback.createdOn
                                                            ).format(
                                                              "DD/MM/YYYY h:mm a"
                                                            )}
                                                          </span>
                                                        ) : null}
                                                      </Col> */}
                              {/* </Row>{" "}
                                                  </Row>
                                                </div>
                                                <div className="feedback-rating" style={{marginLeft:"8%"}}>
                                                  <Col md={12}>
                                                    <Col md={3}>
                                                      <Row>
                                                        {" "}
                                                        <Col md={12}> */}
                              {/* <Rate
                                                            className="feedback-star-ratings"
                                                            // style={{fontSize:8,color:"#EF5A20"}}
                                                            defaultValue={
                                                              feedback.questionSelectedOption1
                                                            }
                                                            disabled
                                                          /> */}
                              {/* <Rating 
                                                          allowHover={false}
                                                          disableFillHover={false}
                                                          size={12}
                                                          fillColor={configs.THEME_COLOR}
                                                          initialValue={feedback.questionSelectedOption1} 
                                                          readonly={true}
                                                        
                                                          />

                                                          
                                                        </Col> */}
                              {/* </Row>
                                                      <Row>
                                                        <Col
                                                          md={12}
                                                          className="feedback-tag"
                                                        >
                                                          Impact
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                    <Col md={4}>
                                                      <Row>
                                                        <Col md={12}> */}
                              {/* <Rate
                                                            // style={{fontSize:8 ,color:"#EF5A20"}}
                                                            className="feedback-star-ratings"
                                                            defaultValue={
                                                              feedback.questionSelectedOption2
                                                            }
                                                            disabled
                                                          /> */}
                              {/* <Rating 
                                                            readonly={true}
                                                          allowHover={false}
                                                          disableFillHover={false}
                                                          size={12}
                                                          fillColor={configs.THEME_COLOR}
                                                          initialValue={feedback.questionSelectedOption2} />
                                                        </Col> */}
                              {/* </Row>
                                                      <Row>
                                                        <Col
                                                          md={12}
                                                          className="feedback-tag"
                                                        >
                                                          Satisfaction
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                    <Col md={5}>
                                                      <Row>
                                                        <Col md={12}> */}
                              {/* <Rate
                                                            // style={{fontSize:8 ,color:"#EF5A20"}}
                                                            className="feedback-star-ratings"
                                                            defaultValue={
                                                              feedback.questionSelectedOption3
                                                            }
                                                            disabled
                                                          /> */}
                              {/* <Rating 
                                                            readonly={true}
                                                          allowHover={false}
                                                          disableFillHover={false}
                                                          size={12}
                                                          fillColor={configs.THEME_COLOR}
                                                          initialValue={feedback.questionSelectedOption3} 
                                                          style={{marginRight:20}}
                                                          />
                                                        </Col> */}
                              {/* </Row>
                                                      <Row>
                                                        <Col
                                                          md={12}
                                                          className="feedback-tag"
                                                        >
                                                          Likelihood of
                                                          participation again
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                  </Col> */}
                              {/* </div>
                                                <div className="feedback-experience" style={{marginLeft:"15%",marginTop: "8%" }}>
                                                  <p>Experience</p>
                                                  <div>{feedback.experience}</div>
                                                </div>
                                              </Col> */}
                              {/* </Row>
                                          </Col>
                                        </Row> */}
                              {/* </li>
                                    </ul>
                                  </Col>
                                );
                               }
                                
                              }.bind(this)
                        ) : null} */}

                              {/* {!this.state.feedbacks.length ? (
                              <div className="no-feedbacks-container">
                                <span className="no-feedbacks">
                                  No feedbacks posted yet!
                                </span>
                                {this.props.selectedOpportunity
                                  .attendeeDeclaration &&
                                  this.props.selectedOpportunity.attendeeDeclaration.hasOwnProperty(
                                    "participated"
                                  ) &&
                                  this.props.selectedOpportunity.attendeeDeclaration
                                    .participated &&
                                  this.props.selectedOpportunity.attendeeDeclaration
                                    .experience === "" ? (
                                  <div className="fill-your-feedback-container">
                                    <span
                                      className="fill-your-feedback"
                                      onClick={this.showOnlyFeedbackModal.bind(
                                        this
                                      )}
                                    >
                                      Please fill your feedback!
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Tab>  */}
                            </Tabs>
                          </Row>
                        </div>

                        <div
                          className="col-md-4 pad30"
                          style={{ paddingRight: "0" }}
                        >
                          <Col md={12} className="opportunity-details-snapshot">
                            <div className="row budget-block">
                              <div className="col-md-12 goal-detail">
                                <div className="text-center">
                                  <Icon icon={calendar} className="list-icon" />
                                  <span style={{ fontWeight: "bold" }}>
                                    {moment(
                                      this.props.selectedOpportunity.date,
                                      "DD/MM/YYYY"
                                    ).format("ddd, DD MMM YYYY")}
                                  </span>
                                  {this.props.selectedOpportunity.status ===
                                  "Completed" ? (
                                    <button className="completed" disabled>
                                      {this.props.selectedOpportunity.status}
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="row snapshot-container">
                              <div className="col-md-12 whiteBg snapshot-item black-header text-center">
                                <div className="pad-3">
                                  <span
                                    style={{ fontWeight: "bold", fontSize: 14 }}
                                  >
                                    Event time:{" "}
                                  </span>
                                  <span className="number opportunity-number">
                                    {this.props.selectedOpportunity.startTime}
                                  </span>
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    To{" "}
                                  </span>
                                  <span className="number opportunity-number">
                                    {this.props.selectedOpportunity.endTime}
                                  </span>
                                </div>
                              </div>

                              {this.props.selectedOpportunity.status ===
                              "Live" ? (
                                <div className="col-md-12 whiteBg snapshot-item black-header text-center">
                                  <div className="top5">
                                    {this.props.selectedOpportunity
                                      .volunteersRequired -
                                      this.props.selectedOpportunity.attendees
                                        .length ===
                                    0 ? (
                                      <span style={{ fontWeight: "bold" }}>
                                        All slots are filled
                                      </span>
                                    ) : (
                                      <span style={{ fontWeight: "bold" }}>
                                        <span
                                          className="le-color"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {this.props.selectedOpportunity
                                            .volunteersRequired -
                                            (this.props.selectedOpportunity
                                              .attendees.length +
                                              this.props.selectedOpportunity
                                                .friends.length)}
                                          /
                                          {
                                            this.props.selectedOpportunity
                                              .volunteersRequired
                                          }
                                        </span>{" "}
                                        slots available
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ) : null}

                              {/* {(() => {
                        if (
                          this.props.selectedOpportunity.status !== "Completed" &&
                          this.props.selectedOpportunity.config.hasOwnProperty(
                            "isTransportRequired"
                          ) &&
                          this.props.selectedOpportunity.config
                            .isTransportRequired
                        ) {
                          return (
                            <div
                              className="col-md-12 whiteBg snapshot-item le black-header mt10 le-bg-color"
                              style={{ padding: "0", color: "fff !important" }}
                            >
                              <div
                                className="flex-center"
                                style={{ padding: "4px 0" }}
                              >
                                <Icon
                                  size={18}
                                  icon={bus}
                                  style={{ color: "#fff" }}
                                  className="list-icon"
                                />
                                <span
                                  style={{ fontSize: "12px", color: "#fff" }}
                                  className="ml5"
                                >
                                  Transport Provided
                                </span>
                              </div>
                            </div>
                          );
                        }
                      })()} */}
                              {/* {(() => {
                        if (
                          this.props.selectedOpportunity.status !== "Completed" &&
                          this.props.selectedOpportunity.config.hasOwnProperty(
                            "isTshirtProvided"
                          ) &&
                          this.props.selectedOpportunity.config.isTshirtProvided
                        ) {
                          return (
                            <div
                              className="col-md-12 whiteBg snapshot-item le black-header mt10"
                              style={{ padding: "0", color: "fff !important" }}
                            >
                              <div
                                className="flex-center"
                                style={{ padding: "4px 0" }}
                              >
                                <Icon
                                  size={18}
                                  icon={tshirt}
                                  style={{ color: "#fff" }}
                                  className="list-icon"
                                />
                                {this.props.selectedOpportunity
                                  .isTshirtCollected ? (
                                  <span
                                    style={{ fontSize: "12px", color: "#fff" }}
                                    className="ml5"
                                  >
                                    {"T-shirt Collected (Size: " +
                                      this.props.selectedOpportunity.isTshirtCollected.toUpperCase() +
                                      ")"}
                                  </span>
                                ) : (
                                  <span
                                    style={{ fontSize: "12px", color: "#fff" }}
                                    className="ml5"
                                  >
                                    T-shirt Provided
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        }
                      })()} */}

                              <div className="col-md-12 whiteBg snapshot-item black-header text-center mt10">
                                {((row) => {
                                  if (this.props.selectedOpportunity.isOpen) {
                                    if (
                                      this.props.selectedOpportunity
                                        .isUserAttendee
                                    ) {
                                      return (
                                        <div className="reserved-and-backout-container">
                                          <button
                                            className="btn btn-endorse cancel"
                                            disabled
                                          >
                                            RESERVED
                                          </button>
                                          <a
                                            className="backout-text"
                                            onClick={this.backout.bind(this)}
                                            // onClick={this.openReserveModal.bind(this)}
                                            // onClick={() => this.setState({ displayBackoutModal: true })}
                                            disabled={
                                              this.props.role === "admin"
                                                ? true
                                                : false
                                            }
                                            title={
                                              this.props.role === "admin"
                                                ? "Switch to employee mode to proceed with this action"
                                                : null
                                            }
                                          >
                                            Edit my Registration
                                          </a>

                                          <div
                                            style={{ cursor: "pointer" }}
                                            title="Click to copy opportunity link"
                                          >
                                            <CopyToClipboard
                                              text={(
                                                window.location.href || ""
                                              ).replace("/admin/", "/")}
                                              onCopy={() =>
                                                this.notifySuccess(
                                                  "Opportunity link copied!"
                                                )
                                              }
                                              title="Click to copy opportunity link"
                                            >
                                              <button
                                                style={{ width: "100%" }}
                                                className="btn btn-endorse"
                                                // onClick={this.openReserveModal}
                                                // disabled={
                                                //   window.location.pathname.match("/admin/") !==
                                                //     null
                                                //     ? true
                                                //     : false
                                                // }
                                                // title={
                                                //   window.location.pathname.match("/admin/") !==
                                                //     null
                                                //     ? "Switch to employee mode to proceed with this action"
                                                //     : null
                                                // }
                                              >
                                                SHARE THIS EVENT LINK
                                              </button>
                                            </CopyToClipboard>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      this.props.selectedOpportunity
                                        .volunteersRequired -
                                        (this.props.selectedOpportunity
                                          .attendees.length +
                                          this.props.selectedOpportunity.friends
                                            .length) >
                                      0
                                    ) {
                                      return (
                                        <>
                                          {this.state.haveAnotherReservation ? (
                                            <span
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              You have already reserved another
                                              event for this time. Kindly
                                              unreserve from there to proceed.
                                            </span>
                                          ) : (
                                            <button
                                              style={{ width: "100%" }}
                                              className="btn btn-endorse"
                                              onClick={this.openReserveModal}
                                              disabled={
                                                this.props.role === "admin"
                                                  ? true
                                                  : false
                                              }
                                              title={
                                                this.props.role === "admin"
                                                  ? "Switch to employee mode to proceed with this action"
                                                  : null
                                              }
                                            >
                                              RESERVE MY SPOT
                                            </button>
                                          )}
                                          <div
                                            style={{ cursor: "pointer" }}
                                            title="Click to copy opportunity link"
                                          >
                                            <CopyToClipboard
                                              text={(
                                                window.location.href || ""
                                              ).replace("/admin/", "/")}
                                              onCopy={() =>
                                                this.notifySuccess(
                                                  "Opportunity link copied!"
                                                )
                                              }
                                              title="Click to copy opportunity link"
                                            >
                                              <button
                                                style={{ width: "100%" }}
                                                className="btn btn-endorse"
                                                // onClick={this.openReserveModal}
                                                // disabled={
                                                //   window.location.pathname.match("/admin/") !==
                                                //     null
                                                //     ? true
                                                //     : false
                                                // }
                                                // title={
                                                //   window.location.pathname.match("/admin/") !==
                                                //     null
                                                //     ? "Switch to employee mode to proceed with this action"
                                                //     : null
                                                // }
                                              >
                                                SHARE THIS EVENT LINK
                                              </button>
                                            </CopyToClipboard>
                                          </div>
                                        </>
                                      );
                                    } else if (
                                      this.props.selectedOpportunity
                                        .volunteersRequired -
                                        (this.props.selectedOpportunity
                                          .attendees.length +
                                          this.props.selectedOpportunity.friends
                                            .length) <=
                                      0
                                    ) {
                                      return (
                                        <>
                                          {this.state.haveAnotherReservation ? (
                                            <span
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              You have already reserved another
                                              event for this time. Kindly
                                              unreserve from there to proceed.
                                            </span>
                                          ) : (
                                            <button
                                              style={{ width: "100%" }}
                                              className="btn btn-endorse"
                                              // onClick={this.openReserveModal}
                                              disabled={
                                                this.props.role === "admin"
                                                  ? true
                                                  : true
                                              }
                                              title={
                                                this.props.role === "admin"
                                                  ? "Switch to employee mode to proceed with this action"
                                                  : null
                                              }
                                            >
                                              RESERVE MY SPOT
                                            </button>
                                          )}
                                          <div
                                            style={{ cursor: "pointer" }}
                                            title="Click to copy opportunity link"
                                          >
                                            <CopyToClipboard
                                              text={(
                                                window.location.href || ""
                                              ).replace("/admin/", "/")}
                                              onCopy={() =>
                                                this.notifySuccess(
                                                  "Opportunity link copied!"
                                                )
                                              }
                                              title="Click to copy opportunity link"
                                            >
                                              <button
                                                style={{ width: "100%" }}
                                                className="btn btn-endorse"
                                                // onClick={this.openReserveModal}
                                                // disabled={
                                                //   window.location.pathname.match("/admin/") !==
                                                //     null
                                                //     ? true
                                                //     : false
                                                // }
                                                // title={
                                                //   window.location.pathname.match("/admin/") !==
                                                //     null
                                                //     ? "Switch to employee mode to proceed with this action"
                                                //     : null
                                                // }
                                              >
                                                SHARE THIS EVENT LINK
                                              </button>
                                            </CopyToClipboard>
                                          </div>
                                        </>
                                      );
                                    }
                                  } else if (
                                    !this.props.selectedOpportunity
                                      .isUserAttendee
                                  ) {
                                    if (
                                      (this.props.selectedOpportunity
                                        .allowPostEventSignUp &&
                                        !(this.props.role === "admin") &&
                                        !this.props.selectedOpportunity
                                          .isUserAttendee &&
                                        this.props.selectedOpportunity
                                          .volunteersRequired -
                                          (this.props.selectedOpportunity
                                            .attendees.length +
                                            this.props.selectedOpportunity
                                              .friends.length) >
                                          0 &&
                                        // (this.props.selectedOpportunity.volunteersRequired - (this.props.selectedOpportunity.attendees.length + this.props.selectedOpportunity.friends.length) !== 0)
                                        this.props.selectedOpportunity
                                          .status === "Completed") ||
                                      this.props.selectedOpportunity.status ===
                                        "Ongoing"
                                    ) {
                                      return (
                                        <>
                                          <button
                                            style={{ width: "100%" }}
                                            className="btn btn-sq btn-le mr10 btn-declare-attendance"
                                            onClick={this.openReserveModal}
                                            disabled={
                                              this.props.role === "admin"
                                                ? true
                                                : false
                                            }
                                            title={
                                              this.props.role === "admin"
                                                ? "Switch to employee mode to proceed with this action"
                                                : null
                                            }
                                          >
                                            participated in this event?
                                          </button>
                                        </>
                                      );
                                    }
                                  } else if (
                                    // !this.state.attendanceValidation &&
                                    !this.props.selectedOpportunity
                                      .employeeAttendanceOverride &&
                                    !(this.props.role === "admin") &&
                                    this.props.selectedOpportunity
                                      .isUserAttendee
                                  ) {
                                    if (
                                      !this.props.selectedOpportunity.hasOwnProperty(
                                        "attendeeDeclaration"
                                      )
                                    ) {
                                      return (
                                        <>
                                          <a
                                            onClick={this.showTimeModal.bind(
                                              this
                                            )}
                                            className="btn btn-sq btn-le mr10 btn-declare-attendance"
                                          >
                                            Declare Attendance
                                          </a>
                                        </>
                                      );
                                    } else if (
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated &&
                                      !this.props.selectedOpportunity.attendeeDeclaration.hasOwnProperty(
                                        "updatedParticipatedAttendence"
                                      )
                                    ) {
                                      return (
                                        <div className="participated-in-opportunity">
                                          <>
                                            <span className="title">
                                              You have successfully completed
                                              this opportunity!
                                            </span>
                                            <span className="subtitle">
                                              {"Total : " +
                                                parseInt(
                                                  moment
                                                    .duration(
                                                      moment(
                                                        this.props
                                                          .selectedOpportunity
                                                          .attendeeDeclaration
                                                          .endTime,
                                                        "h:mm"
                                                      ).diff(
                                                        moment(
                                                          this.props
                                                            .selectedOpportunity
                                                            .attendeeDeclaration
                                                            .startTime,
                                                          "h:mm"
                                                        )
                                                      )
                                                    )
                                                    .asHours()
                                                ) +
                                                " hour, " +
                                                (parseInt(
                                                  moment
                                                    .duration(
                                                      moment(
                                                        this.props
                                                          .selectedOpportunity
                                                          .attendeeDeclaration
                                                          .endTime,
                                                        "h:mm"
                                                      ).diff(
                                                        moment(
                                                          this.props
                                                            .selectedOpportunity
                                                            .attendeeDeclaration
                                                            .startTime,
                                                          "h:mm"
                                                        )
                                                      )
                                                    )
                                                    .asMinutes()
                                                ) %
                                                  60) +
                                                " mins"}{" "}
                                            </span>
                                            <a
                                              onClick={this.showTimeModal.bind(
                                                this
                                              )}
                                              className="btn btn-sq btn-le mr10"
                                              style={{ marginTop: "20px" }}
                                            >
                                              Edit Attendance
                                            </a>
                                            {organisationData.eePreferences
                                              .enableCertificateGenerationForFamily ? (
                                              <>
                                                <div
                                                  onClick={this.showFamilyFriendsCertificateModal.bind(
                                                    this
                                                  )}
                                                  className="declarationTag"
                                                  style={{
                                                    marginTop: "5%",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  View{" "}
                                                  {this.state
                                                    .presentAttendeeNumber +
                                                    1}{" "}
                                                  Certificates
                                                </div>
                                              </>
                                            ) : (
                                              <div
                                                onClick={this.getCertificate.bind(
                                                  this
                                                )}
                                                className="declarationTag"
                                                style={{
                                                  marginTop: "5%",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                View Certificate
                                              </div>
                                            )}
                                          </>
                                          {this.props.selectedOpportunity
                                            .attendeeDeclaration.experience ===
                                          "" ? (
                                            <span
                                              className="fill-your-feedback"
                                              onClick={this.showOnlyFeedbackModal.bind(
                                                this
                                              )}
                                            >
                                              Please fill your feedback!
                                            </span>
                                          ) : null}
                                        </div>
                                      );
                                    } else if (
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated ===
                                        false &&
                                      !this.props.selectedOpportunity.attendeeDeclaration.hasOwnProperty(
                                        "updatedParticipatedAttendence"
                                      )
                                    ) {
                                      return (
                                        <>
                                          <div className="participated-in-opportunity">
                                            <span className="title">
                                              You missed this opportunity!
                                            </span>
                                          </div>
                                          <a
                                            title={"Change your Attendence"}
                                            onClick={this.showTimeModal.bind(
                                              this
                                            )}
                                            className="btn btn-sq btn-le mr10 "
                                            style={{ marginTop: 15 }}
                                          >
                                            Edit Attendance
                                          </a>
                                        </>
                                      );
                                    } else if (
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated ===
                                        false &&
                                      this.props.selectedOpportunity.attendeeDeclaration.hasOwnProperty(
                                        "updatedParticipatedAttendence"
                                      )
                                    ) {
                                      return (
                                        <div className="participated-in-opportunity">
                                          <span className="title">
                                            You missed this opportunity!
                                          </span>
                                        </div>
                                      );
                                    } else if (
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated &&
                                      this.props.selectedOpportunity.attendeeDeclaration.hasOwnProperty(
                                        "updatedParticipatedAttendence"
                                      )
                                    ) {
                                      return (
                                        <div className="participated-in-opportunity">
                                          <>
                                            <span className="title">
                                              You have successfully completed
                                              this opportunity!
                                            </span>
                                            <span className="subtitle">
                                              {"Total : " +
                                                parseInt(
                                                  moment
                                                    .duration(
                                                      moment(
                                                        this.props
                                                          .selectedOpportunity
                                                          .attendeeDeclaration
                                                          .endTime,
                                                        "h:mm"
                                                      ).diff(
                                                        moment(
                                                          this.props
                                                            .selectedOpportunity
                                                            .attendeeDeclaration
                                                            .startTime,
                                                          "h:mm"
                                                        )
                                                      )
                                                    )
                                                    .asHours()
                                                ) +
                                                " hour, " +
                                                (parseInt(
                                                  moment
                                                    .duration(
                                                      moment(
                                                        this.props
                                                          .selectedOpportunity
                                                          .attendeeDeclaration
                                                          .endTime,
                                                        "h:mm"
                                                      ).diff(
                                                        moment(
                                                          this.props
                                                            .selectedOpportunity
                                                            .attendeeDeclaration
                                                            .startTime,
                                                          "h:mm"
                                                        )
                                                      )
                                                    )
                                                    .asMinutes()
                                                ) %
                                                  60) +
                                                " mins"}{" "}
                                            </span>
                                            {organisationData.eePreferences
                                              .enableCertificateGenerationForFamily ? (
                                              <>
                                                <div
                                                  onClick={this.showFamilyFriendsCertificateModal.bind(
                                                    this
                                                  )}
                                                  className="declarationTag"
                                                  style={{
                                                    marginTop: "5%",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  View{" "}
                                                  {this.state
                                                    .presentAttendeeNumber +
                                                    1}{" "}
                                                  Certificates
                                                </div>
                                                <div
                                                  onClick={this.showTimeModal.bind(
                                                    this,false
                                                  )}
                                                  style={{
                                                    cursor: "pointer",
                                                    marginTop: 10,
                                                  }}
                                                  title={
                                                    "You can change your Attendance"
                                                  }
                                                  className="declarationTag"
                                                >
                                                  Edit friends/family Attendance
                                                </div>
                                              </>
                                            ) : (
                                              <div
                                                onClick={this.getCertificate.bind(
                                                  this
                                                )}
                                                className="declarationTag"
                                                style={{
                                                  marginTop: "5%",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                View Certificate
                                              </div>
                                            )}
                                            {/* <div
                                      onClick={this.getCertificate.bind(this)} 
                                      className="declarationTag"
                                    style={{marginTop: '5%',cursor:"pointer"}}
                                    >
                                      MY Volunteering Certificate
                                    </div> */}
                                          </>
                                        </div>
                                      );
                                    }
                                  } else if (
                                    // this.state.attendanceValidation &&
                                    this.props.selectedOpportunity
                                      .employeeAttendanceOverride &&
                                    !(this.props.role === "admin") &&
                                    this.props.selectedOpportunity
                                      .isUserAttendee
                                  ) {
                                    let data =
                                      this.props.selectedOpportunity.attendees;
                                    let attendeesData = data.filter(
                                      (data) =>
                                        data.id ===
                                        JSON.parse(readCookie("userData")).id
                                    );

                                    if (
                                      !this.props.selectedOpportunity.hasOwnProperty(
                                        "attendeeDeclaration"
                                      )
                                    ) {
                                      return (
                                        <>
                                          <div className="participated-in-opportunity">
                                            <a
                                              onClick={this.showTimeModal.bind(
                                                this
                                              )}
                                              className="btn btn-sq btn-le mr10 btn-declare-attendance"
                                              style={{ marginTop: 15 }}
                                            >
                                              Declare Attendance
                                            </a>
                                          </div>
                                        </>
                                      );
                                    }
                                    if (
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated ===
                                        true &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === "" &&
                                      !(this.props.role === "admin")
                                    ) {
                                      return (
                                        <>
                                          {/* <div className="pad-3">
                                      <span style={{ fontWeight: "bold", fontSize: 14 }}>
                                      Claimed time:-{" "}
                                      </span>
                                      <span className="number opportunity-number">
                                        {this.props.selectedOpportunity.attendeeDeclaration.startTime}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}> To </span>
                                      <span className="number opportunity-number">
                                        {this.props.selectedOpportunity.attendeeDeclaration.endTime}
                                      </span>
                                    </div> */}
                                          <div
                                            onClick={this.showTimeModal.bind(
                                              this
                                            )}
                                            style={{
                                              cursor: "pointer",
                                              marginTop: 10,
                                            }}
                                            title={
                                              "You can change your Attendance"
                                            }
                                            className="declarationTag"
                                          >
                                            Edit Attendance
                                          </div>

                                          <div
                                            className="declarationTag"
                                            style={{ marginTop: 10 }}
                                          >
                                            Pending Approval
                                          </div>
                                        </>
                                      );
                                    }
                                    if (
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated ===
                                        true &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === true
                                    ) {
                                      return (
                                        <>
                                          {/* <div className="pad-15">
                                    <p className="claimed-time-p">
                                      <span className="claimed-time" >
                                        Claimed Time:-{" "}
                                      </span>
                                      {attendeesData[0]["startTime"] +
                                        " To " +
                                        attendeesData[0]["endTime"]}
                                    </p>
                                  </div> */}
                                          {/* <div className="pad-15">
                                      <span style={{ fontWeight: "bold", fontSize: 14 }}>
                                      Claimed time:-{" "}
                                      </span>
                                      <span className="number opportunity-number">
                                        {this.props.selectedOpportunity.attendeeDeclaration.startTime}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}> To </span>
                                      <span className="number opportunity-number">
                                        {this.props.selectedOpportunity.attendeeDeclaration.endTime}
                                      </span>
                                    </div> */}

                                          <div
                                            className="pad-15"
                                            style={{
                                              marginTop: "10px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            {/* <p className="claimed-time-p"> */}
                                            <span
                                              title={"Admin Approved Hours"}
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 14,
                                              }}
                                              className="claimed-time"
                                            >
                                              Approved Time:{" "}
                                            </span>
                                            <span className="number opportunity-number">
                                              {
                                                attendeesData[0][
                                                  "updatedStartTime"
                                                ]
                                              }
                                            </span>
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {" "}
                                              To{" "}
                                            </span>
                                            <span className="number opportunity-number">
                                              {
                                                attendeesData[0][
                                                  "updatedEndTime"
                                                ]
                                              }
                                            </span>
                                            {/* {attendeesData[0]["updatedStartTime"] +
                                        " - " +
                                        attendeesData[0]["updatedEndTime"]} */}
                                            {/* </p> */}
                                          </div>

                                          <div
                                            className="declarationTag"
                                            title={
                                              "Admin Declare your attendance "
                                            }
                                          >
                                            Approved
                                          </div>
                                          
                                          {organisationData.eePreferences
                                            .enableCertificateGenerationForFamily ? (
                                            <>
                                              <div
                                                onClick={this.showFamilyFriendsCertificateModal.bind(
                                                  this
                                                )}
                                                className="declarationTag"
                                                style={{
                                                  marginTop: "5%",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {this.state
                                                  .presentAttendeeNumber > 0
                                                  ? `View ${
                                                      this.state
                                                        .presentAttendeeNumber +
                                                      1
                                                    } 
                                    Certificates`
                                                  : `View ${
                                                      this.state
                                                        .presentAttendeeNumber +
                                                      1
                                                    } Certificate`}
                                              </div>
                                              <div
                                                  onClick={this.showTimeModal.bind(
                                                    this,false
                                                  )}
                                                  style={{
                                                    cursor: "pointer",
                                                    marginTop: 10,
                                                  }}
                                                  title={
                                                    "You can change your Attendance"
                                                  }
                                                  className="declarationTag"
                                                >
                                                  Edit friends/family Attendance
                                                </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={this.getCertificate.bind(
                                                this
                                              )}
                                              className="declarationTag"
                                              style={{
                                                marginTop: "5%",
                                                cursor: "pointer",
                                              }}
                                            >
                                              View Certificate
                                            </div>
                                          )}
                                        </>
                                      );
                                    }
                                    if (
                                      this.props.selectedOpportunity.attendeeDeclaration.hasOwnProperty(
                                        "startTime"
                                      ) &&
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === false
                                    ) {
                                      return (
                                        <>
                                          {/* <div className="pad-15">
                                    {/* <p
                                      title={"Your Claimed Time"}
                                      className="claimed-time-p"
                                    ><span className="claimed-time">
                                        Claimed time:-
                                      </span>
                                      {attendeesData[0]["startTime"] +
                                        " To " +
                                        attendeesData[0]["endTime"]}
                                    </p> */}
                                          {/* <span style={{ fontWeight: "bold", fontSize: 14 }}>
                                      Claimed time:-{" "}
                                      </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["startTime"]}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}> To </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["endTime"]}
                                      </span> */}
                                          {/* </div> */}

                                          <div
                                            className="declarationTag"
                                            style={{ marginTop: "1%" }}
                                            title={
                                              "Admin declined your attendance"
                                            }
                                          >
                                            Declined
                                          </div>
                                        </>
                                      );
                                    }

                                    if (
                                      attendeesData[0]["hoursClocked"] !==
                                        "N/A" &&
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === ""
                                    ) {
                                      return (
                                        <>
                                          {/* <div className="pad-10">
                                  <span style={{ fontWeight: "bold", fontSize: 14 }}>
                                      Claimed time:-{" "}
                                      </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["startTime"]}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}> To </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["endTime"]}
                                      </span>
                                    {/* <p className="claimed-time-p">
                                      <span className="claimed-time">
                                        Claimed Time:-{" "}
                                      </span>
                                      {attendeesData[0]["startTime"] +
                                        " To " +
                                        attendeesData[0]["endTime"]}
                                    </p> */}
                                          {/* </div> */}
                                          <a
                                            onClick={this.showTimeModal.bind(
                                              this
                                            )}
                                            className="declarationTag"
                                          >
                                            Edit Attendance
                                          </a>
                                          <div className="declarationTag">
                                            Pending Approval
                                          </div>
                                        </>
                                      );
                                    } else if (
                                      attendeesData[0]["hoursClocked"] !==
                                        "N/A" &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === true
                                    ) {
                                      return (
                                        <>
                                          {/* <div className="pad-10">
                                  <span style={{ fontWeight: "bold", fontSize: 14 }}>
                                      Claimed time:-{" "}
                                      </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["startTime"]}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}> To </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["endTime"]}
                                      </span>
                                    {/* <p className="claimed-time-p">
                                      <span className="claimed-time">
                                        Claimed Time:-{" "}
                                      </span>
                                      {attendeesData[0]["startTime"] +
                                        " To " +
                                        attendeesData[0]["endTime"]}
                                    </p> */}

                                          {/* </div>  */}
                                          {/* <div>
                                  <span style={{ fontWeight: "bold", fontSize: 14 }}>
                                      Claimed time:-{" "}
                                      </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["updatedStartTime"]}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}> To </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["updatedEndTime"]}
                                      </span>
                                    {/* <p className="claimed-time-p">
                                      <span
                                        title={"Admin Approved Hours"}
                                        className="claimed-time"
                                      >
                                        Approved Time:-{" "}
                                      </span>
                                      {attendeesData[0]["updatedStartTime"] +
                                        " - " +
                                        attendeesData[0]["updatedEndTime"]}
                                    </p> */}

                                          {/* </div>  */}
                                          <div
                                            className="declarationTag"
                                            title={
                                              "Admin Declare your attendance "
                                            }
                                          >
                                            Approved
                                          </div>
                                          {organisationData.eePreferences
                                            .enableCertificateGenerationForFamily ? (
                                            <>
                                              <div
                                                onClick={this.showFamilyFriendsCertificateModal.bind(
                                                  this
                                                )}
                                                className="declarationTag"
                                                style={{
                                                  marginTop: "5%",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {this.state
                                                  .presentAttendeeNumber > 0
                                                  ? `View ${
                                                      this.state
                                                        .presentAttendeeNumber +
                                                      1
                                                    } Certificates`
                                                  : `View ${
                                                      this.state
                                                        .presentAttendeeNumber +
                                                      1
                                                    } Certificate`}
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={this.getCertificate.bind(
                                                this
                                              )}
                                              className="declarationTag"
                                              style={{
                                                marginTop: "5%",
                                                cursor: "pointer",
                                              }}
                                            >
                                              View Certificate
                                            </div>
                                          )}
                                        </>
                                      );
                                    } else if (
                                      attendeesData[0]["hoursClocked"] !==
                                        "N/A" &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === false
                                    ) {
                                      return (
                                        <>
                                          {/* <div className="pad-10">
                                  <span style={{ fontWeight: "bold", fontSize: 14 }}>
                                      Claimed time:-{" "}
                                      </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["startTime"]}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}> To </span>
                                      <span className="number opportunity-number">
                                        {attendeesData[0]["endTime"]}
                                      </span>
                                    {/* <p
                                      title={"Your Claimed Hours"}
                                      className="claimed-time-p"
                                    >
                                      <span className="claimed-time">
                                        Claimed Time:-
                                      </span>
                                      {attendeesData[0]["startTime"] +
                                        " To " +
                                        attendeesData[0]["endTime"]}
                                    </p> */}

                                          {/* </div>  */}
                                          <div
                                            className="declarationTag"
                                            title={
                                              "Admin Declined your Attendence"
                                            }
                                          >
                                            Declined
                                          </div>
                                        </>
                                      );
                                    } else if (
                                      // if(this.props.selectedOpportunity.attendanceDeclaration.hasOwnProperty('updatedParticipatedAttendence'))
                                      attendeesData[0]["hoursClocked"] ===
                                        "N/A" &&
                                      this.props.selectedOpportunity
                                        .attendeeDeclaration.participated ===
                                        false &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === "" &&
                                      attendeesData[0]["adminName"] === "-"
                                    ) {
                                      return (
                                        <>
                                          <div className="pad-10">
                                            <div
                                              className="declarationTag"
                                              title={
                                                "You missed this opportunity"
                                              }
                                            >
                                              Missed
                                            </div>
                                            <div className="participated-in-opportunity">
                                              <a
                                                title={
                                                  "You can change your Attendance"
                                                }
                                                onClick={this.showTimeModal.bind(
                                                  this
                                                )}
                                                className="declarationTag"
                                              >
                                                Edit Attendance
                                              </a>
                                            </div>
                                            <div
                                              className="declarationTag"
                                              style={{ marginTop: 10 }}
                                            >
                                              Pending Approval
                                            </div>
                                          </div>
                                        </>
                                      );
                                    } else if (
                                      attendeesData[0]["hoursClocked"] ===
                                        "N/A" &&
                                      attendeesData[0]["participated"] ===
                                        false &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === true
                                    ) {
                                      return (
                                        <>
                                          <div
                                            className="declarationTag"
                                            title={
                                              "You missed this opportunity"
                                            }
                                          >
                                            Declared
                                          </div>

                                          <div
                                            style={{ marginTop: 15 }}
                                            className="declarationTag "
                                            title={
                                              "Admin approved your attendence"
                                            }
                                          >
                                            Approved
                                          </div>
                                          {organisationData.eePreferences
                                            .enableCertificateGenerationForFamily ? (
                                            <>
                                              <div
                                                onClick={this.showFamilyFriendsCertificateModal.bind(
                                                  this
                                                )}
                                                className="declarationTag"
                                                style={{
                                                  marginTop: "5%",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {this.state
                                                  .presentAttendeeNumber > 0
                                                  ? `View ${
                                                      this.state
                                                        .presentAttendeeNumber +
                                                      1
                                                    } Certificates`
                                                  : `View ${
                                                      this.state
                                                        .presentAttendeeNumber +
                                                      1
                                                    } Certificate`}
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={this.getCertificate.bind(
                                                this
                                              )}
                                              className="declarationTag"
                                              style={{
                                                marginTop: "5%",
                                                cursor: "pointer",
                                              }}
                                            >
                                              View Certificate
                                            </div>
                                          )}
                                        </>
                                      );
                                    } else if (
                                      attendeesData[0]["hoursClocked"] ===
                                        "N/A" &&
                                      attendeesData[0]["participated"] ===
                                        false &&
                                      attendeesData[0][
                                        "updatedParticipatedAttendence"
                                      ] === false
                                    ) {
                                      return (
                                        <>
                                          <div
                                            className="declarationTag"
                                            title={
                                              "You missed this opportunity"
                                            }
                                          >
                                            Missed
                                          </div>
                                          <div
                                            style={{ marginTop: 15 }}
                                            className="declarationTag "
                                            title={
                                              "Admin declined your attendence"
                                            }
                                          >
                                            Declined
                                          </div>
                                        </>
                                      );
                                    }
                                  } else {
                                    if (
                                      this.props.selectedOpportunity.status ===
                                      "Live"
                                    ) {
                                      return (
                                        <button
                                          className="reserve-seat-btn"
                                          disabled="disabled"
                                        >
                                          ALL SPOTS BOOKED
                                        </button>
                                      );
                                    } else {
                                      if (
                                        this.props.selectedOpportunity.hasOwnProperty(
                                          "attendeeDeclaration"
                                        ) &&
                                        // !this.state.attendanceValidation
                                        !this.props.selectedOpportunity
                                          .employeeAttendanceOverride
                                      ) {
                                        if (
                                          this.props.selectedOpportunity.attendeeDeclaration.hasOwnProperty(
                                            "updatedParticipatedAttendence"
                                          )
                                        ) {
                                          if (
                                            this.props.selectedOpportunity
                                              .attendeeDeclaration
                                              .updatedParticipatedAttendence
                                          ) {
                                            if (
                                              window.location.pathname.match(
                                                "/my-volunteer-history/"
                                              )
                                            ) {
                                              return (
                                                <>
                                                  {/* <span>
                                          <button
                                            className="reserve-seat-btn"
                                            disabled="disabled"
                                          >
                                            {
                                              this.props.selectedOpportunity
                                                .status
                                            }
                                          </button> */}
                                                  {/* <SvgIcon
                                          style={{
                                            background: "#66BB6A",
                                            padding: "5px 10px",
                                            margin: "5px",
                                            borderRadius: "7px",
                                            color: "#EEEEEE",
                                          }}
                                          icon={check}
                                          title={
                                            "Your Attendence is Approved"
                                          }
                                        /> */}
                                                  {/* </span> */}
                                                </>
                                              );
                                            }
                                          }
                                          if (
                                            !this.props.selectedOpportunity
                                              .attendeeDeclaration
                                              .updatedParticipatedAttendence
                                          ) {
                                            if (
                                              !(this.props.role === "admin")
                                            ) {
                                              return (
                                                <>
                                                  {/* <button
                                          className="reserve-seat-btn"
                                          disabled="disabled"
                                        >
                                          {
                                            this.props.selectedOpportunity
                                              .status
                                          }
                                        </button>
                                        <span>
                                          <SvgIcon
                                            style={{
                                              background: "#90A4AE",
                                              padding: "5px 10px",
                                              margin: "5px",
                                              borderRadius: "7px",
                                              color: "#EEEEEE",
                                            }}
                                            icon={close}
                                            title={
                                              "Your Attendence is Declined"
                                            }
                                          />
                                        </span> */}
                                                </>
                                              );
                                            }
                                          }
                                        } else {
                                          if (
                                            window.location.pathname.match(
                                              "/my-volunteer-history/"
                                            ) &&
                                            // this.state.attendanceValidation
                                            this.props.selectedOpportunity
                                              .employeeAttendanceOverride
                                          ) {
                                            let data =
                                              this.props.selectedOpportunity
                                                .attendees;

                                            let attendeesData = data.filter(
                                              (data) =>
                                                data.id ===
                                                JSON.parse(
                                                  readCookie("userData")
                                                ).id
                                            );

                                            if (
                                              attendeesData[0][
                                                "hoursClocked"
                                              ] === "N/A" &&
                                              attendeesData[0]["declared"] ===
                                                ""
                                            ) {
                                              return (
                                                <div className="participated-in-opportunity">
                                                  <span className="title">
                                                    {/* Declare Your attendance */}
                                                  </span>

                                                  <a
                                                    onClick={this.showTimeModal.bind(
                                                      this
                                                    )}
                                                    className="btn btn-sq btn-le mr10 btn-declare-attendance"
                                                  >
                                                    Declare Attendance
                                                  </a>
                                                </div>
                                              );
                                            } else if (
                                              attendeesData[0][
                                                "hoursClocked"
                                              ] !== "N/A" &&
                                              attendeesData[0][
                                                "participated"
                                              ] !== "" &&
                                              attendeesData[0][
                                                "updatedParticipatedAttendence"
                                              ] === ""
                                            ) {
                                              return (
                                                <>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                        fontSize: 14,
                                                      }}
                                                    >
                                                      Claimed hours
                                                    </span>
                                                    <span>
                                                      {attendeesData[0][
                                                        "startTime"
                                                      ] +
                                                        " - " +
                                                        attendeesData[0][
                                                          "endTime"
                                                        ] +
                                                        " (" +
                                                        attendeesData[0][
                                                          "hoursClocked"
                                                        ] +
                                                        " hours)"}
                                                    </span>
                                                  </div>
                                                  <div className="reserve-seat-btn">
                                                    Pending Approval
                                                  </div>
                                                </>
                                              );
                                            } else if (
                                              attendeesData[0][
                                                "hoursClocked"
                                              ] !== "N/A" &&
                                              attendeesData[0][
                                                "updatedParticipatedAttendence"
                                              ] === true
                                            ) {
                                              return (
                                                <>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                        fontSize: 14,
                                                      }}
                                                    >
                                                      Claimed hours
                                                    </span>{" "}
                                                    <span>
                                                      {attendeesData[0][
                                                        "startTime"
                                                      ] +
                                                        " - " +
                                                        attendeesData[0][
                                                          "endTime"
                                                        ] +
                                                        " (" +
                                                        attendeesData[0][
                                                          "hoursClocked"
                                                        ] +
                                                        " hours)"}
                                                    </span>
                                                  </div>
                                                  <span>
                                                    <span>
                                                      <div className="reserve-seat-btn">
                                                        Approved Hours
                                                      </div>
                                                    </span>
                                                    <span>
                                                      {attendeesData[0][
                                                        "updatedStartTime"
                                                      ] +
                                                        " - " +
                                                        attendeesData[0][
                                                          "updatedEndTime"
                                                        ] +
                                                        " (" +
                                                        attendeesData[0][
                                                          "updatedHoursTime"
                                                        ] +
                                                        " hours)"}
                                                    </span>
                                                  </span>
                                                </>
                                              );
                                            } else if (
                                              attendeesData[0][
                                                "hoursClocked"
                                              ] !== "N/A" &&
                                              attendeesData[0][
                                                "participated"
                                              ] !== "" &&
                                              attendeesData[0][
                                                "updatedParticipatedAttendence"
                                              ] === "" &&
                                              attendeesData[0]["declared"] ===
                                                true
                                            ) {
                                              return (
                                                <>
                                                  <button
                                                    className="reserve-seat-btn"
                                                    disabled="disabled"
                                                  >
                                                    {
                                                      this.props
                                                        .selectedOpportunity
                                                        .status
                                                    }
                                                  </button>
                                                  );
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                        fontSize: 14,
                                                      }}
                                                    >
                                                      Claimed hours
                                                    </span>{" "}
                                                    <span>
                                                      {attendeesData[0][
                                                        "startTime"
                                                      ] +
                                                        " - " +
                                                        attendeesData[0][
                                                          "endTime"
                                                        ] +
                                                        " (" +
                                                        attendeesData[0][
                                                          "hoursClocked"
                                                        ] +
                                                        " hours)"}
                                                    </span>
                                                  </div>
                                                  <span>
                                                    <div className="reserve-seat-btn">
                                                      Declined
                                                    </div>
                                                  </span>
                                                </>
                                              );
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })()}
                                {(() => {
                                  if (
                                    false &&
                                    !this.props.selectedOpportunity
                                      .attendeeDeclaration
                                  ) {
                                    if (
                                      this.props.selectedOpportunity.status ===
                                        "Ongoing" &&
                                      this.props.selectedOpportunity
                                        .isUserAttendee &&
                                      !this.props.selectedOpportunity.attendeeStarted.hasOwnProperty(
                                        "startTime"
                                      )
                                    ) {
                                      return (
                                        <div className="opportunity-started-container mt20">
                                          <button
                                            className="btn btn-endorse inverted-colors"
                                            onClick={this.checkStartTimer.bind(
                                              this
                                            )}
                                          >
                                            <i className="fa fa-clock-o"></i>{" "}
                                            Click to Start Timer
                                          </button>
                                          <span
                                            className="no-show-span note mt10"
                                            onClick={this.showWontAttendModal.bind(
                                              this
                                            )}
                                          >
                                            I will not be able to attend this
                                            opportunity
                                          </span>
                                        </div>
                                      );
                                    } else if (
                                      this.props.selectedOpportunity.status ===
                                        "Ongoing" &&
                                      this.props.selectedOpportunity
                                        .isUserAttendee &&
                                      this.props.selectedOpportunity
                                        .attendeeStarted &&
                                      this.props.selectedOpportunity.attendeeStarted.hasOwnProperty(
                                        "startTime"
                                      )
                                    ) {
                                      return (
                                        <button
                                          className="btn btn-endorse inverted-colors mt20"
                                          onClick={this.checkEndTimer.bind(
                                            this
                                          )}
                                        >
                                          <i className="fa fa-clock-o"></i>{" "}
                                          Click to Stop Timer
                                        </button>
                                      );
                                    }
                                  }
                                })()}
                              </div>
                            </div>
                            {this.state.registrationsAllowed > 0 && (
                              <Row>
                                <Col md={3} />
                                <Col>
                                  <h5
                                    style={{ marginLeft: 70, marginBottom: 10 }}
                                  >
                                    Friends/ Family members allowed
                                  </h5>
                                  {/* <h5 style={{ marginBottom: 10 }}>upto {this.state.registrationsAllowed} family members/friends allowed</h5> */}
                                </Col>
                              </Row>
                            )}
                          </Col>

                          {this.props.role === "admin" &&
                            ["Completed", "Live"].includes(
                              this.props.selectedOpportunity.status
                            ) && (
                              <Col
                                md={12}
                                className="opportunity-details-snapshot"
                                style={{ paddingBottom: "20px" }}
                              >
                                <div className="row budget-block">
                                  <div
                                    className="expected-impact-text"
                                    style={{ textTransform: "none" }}
                                  >
                                    Add participants
                                  </div>
                                </div>
                                <div
                                  onClick={this.openAddParticipantsModal.bind(
                                    this
                                  )}
                                  className="declarationTag"
                                  style={{ marginTop: "5%", cursor: "pointer" }}
                                >
                                  Add participants
                                </div>
                              </Col>
                            )}
                          {this.state.projectLeaderContact ? (
                            <Col
                              md={12}
                              className="opportunity-details-snapshot"
                            >
                              <div className="row budget-block">
                                <div
                                  className="expected-impact-text"
                                  style={{ textTransform: "none" }}
                                >
                                  Emergency/ Project Leader Contact
                                </div>
                              </div>
                              <div className="row snapshot-container activities-undertaken">
                                <div className="activity-list">
                                  <div className="single-activity-list">
                                    <span style={{ fontWeight: "bold" }}>
                                      {this.state.projectLeaderContact}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ) : null}

                          {this.props.selectedOpportunity.activities.length >
                          0 ? (
                            <Col
                              md={12}
                              className="opportunity-details-snapshot"
                            >
                              <div className="row budget-block">
                                <div className="expected-impact-text">
                                  Activities Undertaken
                                </div>
                              </div>
                              <div className="row snapshot-container activities-undertaken">
                                <div className="activity-list">
                                  {this.props.selectedOpportunity.activities.map(
                                    function (activity, i) {
                                      return (
                                        <div
                                          className="single-activity-list"
                                          key={i}
                                        >
                                          <Icon
                                            icon={dotCircleO}
                                            className="single-activity-icon le-color"
                                          />
                                          <span style={{ fontWeight: "bold" }}>
                                            {activity.name}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </Col>
                          ) : null}

                          {this.state.dos.length > 0 ? (
                            <Col
                              md={12}
                              className="opportunity-details-snapshot"
                            >
                              <div className="row budget-block">
                                <div
                                  className="expected-impact-text"
                                  style={{ textTransform: "none" }}
                                >
                                  Important Instructions
                                </div>
                              </div>
                              <div className="row snapshot-container activities-undertaken">
                                <div className="activity-list">
                                  {this.state.dos.map(function (item, i) {
                                    return (
                                      <div
                                        className="single-activity-list"
                                        key={i}
                                      >
                                        <Icon
                                          icon={dotCircleO}
                                          className="single-activity-icon le-color"
                                        />
                                           {/<\/?[a-z][\s\S]*>/i.test(item.name) ? <div
                                              className="ql-editor"
                                              style={{
                                                padding: "0px",
                                                wordBreak:'break-all',
                                                color: "#707070",
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                item.name
                                              }}
                                            />
                                          :<span style={{ fontWeight: 'bold'   }}>
                                          {item.name}
                                        </span>  }
                                      </div>
                                    );
                                  })}
                                  {!this.state.facilities.includes(
                                    "mealOrSnack"
                                  ) &&
                                  this.props.selectedOpportunity
                                    .physicalOpportunity &&
                                  subdomain.toLowerCase() === "indusind" ? (
                                    <div className="single-activity-list">
                                      <Icon
                                        icon={dotCircleO}
                                        className="single-activity-icon le-color"
                                      />
                                      <span style={{ fontWeight: "bold" }}>
                                        Please carry your own meal/snacks.
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                          ) : null}

                          {this.props.selectedOpportunity &&
                          this.props.selectedOpportunity.longitude ? (
                            <Col
                              md={12}
                              className="opportunity-details-snapshot"
                            >
                              <SingleOpportunityMap
                                selectedOpportunity={
                                  this.props.selectedOpportunity
                                }
                              />
                            </Col>
                          ) : null}
                          <Col style={{ marginBottom: 15 }}>
                            <div align="center">
                              <a
                                onClick={
                                  this.props.selectedOpportunity
                                    .physicalOpportunity &&
                                  this.showDisclaimerModal.bind(this)
                                }
                                href={
                                  !this.props.selectedOpportunity
                                    .physicalOpportunity &&
                                  this.props.selectedOpportunity
                                    .virtualOpportunityUrl
                                }
                                // target='_blank'
                                // onClick={this.props.selectedOpportunity.physicalOpportunity && this.openLocationInMap.bind(this, this.props.selectedOpportunity.addressUrl)}
                              >
                                Take me to the link
                              </a>
                            </div>
                          </Col>
                          {this.props.selectedOpportunity.attendees.length &&
                          this.props.participantsData.length ? (
                            <Col
                              md={12}
                              className="opportunity-details-snapshot attendees-container"
                            >
                              <div className="row budget-block">
                                <div className="expected-impact-text">
                                  Participants
                                </div>
                              </div>
                              {this.props.selectedOpportunity.attendees
                                .slice(0, 3)
                                .map(
                                  function (attendee, index) {
                                    const parsedTime = moment(
                                      attendee?.updatedHoursTime ||
                                        attendee?.hoursClocked ||
                                        "0:0",
                                      "H:m"
                                    );
                                    // Format the parsed time as 'HH:mm'
                                    const formattedTime =
                                      parsedTime.format("HH:mm");
                                    return (
                                      <div
                                        className="attendee-container"
                                        key={index}
                                      >
                                        <div
                                          className="attendee-prof-pic"
                                          style={
                                            attendee.profilePicture
                                              ? {
                                                  backgroundImage:
                                                    'url("' +
                                                    attendee.profilePicture +
                                                    '")',
                                                }
                                              : {
                                                  backgroundImage:
                                                    'url("/img/user.png")',
                                                }
                                          }
                                        ></div>
                                        <div
                                          className={
                                            attendee.department ||
                                            attendee.hoursClocked !== "N/A"
                                              ? "user-details-container"
                                              : "user-details-container center"
                                          }
                                        >
                                          <div className="user-name">
                                            {attendee.fullName}
                                          </div>
                                          {attendee.department ? (
                                            <div className="user-department">
                                              {attendee.department}
                                            </div>
                                          ) : null}

                                          {attendee.hoursClocked !== "N/A" &&
                                          (attendee.updatedHoursTime ||
                                            attendee.hoursClocked) ? (
                                            <div className="user-hours-clocked">
                                              {formattedTime + " hours" ||
                                                attendee.hoursClocked +
                                                  " hours"}
                                            </div>
                                          ) : null}
                                        </div>
                                        <span>
                                          {this.props.selectedOpportunity
                                            .status === "Completed" ? (
                                            attendee.updatedParticipatedAttendence ? (
                                              <div className="user-declared">
                                                Attendance Approved
                                              </div>
                                            ) : attendee.participated === "" ? (
                                              <div className="user-pending-declaration">
                                                Pending Declaration
                                              </div>
                                            ) : !attendee.participated ? (
                                              <div
                                                className="user-declared"
                                                title={attendee.reason}
                                              >
                                                Missed
                                              </div>
                                            ) : (
                                              <div
                                                className="user-declared"
                                                title={attendee.experience}
                                              >
                                                Declared
                                              </div>
                                            )
                                          ) : null}
                                        </span>
                                      </div>
                                    );
                                  }.bind(this)
                                )}
                              <div className="row show-participant-details">
                                <span className="show-participant-details-span">
                                  <a
                                    onClick={() =>
                                      this.setState({ activeTab: 3 })
                                    }
                                  >
                                    Show all participants
                                  </a>
                                </span>
                              </div>
                            </Col>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <SingleOpportunityApplyModal
                      volunteerOpportunity={this.props.selectedOpportunity}
                      showReserveModal={this.state.showReserveModal}
                      openReserveModal={this.openReserveModal}
                      updateSelectedOpportunity={
                        this.props.actions.updateSelectedOpportunity
                      }
                      fetchOpportunityDetails={
                        this.fetchUpdateOpportunityDetails
                      }
                      notifyError={this.notifyError}
                      closeReserveModal={this.closeReserveModal}
                      showAddToCalendarModal={this.showAddToCalendarModal.bind(
                        this
                      )}
                      role={this.props.role}
                    />

                    {subdomain.toLowerCase() === "indusind" ? (
                      <Modal
                        show={this.state.showAddToCalendarModal}
                        onHide={this.closeAddToCalendarModal.bind(this)}
                        className="square-modal add-to-calendar-modal "
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body bsClass="swal2-modal">
                          <Row className="m0">
                            <div
                              className="swal2-icon swal2-success swal2-animate-success-icon"
                              style={{ display: "block" }}
                            >
                              <div
                                className="swal2-success-circular-line-left"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                              <span className="swal2-success-line-tip swal2-animate-success-line-tip"></span>
                              <span className="swal2-success-line-long swal2-animate-success-line-long"></span>
                              <div className="swal2-success-ring"></div>
                              <div
                                className="swal2-success-fix"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                              <div
                                className="swal2-success-circular-line-right"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                            </div>
                            <h2 className="swal2-title" id="swal2-title">
                              Awesome!
                            </h2>
                            <div
                              id="swal2-content"
                              className="swal2-content"
                              style={{ display: "block" }}
                            >
                              You have reserved your spot.
                            </div>

                            {/* <AddToCalendar
                    event={this.state.addToCalendarEvent}
                    buttonTemplate={{ "calendar-plus-o": "left" }}
                  /> */}

                            <a
                              href={outlook(this.state.addToCalendarEvent)}
                              target="_blank"
                            >
                              {" "}
                              <button className="addtocalendar">
                                <Icon icon={calendar} className="list-icon" />
                                <span> Add to Calendar</span>
                              </button>
                            </a>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    ) : (
                      <Modal
                        show={this.state.showAddToCalendarModal}
                        onHide={this.closeAddToCalendarModal.bind(this)}
                        className="square-modal add-to-calendar-modal add-to-calendar"
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body bsClass="swal2-modal">
                          <Row className="m0">
                            <div
                              className="swal2-icon swal2-success swal2-animate-success-icon"
                              style={{ display: "block" }}
                            >
                              <div
                                className="swal2-success-circular-line-left"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                              <span className="swal2-success-line-tip swal2-animate-success-line-tip"></span>
                              <span className="swal2-success-line-long swal2-animate-success-line-long"></span>
                              <div className="swal2-success-ring"></div>
                              <div
                                className="swal2-success-fix"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                              <div
                                className="swal2-success-circular-line-right"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                            </div>
                            <h2 className="swal2-title" id="swal2-title">
                              {" "}
                              Awesome!{" "}
                            </h2>
                            <div
                              id="swal2-content"
                              className="swal2-content"
                              style={{ display: "block" }}
                            >
                              You have reserved your spot.
                            </div>

                            {/* <AddToCalendar
                                 event={this.state.addToCalendarEvent}
                                  buttonTemplate={{ "calendar-plus-o": "left" }}
                                /> */}

                            <div className="dropdown">
                              <button className="dropbtn addtocalendar">
                                <Icon icon={calendar} className="list-icon" />
                                <span> Add to Calendar</span>
                              </button>
                              <div className="addtocalendar dropdown-content">
                                <a
                                  href={outlook(this.state.addToCalendarEvent)}
                                  target="_blank"
                                >
                                  Outlook
                                </a>
                                <a
                                  href={google(this.state.addToCalendarEvent)}
                                  target="_blank"
                                >
                                  Google
                                </a>
                                <a
                                  href={yahoo(this.state.addToCalendarEvent)}
                                  target="_blank"
                                >
                                  Yahoo
                                </a>
                                <a
                                  href={office365(
                                    this.state.addToCalendarEvent
                                  )}
                                  target="_blank"
                                >
                                  Office365
                                </a>
                                <a
                                  href={ics(this.state.addToCalendarEvent)}
                                  target="_blank"
                                >
                                  Ics
                                </a>
                              </div>
                            </div>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    )}
                    <Modal
                      show={this.state.startTimerConfirmModalVisibility}
                      onHide={this.closeStartTimerConfirmModal.bind(this)}
                      className="square-modal start-timer-confirm-modal"
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body bsClass="swal2-modal">
                        <Row className="m0">
                          <div className="swal2-icon swal2-question swal2-animate-question-icon">
                            <span className="swal2-icon-text">?</span>
                          </div>
                          <h2
                            className="swal2-title mr10 ml10"
                            id="swal2-title"
                          >
                            Are you sure you wish to start your volunteering
                            timer?
                          </h2>
                          <div
                            id="swal2-content"
                            className="swal2-content"
                            style={{ display: "block" }}
                          >
                            {"You seem to be "}
                            <a>{parseInt(this.state.distance) + " metres"}</a>
                            {" away from the event location."}
                          </div>
                          <div className="modal-btn-group">
                            <button
                              className="btn-sq btn-endorse"
                              onClick={this.startTimerConfirmed.bind(this)}
                            >
                              Confirm
                            </button>
                            <button
                              className="btn-sq btn-endorse btn-cancel"
                              onClick={this.closeStartTimerConfirmModal.bind(
                                this
                              )}
                            >
                              Cancel
                            </button>
                          </div>
                        </Row>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={this.state.cannotStartTimerModalVisibility}
                      onHide={this.closeCannotStartTimerModal.bind(this)}
                      className="square-modal cannot-start-timer-modal"
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body bsClass="swal2-modal">
                        <Row className="m0">
                          <div className="swal2-icon swal2-error swal2-animate-error-icon">
                            <span className="swal2-x-mark">
                              <span className="swal2-x-mark-line-left"></span>
                              <span className="swal2-x-mark-line-right"></span>
                            </span>
                          </div>
                          <h2
                            className="swal2-title mr10 ml10"
                            id="swal2-title"
                          >
                            You are too far away from the volunteering event
                            location.
                          </h2>
                          <div
                            id="swal2-content"
                            className="swal2-content"
                            style={{ display: "block" }}
                          >
                            Please come near the event location to start
                            volunteering.
                          </div>
                          <div className="modal-btn-group">
                            <button
                              className="btn-sq btn-endorse"
                              onClick={this.closeCannotStartTimerModal.bind(
                                this
                              )}
                            >
                              Ok
                            </button>
                          </div>
                        </Row>
                      </Modal.Body>
                    </Modal>

                    {this.state.endTime !== null ? (
                      <Modal
                        show={this.state.endTimerModalVisibility}
                        onHide={this.closeEndTimerModal.bind(this)}
                        className="square-modal end-timer-modal"
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body bsClass="swal2-modal">
                          <Row className="m0">
                            <div className="swal2-icon swal2-question swal2-animate-question-icon">
                              <span className="swal2-icon-text">?</span>
                            </div>
                            <h2
                              className="swal2-title mr20 ml20"
                              id="swal2-title"
                            >
                              {"You have clocked "}
                              <a>{this.getVolunteeringHours("now")}</a>
                              {" of volunteering today."}
                            </h2>
                            <div
                              id="swal2-content"
                              className="swal2-content"
                              style={{ display: "block" }}
                            >
                              Are you sure you have finished your volunteering
                              day? Your confirmation cannot be reversed.
                            </div>
                            <div className="modal-btn-group">
                              <button
                                className="btn-sq btn-endorse"
                                onClick={this.endTimerConfirmed.bind(this)}
                              >
                                Yes, Make me exit
                              </button>
                              <button
                                className="btn-sq btn-endorse btn-cancel"
                                onClick={this.closeEndTimerModal.bind(this)}
                              >
                                Cancel
                              </button>
                            </div>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    ) : null}

                    {this.state.endTime !== null ? (
                      <Modal
                        show={this.state.submitFeedbackModalVisibility}
                        onHide={this.closeSubmitFeedbackModal.bind(this)}
                        className="square-modal submit-feedback-modal"
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body bsClass="swal2-modal">
                          <Row className="m0">
                            <div
                              className="swal2-icon swal2-success swal2-animate-success-icon"
                              style={{ display: "block" }}
                            >
                              <div
                                className="swal2-success-circular-line-left"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                              <span className="swal2-success-line-tip swal2-animate-success-line-tip"></span>
                              <span className="swal2-success-line-long swal2-animate-success-line-long"></span>
                              <div className="swal2-success-ring"></div>
                              <div
                                className="swal2-success-fix"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                              <div
                                className="swal2-success-circular-line-right"
                                style={{ background: "rgb(255, 255, 255)" }}
                              ></div>
                            </div>
                            <h2
                              className="swal2-title mr20 ml20"
                              id="swal2-title"
                            >
                              {"Thank you for spending your precious "}
                              <a>{this.getVolunteeringHours("later")}</a>
                              {
                                " towards voluntary contribution to the society."
                              }
                            </h2>
                            <div
                              id="swal2-content"
                              className="swal2-content"
                              style={{ display: "block" }}
                            >
                              <span>
                                We appreciate your commitment and initiative.
                              </span>
                              <div className="mt15 mb15 form-group">
                                <textarea
                                  className="form-control"
                                  placeholder="Kindly provide your feedback"
                                  style={{ height: "150px" }}
                                  onChange={this.onChange.bind(this)}
                                ></textarea>
                              </div>
                            </div>
                            <div className="modal-btn-group">
                              <button
                                className="btn-sq btn-endorse"
                                onClick={this.feedbackConfirmed.bind(this)}
                              >
                                Submit
                              </button>
                              <button
                                className="btn-sq btn-endorse btn-cancel"
                                onClick={this.closeSubmitFeedbackModal.bind(
                                  this
                                )}
                              >
                                Cancel
                              </button>
                            </div>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    ) : null}
                    <Modal
                      show={this.state.wontAttendModalVisibility}
                      onHide={this.hideWontAttendModal.bind(this)}
                      className="square-modal wont-attend-modal"
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body bsClass="swal2-modal">
                        <Row className="m0">
                          <div className="swal2-icon swal2-question swal2-animate-question-icon">
                            <span className="swal2-icon-text">?</span>
                          </div>
                          <div
                            id="swal2-content"
                            className="swal2-content"
                            style={{ display: "block" }}
                          >
                            Are you sure you won't attend? Your confirmation
                            cannot be reversed.
                          </div>
                          <label className="control-label is-imp">
                            Please share the reason behind missing this, with
                            your HR.
                          </label>
                          <Col md={12} className="form-group">
                            <Col md={3} style={{ marginLeft: "25%" }}>
                              <select
                                id="type"
                                defaultValue={this.state.wontAttendReason}
                                onChange={(e) =>
                                  this.setState({
                                    wontAttendReason: e.target.value,
                                  })
                                }
                              >
                                <option value="">Choose one</option>
                                <option value="Got engaged with another official work">
                                  Got engaged with another official work{" "}
                                </option>
                                <option value="Got engaged with another unofficial work">
                                  Got engaged with another unofficial work
                                </option>
                                <option value="Had an emergency situation">
                                  Had an emergency situation
                                </option>
                                <option value="Forgot about the event">
                                  Forgot about the event
                                </option>
                                <option value="Opted for another event">
                                  Opted for another event
                                </option>
                                <option value="Another Reason">
                                  Another Reason
                                </option>
                              </select>
                              {this.state.error.indexOf("reason") > -1 ? (
                                <span className="red-color">
                                  Incorrect Reason!
                                </span>
                              ) : null}
                            </Col>

                            {/* <textarea
                      className="form-control"
                      id="reason"
                      onChange={(e) => {
                        this.setState({ wontAttendReason: e.target.value });
                      }}
                      required
                    ></textarea> */}
                          </Col>

                          <div className="modal-btn-group">
                            <button
                              className="btn-sq btn-endorse"
                              onClick={this.confirmWontAttend.bind(this)}
                            >
                              Yes, I won't
                            </button>
                            <button
                              className="btn-sq btn-endorse btn-cancel"
                              onClick={this.hideWontAttendModal.bind(this)}
                            >
                              Cancel
                            </button>
                          </div>
                        </Row>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={this.state.showViewPeopleModal}
                      onHide={this.closeViewPeopleModal}
                      className="square-modal view-people-coming-modal"
                    >
                      {this.state.propsData && this.state.participantsData && (
                        <>
                          <Modal.Header closeButton>
                            <Modal.Title>
                              {this.state.propsData.selectedOpportunity
                                .status === "Completed"
                                ? "PEOPLE WHO HAD PARTICIPATED ("
                                : "PEOPLE WHO HAVE PARTICIPATED ("}
                              {
                                this.state.propsData.selectedOpportunity
                                  .attendees.length
                              }
                              /
                              {
                                this.props.selectedOpportunity
                                  .volunteersRequired
                              }
                              )
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body bsClass="pad-0-15">
                            <Row className="m0 mt20 mb20">
                              <BootstrapTable
                                data={this.state.participantsData}
                                hover
                                exportCSV
                                csvFileName={
                                  this.state.propsData.selectedOpportunity
                                    .title +
                                  " - Participants - " +
                                  String(moment().unix()) +
                                  ".csv"
                                }
                              >
                                <TableHeaderColumn isKey dataField="id" hidden>
                                  ID
                                </TableHeaderColumn>
                                {JSON.parse(readCookie("userData")).role ===
                                "admin" ? (
                                  <TableHeaderColumn
                                    width="100px"
                                    dataField="Employee ID"
                                    export
                                    dataFormat={this.checkEmpId}
                                  >
                                    Employee ID
                                  </TableHeaderColumn>
                                ) : null}
                                <TableHeaderColumn
                                  width="180px"
                                  dataField="fullName"
                                  export
                                  dataSort
                                  dataFormat={this.checkName}
                                >
                                  Name
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  width="130px"
                                  dataField="department"
                                  export
                                  dataSort
                                >
                                  Department
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  width="120px"
                                  dataField="Mobile"
                                  export
                                  dataSort
                                >
                                  Mobile
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  width="120px"
                                  dataField="Attended Event"
                                  export
                                  dataSort
                                >
                                  Employee Attended
                                </TableHeaderColumn>

                                {this.props.selectedOpportunity.status ===
                                "Completed" ? (
                                  <TableHeaderColumn
                                    width="170px"
                                    dataField="Declared Attendance"
                                    dataFormat={this.declaredAttendance}
                                    export
                                    dataSort
                                  >
                                    Declared Attendance
                                  </TableHeaderColumn>
                                ) : null}

                                {JSON.parse(readCookie("userData")).role ===
                                "admin" ? (
                                  <TableHeaderColumn
                                    width="140px"
                                    dataField="Declaration Type"
                                    dataFormat={this.declarationtype}
                                    export
                                    dataSort
                                  >
                                    Declaration Type
                                  </TableHeaderColumn>
                                ) : null}
                                {JSON.parse(readCookie("userData")).role ===
                                "admin" ? (
                                  <TableHeaderColumn
                                    width="140px"
                                    dataField="tshirtCollected"
                                    export
                                    dataSort
                                    dataFormat={this.tshirtCollectedFormatter.bind(
                                      this
                                    )}
                                  >
                                    T-shirt collected
                                  </TableHeaderColumn>
                                ) : null}
                                {this.state.propsData.selectedOpportunity
                                  .status === "Completed" ? (
                                  <TableHeaderColumn
                                    width="100px"
                                    export
                                    dataField="Start Time"
                                    hidden
                                  >
                                    Start Time
                                  </TableHeaderColumn>
                                ) : null}
                                {this.state.propsData.selectedOpportunity
                                  .status === "Completed" ? (
                                  <TableHeaderColumn
                                    width="100px"
                                    export
                                    dataField="End Time"
                                    hidden
                                  >
                                    End Time
                                  </TableHeaderColumn>
                                ) : null}
                                {this.state.propsData.selectedOpportunity
                                  .status === "Completed" ? (
                                  <TableHeaderColumn
                                    width="200px"
                                    export
                                    dataField="hoursClocked"
                                    dataFormat={this.getHours}
                                  >
                                    Claimed hours
                                  </TableHeaderColumn>
                                ) : null}

                                {/* {this.props.selectedOpportunity.status ==="Completed" ?} */}
                                {this.props.selectedOpportunity.status ===
                                  "Completed" &&
                                // this.state.attendanceValidation
                                this.props.selectedOpportunity
                                  .employeeAttendanceOverride ? (
                                  <TableHeaderColumn
                                    width="220px"
                                    export
                                    dataFormat={this.adminDeclaredAttendance}
                                  >
                                    Admin Declared Attendance
                                  </TableHeaderColumn>
                                ) : null}

                                {this.props.selectedOpportunity.status ===
                                  "Completed" &&
                                // this.state.attendanceValidation
                                this.props.selectedOpportunity
                                  .employeeAttendanceOverride ? (
                                  <TableHeaderColumn
                                    width="200px"
                                    export
                                    dataField="updatedHoursTime"
                                    dataFormat={this.approvedHours}
                                  >
                                    Approved Hours
                                  </TableHeaderColumn>
                                ) : null}

                                {this.props.selectedOpportunity.status ===
                                  "Completed" &&
                                // this.state.attendanceValidation
                                this.props.selectedOpportunity
                                  .employeeAttendanceOverride ? (
                                  <TableHeaderColumn
                                    width="140px"
                                    export
                                    dataField="adminName"
                                  >
                                    Approver name
                                  </TableHeaderColumn>
                                ) : null}

                                {this.props.selectedOpportunity.status ===
                                  "Completed" &&
                                this.props.selectedOpportunity
                                  .employeeAttendanceOverride &&
                                // this.state.attendanceValidation
                                this.props.match.path ===
                                  "/admin/opportunity/:name" ? (
                                  <TableHeaderColumn
                                    width="140px"
                                    export
                                    dataFormat={this.editButton}
                                  >
                                    Edit
                                  </TableHeaderColumn>
                                ) : null}
                              </BootstrapTable>
                            </Row>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              onClick={this.closeViewPeopleModal}
                              className="btn-sq btn-cancel"
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </>
                      )}
                    </Modal>
                    {this.state.attendeeId !== null &&
                    this.state.showAdminDeclareModal ? (
                      <AdminDeclareModal
                        setParticipants={(data) =>
                          this.setState({ participantsData: data })
                        }
                        participants={this.state.participantsData}
                        opportunityId={this.state.attendeeId.id}
                        index={this.state.editingRowIndex}
                        selectedOppurtunityId={
                          this.props.selectedOpportunity.id
                        }
                        showDeclareModalVisibility={
                          this.state.showAdminDeclareModal
                        }
                        hideAdminDeclareModal={this.hideDeclareModal.bind(this)}
                      />
                    ) : null}
                    {
                      this.state.displayBackoutModal && (
                        <SingleOpportunityApplyModal
                          volunteerOpportunity={this.props.selectedOpportunity}
                          showReserveModal={this.state.displayBackoutModal}
                          openReserveModal={this.openReserveModal}
                          updateSelectedOpportunity={
                            this.props.actions.updateSelectedOpportunity
                          }
                          notifyError={this.notifyError}
                          fetchOpportunityDetails={
                            this.fetchUpdateOpportunityDetails
                          }
                          closeReserveModal={() =>
                            this.setState({ displayBackoutModal: false })
                          }
                          showAddToCalendarModal={this.showAddToCalendarModal.bind(
                            this
                          )}
                        />
                      )

                      // <SingleOpportunityBackoutModal
                      //   show={this.state.displayBackoutModal}
                      //   handleClose={() => this.setState({ displayBackoutModal: false })}
                      //   opportunity={this.props.selectedOpportunity}
                      // />
                    }
                    {this.state.showEmployeeTimeModal ? (
                      <DeclareModal
                      volunteerOpportunity={this.props.selectedOpportunity}
                        enableParticipantsForTimeChange={
                          organisationData.eePreferences
                            .enableParticipantsForTimeChange
                        }
                        opportunityId={this.props.selectedOpportunity.id}
                        showDeclareModalVisibility={
                          this.state.showEmployeeTimeModal
                        }
                        hideDeclareModal={this.hideEmployeeTimeModal.bind(this)}
                        participants={this.props}
                        allowfeedback={this.state.allowfeedback}
                      />
                    ) : null}
                    {this.state.disclaimerModal ? (
                      <DisclaimerModal
                        disclaimerModalVisibility={this.state.disclaimerModal}
                        hideDisclaimerModal={this.hideDisclaimerModal.bind(
                          this
                        )}
                        targetLink={this.props.selectedOpportunity.addressUrl}
                        physicalOpportunity={
                          this.props.selectedOpportunity.physicalOpportunity
                        }
                      />
                    ) : null}
                  </>
                )}
                {this.state.ShowFriendsCertificateModal ? (
                  <FamilyGuestFriendCertificateModal
                    show={this.state.ShowFriendsCertificateModal}
                    hideModal={this.hideFamilyFriendsCertificateModal.bind(
                      this
                    )}
                    friendsList={this.state.friendsList}
                    selectedOpportunity={this.props.selectedOpportunity}
                  />
                ) : null}

                {this.state.activeTab === 1 && (
                  <SingleOpportunityAnalytics
                    path={this.props.match.params.name}
                    tableData={this.props.selectedOpportunity}
                  />
                )}
                {this.state.activeTab === 2 && (
                  <SingleOpportunityGallery
                    notifyError={this.notifyError}
                    notifySuccess={this.notifySuccess}
                    isAdmin={this.props.role}
                    path={this.props.match.params.name}
                    selectedOpportunity={this.props.selectedOpportunity}
                    imageArray={this.state.imageArray}
                    images={this.state.images}
                    currentUserUploadsCount={this.state.currentUserUploadsCount}
                    fetchImages={() => this.fetchImages()}
                    loading={this.state.loading}
                  />
                )}
                {console.log(this.props.selectedOpportunity)}
                {this.state.activeTab === 3 && (
                  <SingleOpportunityParticipantsList
                    userRole={this.props.role}
                    totalGuestHours={this.state.totalGuestHours}
                    totalEmployeeHours={this.state.totalEmployeeHours}
                    notifyError={this.notifyError}
                    notifySuccess={this.notifySuccess}
                    allowHistoricalEventCreation={
                      this.state.allowHistoricalEventCreation
                    }
                    selectedOpportunity={this.props.selectedOpportunity}
                    enableCertificateGeneration={
                      organisationData.eePreferences
                        .enableCertificateGenerationForFamily
                    }
                    goBack={() => this.setState({ activeTab: 0 })}
                    enableParticipantsForTimeChange={
                      organisationData.eePreferences
                        .enableParticipantsForTimeChange
                    }
                    enableExternalParticipants={
                      organisationData.eePreferences.enableExternalParticipants
                    }
                    path={this.props.match.params.name}
                    tableData={this.state.tableData}
                    mergeData={this.state.mergeData}
                    friendsList={this.state.friendsListData}
                    loading={this.state.getfriendList}
                    fetchOpportunityDetails={() =>
                      this.fetchUpdateOpportunityDetails()
                    }
                  />
                )}

                {this.state.activeTab === 4 ? (
                  this.state.getFeedback ? (
                    <div className="data-loader" />
                  ) : (
                    // <i className="fa fa-spin fa-spinner fa-5x"></i>
                    <Col md={12} className="mt30">
                      <div className="gallery" style={{ marginTop: "20px" }}>
                        <div
                          className="gallery-header"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            className="ee-page-title"
                            style={{ marginTop: 10 }}
                          >
                            Volunteer Feedback
                          </span>
                        </div>
                        <span
                          style={{ cursor: "pointer",fontWeight:'600',fontSize:16 }}
                          title="Click to copy feedback link"
                        >
                          You can share event feedback with your colleagues by
                          generating a sharable link. [
                          <CopyToClipboard
                            text={
                              (window.location.href || "").replace(
                                "/admin/",
                                "/"
                              ) + "#feedback"
                            }
                            onCopy={() =>
                              this.notifySuccess("Feedback link copied!")
                            }
                          >
                            <i   style={{cursor:'pointer',color:'#84181F'}} class="fa fa-share-alt" aria-hidden="true">
                              {" "}
                              Click Here
                            </i>
                          </CopyToClipboard>
                          ]
                        </span>
                        <Col md={12} className="mt15 mb20">
                          <div className="ee-discussion-container mb10">
                            {this.state.feedbacks.length > 0
                              ? (this.state.attendeesData || []).map(
                                  function (feedback, index) {
                                    if (
                                      feedback.experience !== "" &&
                                      feedback.participated === true
                                    ) {
                                      return (
                                        <Col
                                          md={12}
                                          className="comments-sec"
                                          key={index}
                                        >
                                          <ul>
                                            <li className="single-comment">
                                              <Row>
                                                <Col md={12}>
                                                  <Row className="flex">
                                                    <Col
                                                      className="comment"
                                                      md={12}
                                                    >
                                                      <div className="just-flex">
                                                        <img
                                                          src={
                                                            feedback.profilePicture
                                                              ? generatePreSignedGetUrl(
                                                                  feedback.profilePicture
                                                                )
                                                              : "/img/user.png"
                                                          }
                                                          style={{
                                                            width: 50,
                                                            height: 50,
                                                            background:
                                                              "#e0e0e0",
                                                            padding: 3,
                                                          }}
                                                          alt=""
                                                          className="img-circle img-responsive mr10"
                                                        />
                                                        {/* <Row><Col md={12}></Col> </Row>  */}
                                                        <Row>
                                                          <Row>
                                                            <Col
                                                              md={12}
                                                              className="feedback-name"
                                                            >
                                                              {feedback.id ? (
                                                                <a
                                                                  href={
                                                                    "/profile/" +
                                                                    feedback.id
                                                                  }
                                                                  target="_blank"
                                                                >
                                                                  {
                                                                    feedback.fullName
                                                                  }
                                                                  {/* {cell} */}
                                                                </a>
                                                              ) : (
                                                                feedback.fullName
                                                              )}
                                                            </Col>
                                                          </Row>
                                                          <Row>
                                                            <Col
                                                              md={12}
                                                              className="feedback-time"
                                                            >
                                                              Duration:{" "}
                                                              {this.getTimeDiff(
                                                                feedback?.updatedStartTime ||
                                                                  feedback?.startTime ||
                                                                  this.state
                                                                    .selectedOpportunity
                                                                    ?.startTime,
                                                                feedback?.updatedEndTime ||
                                                                  feedback?.endTime ||
                                                                  this.state
                                                                    .selectedOpportunity
                                                                    ?.endTime
                                                              )}{" "}
                                                              | Experience
                                                              submitted on:{" "}
                                                              {feedback.hasOwnProperty(
                                                                "createdOn"
                                                              ) ? (
                                                                <span className="writer-date">
                                                                  {moment(
                                                                    feedback.createdOn
                                                                  ).format(
                                                                    "DD/MM/YYYY h:mm a"
                                                                  )}
                                                                </span>
                                                              ) : null}
                                                            </Col>
                                                          </Row>{" "}
                                                        </Row>
                                                      </div>
                                                      <div
                                                        className="feedback-rating"
                                                        style={{
                                                          marginLeft: "8%",
                                                        }}
                                                      >
                                                        <Col md={12}>
                                                          <Col md={3}>
                                                            <Row>
                                                              {" "}
                                                              <Col md={12}>
                                                                {/* <Rate
                                                        className="feedback-star-ratings"
                                                        // style={{fontSize:8,color:"#EF5A20"}}
                                                        defaultValue={
                                                          feedback.questionSelectedOption1
                                                        }
                                                        disabled
                                                      /> */}
                                                                <Rating
                                                                  allowHover={
                                                                    false
                                                                  }
                                                                  disableFillHover={
                                                                    false
                                                                  }
                                                                  size={12}
                                                                  fillColor={
                                                                    configs.THEME_COLOR
                                                                  }
                                                                  initialValue={
                                                                    feedback.questionSelectedOption1
                                                                  }
                                                                  readonly={
                                                                    true
                                                                  }
                                                                />
                                                              </Col>
                                                            </Row>
                                                            <Row>
                                                              <Col
                                                                md={12}
                                                                className="feedback-tag"
                                                              >
                                                                Impact
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          <Col md={4}>
                                                            <Row>
                                                              <Col md={12}>
                                                                {/* <Rate
                                                        // style={{fontSize:8 ,color:"#EF5A20"}}
                                                        className="feedback-star-ratings"
                                                        defaultValue={
                                                          feedback.questionSelectedOption2
                                                        }
                                                        disabled
                                                      /> */}
                                                                <Rating
                                                                  readonly={
                                                                    true
                                                                  }
                                                                  allowHover={
                                                                    false
                                                                  }
                                                                  disableFillHover={
                                                                    false
                                                                  }
                                                                  size={12}
                                                                  fillColor={
                                                                    configs.THEME_COLOR
                                                                  }
                                                                  initialValue={
                                                                    feedback.questionSelectedOption2
                                                                  }
                                                                />
                                                              </Col>
                                                            </Row>
                                                            <Row>
                                                              <Col
                                                                md={12}
                                                                className="feedback-tag"
                                                              >
                                                                Satisfaction
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          <Col md={5}>
                                                            <Row>
                                                              <Col md={12}>
                                                                {/* <Rate
                                                        // style={{fontSize:8 ,color:"#EF5A20"}}
                                                        className="feedback-star-ratings"
                                                        defaultValue={
                                                          feedback.questionSelectedOption3
                                                        }
                                                        disabled
                                                      /> */}
                                                                <Rating
                                                                  readonly={
                                                                    true
                                                                  }
                                                                  allowHover={
                                                                    false
                                                                  }
                                                                  disableFillHover={
                                                                    false
                                                                  }
                                                                  size={12}
                                                                  fillColor={
                                                                    configs.THEME_COLOR
                                                                  }
                                                                  initialValue={
                                                                    feedback.questionSelectedOption3
                                                                  }
                                                                  style={{
                                                                    marginRight: 20,
                                                                  }}
                                                                />
                                                              </Col>
                                                            </Row>
                                                            <Row>
                                                              <Col
                                                                md={12}
                                                                className="feedback-tag"
                                                              >
                                                                Likelihood of
                                                                participation
                                                                again
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        </Col>
                                                      </div>
                                                      <div
                                                        className="feedback-experience"
                                                        style={{
                                                          marginLeft: "15%",
                                                          marginTop: "8%",
                                                        }}
                                                      >
                                                        <p>Experience</p>
                                                        <div>
                                                          {feedback.experience}
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </li>
                                          </ul>
                                        </Col>
                                      );
                                    }
                                  }.bind(this)
                                )
                              : null}

                            {!this.state.feedbacks.length ? (
                              <div
                                className="no-feedbacks-container"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  className="no-feedbacks"
                                  style={{
                                    padding: "20px 0",
                                    textAlign: "center",
                                    marginBottom: "15px",
                                    background: "#f3f3f3",
                                  }}
                                >
                                  No feedbacks posted yet!
                                </span>
                                {this.props.selectedOpportunity
                                  .attendeeDeclaration &&
                                this.props.selectedOpportunity.attendeeDeclaration.hasOwnProperty(
                                  "participated"
                                ) &&
                                this.props.selectedOpportunity
                                  .attendeeDeclaration.participated &&
                                this.props.selectedOpportunity
                                  .attendeeDeclaration.experience === "" ? (
                                  <div
                                    className="fill-your-feedback-container"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span
                                      className="fill-your-feedback"
                                      onClick={this.showOnlyFeedbackModal.bind(
                                        this
                                      )}
                                    >
                                      Please fill your feedback!
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </Col>

                        <ToastContainer
                          position="bottom-center"
                          autoClose={4000}
                          hideProgressBar={true}
                          closeOnClick={false}
                          newestOnTop={false}
                          pauseOnHover={true}
                        />
                      </div>
                    </Col>
                  )
                ) : null}

                <AddParticipantsModal
                  showReserveModal={this.state.isAddParticipants}
                  openReserveModal={this.openAddParticipantsModal}
                  closeReserveModal={this.closeAddParticipantsModal}
                  opportunityId={this.state.tableData[0].id}
                  fetchOpportunityDetails={() =>
                    this.fetchUpdateOpportunityDetails()
                  }
                />
              </div>
            );
          }}
        </OrgDataContext.Consumer>
      );
    } else {
      return (
        <div className="ee-page-container single-opportunity-page">
          <div className="data-loader" />;
        </div>
      );
    }
  }
}

SingleOpportunity.propTypes = {
  selectedOpportunity: PropTypes.object,
  commentText: PropTypes.string,
  commentImage: PropTypes.array,
  toggleCommentPost: PropTypes.bool,
  subCommentText: PropTypes.string,
  commentId: PropTypes.string,
  participantsData: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    selectedOpportunity: state.EEDiscoverOpportunity.selectedOpportunity,
    commentText: state.EEDiscoverOpportunity.commentText,
    commentImage: state.EEDiscoverOpportunity.commentImage,
    toggleCommentPost: state.EEDiscoverOpportunity.toggleCommentPost,
    subCommentText: state.EEDiscoverOpportunity.subCommentText,
    commentId: state.EEDiscoverOpportunity.commentId,
    participantsData: state.EEDiscoverOpportunity.participantsData,
    role: state.RoleReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleOpportunity);
