import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from 'react-icons-kit';
import moment from 'moment';
import { airplay } from 'react-icons-kit/feather/airplay'
import { locationArrow } from 'react-icons-kit/fa/locationArrow';
import { calendar } from 'react-icons-kit/fa/calendar';
import { clockO } from 'react-icons-kit/fa/clockO';
import DisclaimerModal from "../DiscoverOpportunities/SingleOpportunity/DisclaimerModal"
import DeclareModal from './DeclareModal';
import Pagination from './Pagination';
import configs from '../../../../config/urlConfigs';
import { OrgDataContext } from '../../../../routes';
import { generatePreSignedGetUrl } from '../../../../s3PresignedUrls';

const readCookie = require('../../../../cookie.js').readCookie;

export default class VolunteerHistoryList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: "Pending",
			toggleValue: true,
			AttendanceValidation: false,
			showDeclareModal: false,
			selectedOpportunityId: null,
			disclaimerModal:false,
     		targetLink:null,
			physicalOpportunity:null,
			rowsPerPage: 10,
			// rowsPerPage: 25,
      		currentPage: 1,
			selectedOpportunity:null,
		}
	}
	showDisclaimerModal(url,physicalOpportunity){
		this.setState({disclaimerModal :true});
		this.setState({targetLink :url});
		this.setState({physicalOpportunity:physicalOpportunity})
	  }
	  hideDisclaimerModal(){
		this.setState({disclaimerModal:false})
	  }
	// Change page
	paginate = (pageNumber) => {
		this.setState({
		  currentPage: pageNumber,
		});
	  };
	
	  pageSize = (Number) => {
		this.setState({
		  rowsPerPage: Number,
		});
	  };






	hideDeclareModal() {
		this.setState({ showDeclareModal: false, selectedOpportunityId: null });
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity-history', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.updateHistory(data.data);
		}.bind(this));
	}

	showDeclareModal(opportunityId,selectedOpportunityData) {
		this.setState({ showDeclareModal: true, selectedOpportunityId: opportunityId ,selectedOpportunity :selectedOpportunityData});
	}

	getTimeDiff(startTime, endTime) {
		var time = '';
		var hours = parseInt(moment.duration(moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'))).asHours());
		if (hours > 1) time = hours + ' ' + 'hours ';
		else if (hours === 1) time = hours + ' ' + 'hour ';
		var mins = parseInt(moment.duration(moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'))).asMinutes() % 60);
		if (mins > 1) time += mins + ' ' + 'minutes ';
		else if (mins === 1) time += mins + ' ' + 'minutes ';
		if (time === '') return false;
		return time;
	}

	openLocationInMap(url) {
		window.open(url, '_blank');
	}
	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/get-company-preference', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (Object.keys(data.data).length) {
				this.setState({ AttendanceValidation: data.data.enableEmployeeAttendance, });
			}
		}.bind(this));
	}

	gotoPage(title) {
		// console.log("title",title);
		// /opportunity/${title}#gallery
		window.location.href = `/opportunity/${title}#gallery`;
	  }
	render() {
		const indexOfLastRow = this.state.currentPage * this.state.rowsPerPage;
		const indexOfFirstRow = indexOfLastRow - this.state.rowsPerPage;
		const currentRows = this.props.history.slice(
		  indexOfFirstRow,
		  indexOfLastRow
		);
		if (this.props.history.length) {
			return (
				<OrgDataContext.Consumer> 
          {(organisationData) => {
			// console.log("organisationData===>>>> ",organisationData);
            return <Row className="volunteer-histories-container">{
					currentRows.map(function (hist, i) {
                     let attendees = hist.attendees.filter((data) => data.id === JSON.parse(readCookie('userData')).id );
						return (
							<div>
					          <Col md={12} className="ee-single-opportunity" key={i}>
									<Row> <Col md={3} className="ee-single-opportunity-img-container">
										<a href={"/my-volunteer-history/" + hist.customUrl}>
											{hist.image ? (
												<div className="ee-single-opportunity-img" style={{ backgroundImage: 'url(' + generatePreSignedGetUrl(hist.image) + ')', backgroundSize: 'contain' }}>
													<div className="volunteer current-state">
														<span className={'current-state-outer ' + (hist.isCompleted ? 'completed' : 'live')}>
															<span className="outer-arrow"> </span>
															<span className="current-state-text">{hist.status === 'Live' ? 'Upcoming' : hist.status}</span>
														</span>
													</div>
												</div>
											) : (
												<div className="ee-single-opportunity-img" style={{ backgroundImage: 'url(https://assets.letsendorse.com/sahaj/project-placeholder.jpg)', backgroundSize: 'contain' }}>
													<div className="volunteer current-state">
														<span className={'current-state-outer ' + (hist.isCompleted ? 'completed' : 'live')}>
															<span className="outer-arrow"> </span>
															<span className="current-state-text">{hist.status === 'Live' ? 'Upcoming' : hist.status}</span>
														</span>
													</div>
												</div>
											)}
										</a>
									</Col>
										<Col md={9} className="ee-single-opportunity-details-container">
											<div className="ee-title-section">
												<a href={"/my-volunteer-history/" + hist.customUrl} className="mb5 ee-single-opportunity-title my-opportunities">{hist.title}</a>
											</div>
											<div className="ee-location-date-section my-opportunities">
												{!hist.physicalOpportunity ? (<span className="ee-location">
													<Icon className="pad-right-5" icon={airplay} />Virtual </span>) :
													 (<span className="ee-location" style={{ cursor: "pointer" }} 
													onClick={this.showDisclaimerModal.bind(this,hist.addressUrl,hist.physicalOpportunity)}>
														<Icon className="pad-right-5" icon={locationArrow} />
														<span>{hist.address1}</span>
													</span>)}
											</div>
											<div>
												<span className="ee-calendar">
													<Icon className="pad-right-5" icon={calendar} />
													<span className="pad-right-5">{moment(hist.date, 'DD/MM/YYYY').format('ddd, DD MMM YYYY')}</span>
												</span>
												{attendees && attendees.length && attendees[0].updatedParticipatedAttendence === false ? null : (hist.hasOwnProperty('attendeeDeclaration') 
												&& this.getTimeDiff(hist.attendeeDeclaration.startTime, hist.attendeeDeclaration.endTime) ? (
													<span className="ee-calendar">
														<Icon className="pad-right-5" icon={clockO} />
														<span>{this.getTimeDiff(hist.attendeeDeclaration.startTime, hist.attendeeDeclaration.endTime) + '(' + hist.attendeeDeclaration.startTime + ' - ' + hist.attendeeDeclaration.endTime + ')'}</span>
													</span>
												) : (null))}
											</div>
											<div
                            className="ql-editor card-text recomended-opportunity-card-des"
                            style={{
                                                
                                  color: "#707070",
                                  display: '-webkit-box',
                                  WebkitLineClamp: '3',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxHeight: '60px',
                                  padding: '0px'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: hist.description,
                            }}
                          />
											{/* <p className='project-description recomended-opportunity-card-des' style={{ WebkitLineClamp: '2' }}>{hist.description}</p> */}
											<div className="ee-related-project-section">
												{hist.status === 'Completed' && !hist.hasOwnProperty('attendeeDeclaration') ? (
													<a onClick={this.showDeclareModal.bind(this, hist.id,hist)} className="btn btn-primary btn-declare-attendance" style={{ marginRight: 10 }}>Declare Attendance</a>
												) : (null)}
												{(() => {
														if(	// hist.hasOwnProperty('attendeeDeclaration')
															attendees && attendees.length && attendees[0].updatedParticipatedAttendence === "" 
														&& attendees[0].participated){
															return (
																// <p style={{display:'flex',border:'black'}}>
																<p className="declared-text already-declared-attendance" >You have declared your attendance</p>
																//{/* <p className="declared-text already-declared-attendance" >You have declared your attendance</p> */}
																// </p>
															);
														}
														else if(	// hist.hasOwnProperty('attendeeDeclaration')
														 attendees && attendees.length && !attendees[0].hasOwnProperty('updatedParticipatedAttendence')
														&& attendees[0].participated){
															return (
																<p className="declared-text already-declared-attendance" >You have declared your attendance</p>
															);
														}
													else if ( // hist.hasOwnProperty('attendeeDeclaration') && 
															attendees && attendees.length && attendees[0].participated 
															&&  attendees[0].updatedParticipatedAttendence === true) {
														return (
															<p className="declared-text already-declared-attendance" >You have declared your attendance</p>
														);
													}	
														else if (attendees && attendees.length && attendees[0].updatedParticipatedAttendence === false) {
														return (
																<p className="declared-text already-declared-attendance" >Your attendance has been declined.</p>
																);
														}
													else if ( //!hist.hasOwnProperty('attendeeDeclaration') && 
															hist.status !== 'Completed' && attendees && attendees.length && attendees[0].participated === '') {
														return (
															<p className="declared-text already-declared-attendance" >Applied</p>
														)
													}
														// why are we using this don't know 
													// else if (hist.isUserAttendee ) {
													// 	return (
													// 		<Button className="btn-sq btn-cancel" disabled>Applied</Button>
													// 	);
													// } 
													// else {
													// 	return (
													// 		<a className="btn ee-single-opportunity-more" data-opp-id={hist.id} onClick={this.openReserveModal}>Apply</a>
													// 	);
													// }
												})()}
												{(() => {
													if (organisationData.eePreferences.galleryLimit > 0 && attendees && attendees.length && attendees[0].updatedParticipatedAttendence === "" 
														&& attendees[0].participated){
															return ( <p className="btn-contribute-photo btn-primary btn-declare-attendance" style={{textTransform: 'none'}} onClick={this.gotoPage.bind(this,hist.customUrl)} >Upload event photos</p> )}
													else if (organisationData.eePreferences.galleryLimit > 0 && attendees && attendees.length && !attendees[0].hasOwnProperty('updatedParticipatedAttendence')
														&& attendees[0].participated){
															return (
																<p className="btn-contribute-photo btn-primary btn-declare-attendance" style={{textTransform: 'none'}} onClick={this.gotoPage.bind(this,hist.customUrl)} >Upload event photos</p>
															);
														}
													else if (organisationData.eePreferences.galleryLimit > 0 && attendees && attendees.length && attendees[0].participated 
															&&  attendees[0].updatedParticipatedAttendence === true ) {
														return (
															<p className="btn-contribute-photo btn-primary btn-declare-attendance" style={{textTransform: 'none'}} onClick={this.gotoPage.bind(this,hist.customUrl)} > Upload event photos</p>
														);
													}	
												})()}
											</div>
										</Col>
									</Row>
									<div className='le-divider' />
								</Col>
							</div>
						)
					}.bind(this))}
					{this.state.selectedOpportunityId !== null && this.state.showDeclareModal ? (
					
						<DeclareModal
							allowfeedback={true}
							volunteerOpportunity={this.state.selectedOpportunity}
							opportunityId={this.state.selectedOpportunityId}
							showDeclareModalVisibility={this.state.showDeclareModal}
							hideDeclareModal={this.hideDeclareModal.bind(this)}
							participantsData={this.props}
							selectedOpportunityData={this.state.selectedOpportunity}
						/>
					) : (null)}

{ this.state.disclaimerModal ? (
              <DisclaimerModal 
              disclaimerModalVisibility = {this.state.disclaimerModal}
              hideDisclaimerModal ={this.hideDisclaimerModal.bind(this)}
              targetLink ={this.state.targetLink}
 				physicalOpportunity ={this.state.physicalOpportunity}/>) :null


            }
			<Pagination rowsPerPage={this.state.rowsPerPage}
		totalRows={this.props.history.length}
		paginate={this.paginate}
		pageSize={this.pageSize}
		url="/my-volunteer-history"/>
</Row>
		}}</OrgDataContext.Consumer>
			);
		} else {
			return (
				<Row>
					<Col md={12} className="text-center">
						<p className="no-data-text">You have not volunteered yet</p>
						<button onClick={() => { window.location.pathname = '/discover-opportunities' }} className="btn-le btn-sq btn-pad-5-20 m10">volunteer now</button>
					</Col>
				</Row>
			);
		}
		
	}
}