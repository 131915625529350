import React, { Component, useContext } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton, } from 'react-bootstrap-table';
import moment from 'moment';
import { CSVLink, CSVDownload } from 'react-csv';


import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import configs from '../../../../config/urlConfigs';
import { OrgDataContext } from '../../../../routes';
import AddEditMemberModal from './AddEditMemberModal.js';
import GetParticipantModal from '../5-Settings/GetParticipantModal.js';

let organisationDataContext = null;
const readCookie = require('../../../../cookie.js').readCookie;

export default class AllEmployeesTable extends Component {
    constructor(props) {
        super();
        this.state = {
            employeesData: props.employeesData,
            subdomain: configs.SUBDOMAIN || "",
            page: 1,
            sizePerPage: 100,
            totalSize: this.props?.memberCount||0,
			full_name: '',
			emp_id: '',
			gender: '',
			department: '',
			phone: '',
			email: '',
			designation: '',
			status: '',
			role: '',
			error: [],
			editMember: false,
            editMemberId: '',
            offices: [],
			cities: [],
			departments: [],
			officeTitle: '',
			transferMember: null,
			totalEmployeeCount: 0,
			transferMemberModalVisibility: false,
			accessLocations: [],
			allOffices: [],
			allCities: [],
      allLoadedData: props.employeesData || [],
      searchResult:[],
      SearchingUser:false,
      ShowSearchModal:false,
        };

    }

    componentDidMount() {
      // Initial data load
      this.getAllEmployeesList();
      this.getDepartmentsList();
      // ... rest of your componentDidMount
  }

    componentDidUpdate(prevProps) {
      // Update totalSize if memberCount prop changes
      if (prevProps.memberCount !== this.props.memberCount) {
          this.setState({ totalSize: this.props.memberCount });
      }
  }

    showEditMemberModal(row) {
		this.setState({
			editMember: true,
			editMemberId: row._id,
			addMemberModalVisibility: true,
			full_name: row.fullName,
			emp_id: row.employeeId,
			gender: row.gender,
			department: row.department,
			phone: row.mobileNo,
			email: row.email,
			designation: row.designationAtOrganisation,
			status: row.status,
			role: row.role,
			accessLocations: row.accessLocations || [],
			allOffices: this.state.offices.map(office => ({ label: office.name, value: office.id, selected: (row.accessLocations || []).includes(office.id) })),
			allCities: this.state.cities.map(city => ({ label: city.name, value: city.id, selected: (row.accessLocations || []).includes(city.id) })),
			error: []
		});
	}

    updateChange(name, required, e) {
		var error = this.state.error;
		var index = error.indexOf(name);
		if (required && e.target  && e.target.value !== '' && index > -1) error.splice(index, 1);

		// if (name === 'accessLocations') {
		// 	const officeArray = e.parent().val();
		// 	const allOffices = this.state.offices.map(office => ({ label: office.name, value: office.id, selected: officeArray.includes(office.id) }));
		// 	if (required && officeArray.length && index > -1) error.splice(index, 1);
		// 	this.setState({ [name]: officeArray, allOffices, error });
		// }
		if (name === 'accessLocations') {
			const citiesArray = e.parent().val();
			const allCities = this.state.cities.map(city => ({ label: city.name, value: city.id, selected: citiesArray.includes(city.id) }));
			if (required && citiesArray.length && index > -1) error.splice(index, 1);
			this.setState({ [name]: citiesArray, allCities, error });
		}
		else {
			this.setState({ [name]: e.target.value, error });
		}
	}

	hideAddMemberModal() {
		const allOffices = this.state.offices.map(office => ({ label: office.name, value: office.id }));
		const allCities = this.state.cities.map(city => ({ label: city.name, value: city.id }));
		this.setState({
			addMemberModalVisibility: false,
			full_name: '',
			emp_id: '',
			gender: '',
			department: '',
			phone: '',
			email: '',
			designation: '',
			status: '',
			role: '',
			error: [],
			editMember: false,
			editMemberId: '',
			accessLocations: [],
			allOffices,
			allCities,
		});
		this.getAllEmployeesList()
	}

    addMember() {
		var full_name = this.state.full_name;
		var emp_id = this.state.emp_id;
		var gender = this.state.gender;
		var department = this.state.department;
		var phone = this.state.phone;
		var email = this.state.email;
		var designation = this.state.designation;
		var status = this.state.status;
		var role = this.state.role;
		var accessLocations = this.state.accessLocations;
		if (!((full_name === '' || email === '' || role === '') || (role ==='office-admin' &&  accessLocations.length ===0))) {
			var data = {};
			data['fullName'] = full_name;
			data['employeeId'] = emp_id;
			data['gender'] = gender;
			data['department'] = department;
			data['mobileNo'] = phone;
			data['designation'] = designation;
			data['status'] = status;
			data['role'] = role;
			data['accessLocations'] = accessLocations;
			if (!this.state.editMember) data['email'] = email;
			if (!this.state.editMember) data['listId'] = this.state.listId;

			if (!this.state.editMember) {
				fetch(configs.BACKEND_API_URL + '/add-employees', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then((response) => response.json())
					.then(function (data) {
						swal(
							'Successful!',
							'You have added the employee.',
							'success'
						);
						this.hideAddMemberModal();
					}.bind(this));
			} else {
				fetch(configs.BACKEND_API_URL + '/employee/' + this.state.editMemberId, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then((response) => response.json())
					.then(function (data) {
						swal(
							'Successful!',
							'You have updated the employee.',
							'success'
						);
						this.hideAddMemberModal();
					}.bind(this));
			}
		} else {
			var error = [];
			if (full_name === '') error.push('full_name');
			if (email === '') error.push('email');
			if (role === '') error.push('role');
			if (role === 'office-admin' && this.state.accessLocations.length === 0) error.push('accessLocations');
			this.setState({ error });
		}
	}

    edit(cell, row) {
		return (
			<button className="btn btn-default member-edit-btn" type="button" onClick={this.showEditMemberModal.bind(this, row)}>Edit</button>
		)
	}

    getDepartmentsList() {
        fetch(configs.BACKEND_API_URL + '/list', {
            method: "GET",
            headers: {
                'Auth': JSON.parse(readCookie('access_token')).access_token
            },
        }).then(function (response) {
            return response.json();
        }).then(function (data) {
            // this.setState({ lists: data.lists, memberCount: data.memberCount || 0 });
            $(() => {
                // use UI arrows in page content to control scrolling
                this.scrolling = false;
                // var that = this;

                $('#drag-table-left').mouseover((evt) => {
                    this.scrolling = true;
                    this.startScrolling($('table.table'), 15, evt.target.id, 'right');
                });

                $('#drag-table-right').mouseover((evt) => {
                    this.scrolling = true;
                    this.startScrolling($('table.table'), 15, evt.target.id, 'left');
                });

                $('#drag-table-left').mouseout(() => { this.scrolling = false; });
                $('#drag-table-right').mouseout(() => { this.scrolling = false; });
            });
        }.bind(this));
    }

    startScrolling = (obj, spd, btn, direction) => {
        console.log("🚀 ~ file: AllEmployeesTable.js:314 ~ AllEmployeesTable ~ obj, spd, btn, direction:", obj, spd, btn, direction)

        var travelX = 0, stop = false;
        if ($(obj).css('transform') !== 'none') travelX = parseInt($(obj).css('transform').split(',')[4].trim());

        if (direction === 'left') {
            let max = $('.react-bs-table').width() - $(obj).width();
            // let max = $('.react-bootstrap-table').width() - $(obj).width();
            console.log("travelX - spd) < max", travelX - spd, max);
            if ((travelX - spd) < max) {
                travelX = max;
                stop = true;
                $(obj).css('transform', 'translateX(' + travelX + 'px)');
            } else travelX -= spd;
        } else if (direction === 'right') {
            if ((travelX + spd) > 0) {
                travelX = 0;
                stop = true;
                $(obj).css('transform', 'translateX(' + travelX + 'px)');
            } else travelX += spd;
        }

        if (!this.scrolling || stop) {
            obj.stop();
        } else {
            $(obj).css('transform', 'translateX(' + travelX + 'px)');
            // recursively call startScrolling while mouse is hovered
            setTimeout(() => {
                this.startScrolling(obj, spd, btn, direction);
            }, spd);
        }
    }
    getAllEmployeesList() {
      const { page, sizePerPage, allLoadedData } = this.state;
      fetch(`${configs.BACKEND_API_URL}/all-employees-details?page=${page > 1 ? Math.ceil(page/5)+1 : 1}&limit=500`, {
          method: "GET",
          headers: {
              'Auth': JSON.parse(readCookie('access_token')).access_token
          },
      }).then(function (response) {
          return response.json();
      }).then(function (data) {
          if (data && data.allEmployees) {
            const newData = page > 1 
            ? [...allLoadedData, ...data.allEmployees]
            : data.allEmployees;
            this.setState({ 
              employeesData: newData,
              allLoadedData: newData,
          });
          }
      }.bind(this))
      .catch(error => {
          console.error("Error fetching employees:", error);
      });
  }

  handlePageChange = (page, sizePerPage) => {
      console.log("Page changed to:", page, "Size per page:", sizePerPage);
      const totalPages = Math.ceil(this.state.allLoadedData.length / sizePerPage);
      const shouldLoadMore = page >= totalPages - 2;
      console.log(shouldLoadMore,'shouldLoadMore',totalPages,page)
      this.setState({ 
          page, 
          sizePerPage 
      }, () => {
          if (shouldLoadMore) {
              // Load more data when approaching the end
              this.getAllEmployeesList();
          }
      });
  }

    // employeeId(cell, row) {
    //     if (cell === '') {
    //         return (<span></span>)
    //     } else {
    //         return (
    //             <a href={'/team-management/' + this.state.listId + '/' + row.id} title={cell}>{cell}</a>
    //         )
    //     }
    // }

    // fullName(cell, row) {
    //     return (
    //         <a href={'/team-management/' + this.state.listId + '/' + row.id} title={cell}>{cell}</a>
    //     )
    // }

    // email(cell, row) {
    //     return (
    //         <span title={cell}>{cell}</span>
    //     )
    // }

    // edit(cell, row) {
    //     return (
    //         <button className="btn btn-default member-edit-btn" type="button" onClick={this.showEditMemberModal.bind(this, row)}>Edit</button>
    //     )
    // }

    // transfer(cell, row) {
    //     return (
    //         <button className={row.status === 'Terminated' ? "btn btn-default member-transfer-btn terminated" : "btn btn-default member-transfer-btn"} type="button" onClick={this.showTransferMemberModal.bind(this, row)} disabled={row.status === 'Terminated' ? true : false}>Transfer</button>
    //     )
    // }

    SearchUserResult(user){
      console.log(user);
      if(user){
        this.setState({
          searchResult:[user],
          SearchingUser:true,
          ShowSearchModal:false
        })
      }else{
        this.setState({
          searchResult:[],
          SearchingUser:false,
          ShowSearchModal:false
        })
      }
      
    }


    createCustomButtonGroup(props, onClick) {

        return <OrgDataContext.Consumer>
            {(organisationDataContextInFunc) => (organisationDataContextInFunc.ssoEnabled ? (null) : <div>
                <ButtonGroup className='manage-team-buttons' sizeClass='btn-group-md'>
                    <button type='button' onClick={this.showAddMemberModal.bind(this)} className='btn btn-le'>
                        <span>
                            <i className="fa fa-plus"></i>
                            Add Employee
                        </span>
                    </button>
                    <button type='button' disabled={this.state.deleteButtonDisabled} onClick={this.getSelectedRowKeys.bind(this)} className='btn btn-le'>
                        <span>
                            <i className="fa fa-minus"></i>
                            Delete Employee
                        </span>
                    </button>
                    <button type='button' onClick={this.showImportMemberModal.bind(this)} className='btn btn-le'>
                        <span>
                            <i className="fa fa-plus"></i>
                            Import Employees
                        </span>
                    </button>
                </ButtonGroup>
            </div>)
            }
        </OrgDataContext.Consumer>
    }

    createCustomExportCSVButtonForAllEmployees(employeesData) {

        const headers = [
            {label:"Hexa ID",key:"_id"},
            { label: "Employee Id", key: "employeeId" },
            { label: "Name", key: "fullName" },
            {label:"Department",key: "department"},
            { label: "Email", key: "email" },
            { label: "Mobile", key: "mobileNo" },
            { label: "Alternative Mobile Number", key: "alternateMobileNumber" },
            { label: "Digital Volunteering ID", key: "digitalVolunteeringId" },
            {label:"Designation",key:"designationAtOrganisation" },
            {label :"Status",key:"status" },
            {label:"Created at",key:"created_at"},
            {label: "Date of joining",key:"doj"},
            {label: "Gender",key:"gender"},
            {label: "Business Unit",key:"business_unit"},
            {label: "OU Name",key:"ou_Name"},

            {label: "Reporting Manager Employee Id",key:"reporting_manager_employeeId"},
            {label: "Reporting Manager Name",key:"reporting_manager_name"},
            {label: "Reporting Manager Designation",key:"reporting_manager_designation"},
            {label: "Reporting Manager Email",key:"reporting_manager_email"},
            {label: "Branch code",key:"branch_code"},
            {label:"Branch address",key:"branch_address"},
            {label:"Location",key:"location"},
            {label:"City",key:"city"},
            {label:"Region",key:"region"},
            {label:"Zone",key:"zone"},
            {label:"Source",key:"source"},
            {label:"Last updated on",key:"last_updated_on"},
            {label:"Consent last updated",key:"consentLastUpdated"},

        ];
        return (
                <CSVLink
                    headers={headers}
                    data={this.state.employeesData}
                    onClick={() => {
                    }}
                    filename={
                        'All employees - ' + String(moment().unix()) + '.csv'
                    }
                    target="_blank">
                    <ExportCSVButton
                        style={{
                            marginTop: "5%",
                            background: 'var(--themeColor)',
                            borderColor: 'var(--themeColor)',
                            boxShadow: "1px 1px 1px #515356"
                        }}
                        // className="btn btn-endorse"
                        btnText='Export to CSV' />
                </CSVLink>
            
        );
    }
    createdAtFormatter(cell) {
        return cell ? <>{ moment(cell).format("DD/MM/yyyy hh:mm")}</>: "";    
    }

    render() {
        // const selectRowProp = {
        //     mode: 'checkbox',
        //     onSelect: this.onRowSelect.bind(this),
        //     onSelectAll: this.onRowSelectAll.bind(this)
        // };

        // const options = {
        //     exportCSVBtn: ()=> <ExportCSVButton
        //         style={{
        //             marginTop: "5%",
        //             background: 'var(--themeColor)',
        //             borderColor: 'var(--themeColor)',
        //             boxShadow: "1px 1px 1px #515356"
        //         }}
        //         // className="btn btn-endorse"
        //         btnText='Export to CSV' />,
        //     // btnGroup: this.createCustomButtonGroup.bind(this),
        //     sizePerPage: 100,
        //     sizePerPageList:  [
        //         { text: '100 per page', value: 100 },
        //         { text: '200 per page', value: 200 },
        //         { text: '500 per page', value: 500 },
        //         { text: '1000 per page', value: 1000 }
        //     ]
        // }

        // const options = {
        //     exportCSVBtn :() =>  this.createCustomExportCSVButtonForAllEmployees(),
        //     sizePerPage: 100,
        //     // sizePerPageList:  [
        //     //     { text: '100 per page', value: 1000 },
        //     //     { text: '200 per page', value: 2000 },
        //     //     { text: '500 per page', value: 500 },
        //     //     { text: '1000 per page', value: 1000 }
        //     // ],
        //     paginationSize: 10,  // the pagination bar size.
        //     pageStartIndex: 1,

        // }
        const options = {
          // exportCSVBtn: () => this.createCustomExportCSVButtonForAllEmployees(),
          sizePerPage: this.state.sizePerPage,
          page: this.state.page,
          onPageChange: this.handlePageChange,
          paginationSize: 10,
          pageStartIndex: 1,
          
          // totalSize: this.props.memberCount || 0 // Use total count from props
      };

       
        return (
          <div>
            <Row
              className="single-team-member-table-container"
              id="all-members-container"
            >
              {/* {      console.log(this.props,this.state.totalSize,options)              } */}
              {/* <div className="back-to-all-teams">
                    {this.state.subdomain === "indusind" ? <>
                        <i className="fa fa-chevron-left" onClick={this.goBack.bind(this)}></i>
                        <span className="back-to-all-teams-text" onClick={this.goBack.bind(this)}>Back To All Department</span>
                    </> : <>
                        <i className="fa fa-chevron-left" onClick={this.goBack.bind(this)}></i>
                        <span className="back-to-all-teams-text" onClick={this.goBack.bind(this)}>Back To All Offices</span>
                    </>
                    }
                </div> */}
              {/* {this.state.subdomain === 'indusind' ? (<div className="team-title ml10">{this.state.officeTitle}</div>)
                    : (<div className="team-title ml10">{this.state.officeTitle}{this.state.officeTitle.toLowerCase().match('office') === null ? ' Office' : ''}</div>)} */}
              {this.state.employeesData ? (
                <span id="drag-table-left">{"<"}</span>
              ) : null}
              {this.state.employeesData ? (
                <span id="drag-table-right">{">"}</span>
              ) : null}
              <div style={{
                display:'flex',
                justifyContent:'end',
                alignItems:'center',
                }}>
                  {this.state.SearchingUser ? <div style={{
                display:'flex',
                alignItems:'center',
                margin:'20px 0px',
                cursor:'pointer',
                color:'var(--themeColor)',
                marginRight:'20px'
                }}
                onClick={()=>{
                  this.setState({
                    SearchingUser:false,
                    searchResult:[],
                  })
                }}
                >
                <p style={{
                  border:'1px solid var(--themeColor)',
                  padding:'3px 15px',
                  borderRadius:'5px',
                  color:'var(--themeColor)',
                  }}>Reset</p>
              </div> : null}
              <div style={{
                display:'flex',
                alignItems:'center',
                margin:'20px 0px',
                cursor:'pointer',
                color:'var(--themeColor)'
                }}
                onClick={()=>{
                  this.setState({
                    ShowSearchModal:true
                  })
                }}
                >
                <p style={{
                  border:'1px solid rgb(204, 204, 204)',
                  padding:'3px 15px',
                  borderRadius:'5px',
                  color:'rgb(204, 204, 204)',
                  }}>Search User By Ecn</p>
              </div>
              </div>
              <BootstrapTable
                ref="table"
                data={this.state.SearchingUser ? this.state.searchResult : this.state.employeesData}
                className="ml10"
                hover
                options={options}
                pagination
                // search
                // exportCSV
              >
                <TableHeaderColumn isKey dataField="_id" width="200">
                  Hexa ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="100"
                  dataField="employeeId"
                  searchable
                  export={true}
                >
                  Employee ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="160"
                  dataField="fullName"
                  searchable
                  export={true}
                >
                  Full Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="department"
                  searchable
                  export={true}
                >
                  Department
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200"
                  dataField="email"
                  searchable
                  export={true}
                >
                  Email
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="100"
                  dataField="mobileNo"
                  searchable
                  export={true}
                >
                  Mobile
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="100"
                  dataField="whatsapp"
                  searchable
                  export={true}
                >
                  Whatsapp
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="100"
                  dataField="alternateMobileNumber"
                  searchable
                  export={true}
                >
                  Alternate Mobile No.
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="100"
                  dataField="digitalVolunteeringId"
                  searchable
                  export={true}
                >
                  IBL Volunteer Number
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="designationAtOrganisation"
                  searchable
                  export={true}
                >
                  Designation
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="80"
                  dataField="status"
                  searchable
                  export={true}
                >
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="130"
                  dataField="created_at"
                  searchable
                  export={true}
                  dataFormat={this.createdAtFormatter}
                >
                  Registered at
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="100"
                  dataField="doj"
                  searchable
                  export={true}
                >
                  DOJ
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="80"
                  dataField="gender"
                  searchable
                  export={true}
                >
                  Gender
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200"
                  dataField="business_unit"
                  searchable
                  export={true}
                >
                  Business Unit
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200"
                  dataField="ou_Name"
                  searchable
                  export={true}
                >
                  OU Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="100"
                  dataField="reporting_manager_employeeId"
                  searchable
                  export={true}
                >
                  Reporting manager Employee ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200"
                  dataField="reporting_manager_name"
                  searchable
                  export={true}
                >
                  Reporting Manager Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200"
                  dataField="reporting_manager_designation"
                  searchable
                  export={true}
                >
                  Reporting Manager Designation
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200"
                  dataField="reporting_manager_email"
                  searchable
                  export={true}
                >
                  Reporting Manager Email
                </TableHeaderColumn>
                {/* <TableHeaderColumn width='120' dataField='weekly_off' searchable export={true}>Weekly Off</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='100' dataField='country' searchable export={true}>Country</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='80' dataField='employee_status' searchable export={true}>Employee Status</TableHeaderColumn> */}
                <TableHeaderColumn
                  width="100"
                  dataField="branch_code"
                  searchable
                  export={true}
                >
                  Branch Code
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="150"
                  dataField="branch_address"
                  searchable
                  export={true}
                >
                  Branch Address
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="150"
                  dataField="location"
                  searchable
                  export={true}
                >
                  Location
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="150"
                  dataField="city"
                  searchable
                  export={true}
                >
                  City
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="150"
                  dataField="region"
                  searchable
                  export={true}
                >
                  Region
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="150"
                  dataField="zone"
                  searchable
                  export={true}
                >
                  Zone
                </TableHeaderColumn>
                {/* <TableHeaderColumn width='80' dataField='shift_start' searchable export={true}>Shift Start</TableHeaderColumn>
                    <TableHeaderColumn width='80' dataField='geolocation' searchable export={true}>Geolocation</TableHeaderColumn>
                    <TableHeaderColumn width='80' dataField='georadius' searchable export={true}>Georadius</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='80' dataField='grade' searchable export={true}>Grade</TableHeaderColumn> */}
                <TableHeaderColumn
                  width="120"
                  dataField="source"
                  searchable
                  export={true}
                >
                  Source
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="120"
                  dataField="last_updated_on"
                  dataFormat={this.createdAtFormatter}
                  searchable
                  export={true}
                >
                  Last updated on
                </TableHeaderColumn>
                {/* <TableHeaderColumn width='200' dataField='company' searchable export={true}>Company</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='120' dataField='type' searchable export={true}>Type</TableHeaderColumn> */}
                <TableHeaderColumn
                  width="200"
                  dataField="consentLastUpdated"
                  dataFormat={this.createdAtFormatter}
                  searchable
                  export={true}
                >
                  Consent last updated at
                </TableHeaderColumn>
                <TableHeaderColumn width='70' dataField='status' export={false} headerAlign='center' columnClassName='member-edit-btn-container' dataFormat={this.edit.bind(this)}>Edit</TableHeaderColumn>

              </BootstrapTable>
            </Row>

            {this.state.addMemberModalVisibility ? (
              <AddEditMemberModal
                addMemberModalVisibility={this.state.addMemberModalVisibility}
                hideAddMemberModal={this.hideAddMemberModal.bind(this)}
                role={this.state.role}
                updateChange={this.updateChange.bind(this)}
                error={this.state.error}
                addMember={this.addMember.bind(this)}
                emp_id={this.state.emp_id}
                full_name={this.state.full_name}
                email={this.state.email}
                gender={this.state.gender}
                department={this.state.department}
                phone={this.state.phone}
                designation={this.state.designation}
                offices={this.state.allOffices}
                cities={this.state.allCities}
                accessLocations={this.state.accessLocations}
                status={this.state.status}
                editMember={this.state.editMember}
              />
            ) : null}

          {this.state.ShowSearchModal ? (
					<GetParticipantModal
          SearchButtonText="View Selected Employee Details"
					Action={this.SearchUserResult.bind(this)}
					HideGetParicipantModal={q=>{this.setState({ShowSearchModal:false})}}
					/>
				) : null}
          </div>
        );

    }
}