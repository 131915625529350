import React, { Component } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import Icon from 'react-icons-kit';
import { locationArrow } from 'react-icons-kit/fa/locationArrow';
import { calendar } from 'react-icons-kit/fa/calendar';
import swal from 'sweetalert2';
import { airplay } from 'react-icons-kit/feather/airplay'
import moment from 'moment';
import MakeLive from './MakeLive';
import  Pagination from "./Pagination";
import configs from '../../../../../config/urlConfigs';
import { generatePreSignedGetUrl } from '../../../../../s3PresignedUrls';

const readCookie = require('../../../../../cookie.js').readCookie;

export default class VolunteerOpportunitiesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showReserveModal: false,
			currentOpportunity: null,
			rowsPerPage: 10,
			// rowsPerPage: 25,
			currentPage: props.pageNumber || 1,
		};

		this.closeReserveModal = this.closeReserveModal.bind(this);
		this.openReserveModal = this.openReserveModal.bind(this);
	}

	closeReserveModal() {
		this.setState({ showReserveModal: false, currentOpportunity: null });
	}

	openReserveModal(e, opportunity) {
		let volunteerOpportunityId = e.currentTarget.getAttribute('data-id');
		if (!opportunity.config) {
			opportunity.config = {
				isExclusiveOpportunity: false,
				isTransportRequired: false,
				isTshirtProvided: false,
				date: null,
				volunteersNeeded: '',
				hoursNeeded: '',
				startTime: null,
				endTime: null
			};
		}
		this.setState({
			showReserveModal: true,
			volunteerOpportunityId,
			currentOpportunity: opportunity
		});
	}

	deleteOpportunity(opportunityId) {
		swal({
			title: 'Are you sure you want to delete this opportunity?',
			text: "You won't be able to revert this.",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result) {
				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + opportunityId, {
					method: "DELETE",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
				}).then((data) => data.json())
					.then(function (data) {
						if (data.status === 'ok') {
							swal(
								'',
								'The volunteer opportunity was successfully deleted.',
								'success'
							);
							this.fetchOpportunities();
							
							// fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover', {
							// 	method: "GET",
							// 	headers: {
							// 		'Auth': JSON.parse(readCookie('access_token')).access_token
							// 	},
							// }).then(function (response) {
							// 	return response.json();
							// }).then(function (data) {
							// 	this.props.updateOpportunities(data.data);
							// }.bind(this));
						} else {
							swal(
								'Oops!',
								data.message,
								'error'
							);
						}
					}.bind(this));
			}
		}).catch(() => { });
	}
 
fetchOpportunities = (pageNumber) =>	{
	fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover'+(pageNumber?`?pageNumber=${pageNumber}`:''), {
		method: "GET",
		headers: {
			'Auth': JSON.parse(readCookie('access_token')).access_token
		},
	}).then(function (response) {
		return response.json();
	}).then(function (data) {
		this.props.updateOpportunities(data.data, data.totalOpportunities);
	}.bind(this));
}
	// Change page
	paginate = (pageNumber) => {
		this.fetchOpportunities(pageNumber);
		this.props.updateOpportunities(null);
		this.props.updatePageNumber(pageNumber);
		this.setState({
		  currentPage: pageNumber,
		});
	  };
	
	  pageSize = (Number) => {
		this.setState({
		  rowsPerPage: Number,
		});
	  };

	render() {
		// const indexOfLastRow = this.state.currentPage * this.state.rowsPerPage;
		// const indexOfFirstRow = indexOfLastRow - this.state.rowsPerPage;
		// const currentRows = this.props.opportunities.slice(
		//   indexOfFirstRow,
		//   indexOfLastRow
		// );
		if ( this.props.opportunities && this.props.opportunities.length > 0) {
			return (
				<div className="manage-opportunities-container">
					<Row className="ee-single-opportunity-list">
						{this.props.opportunities.map(function (opportunity, i) {
							// {currentRows.map(function (opportunity, i) {
							return (
								<Col md={12} className="ee-single-opportunity" key={i}>
									<Row>
									{/* Removed for Indusind UAT */}
										{/* {opportunity.hasOwnProperty('type') ? (
											<div className="manage-volunteer current-state">
												{opportunity.type === 'LetsEndorse' ? (
													<span className="current-state-outer le">
														<span className="outer-arrow"></span>
														<span className="current-state-text">LetsEndorse</span>
													</span>
												) : (
													<span className="current-state-outer internal">
														<span className="outer-arrow"></span>
														<span className="current-state-text">{opportunity.type}</span>
													</span>
												)}
											</div>
										) : (null)} */}  
										 
										{opportunity.hasOwnProperty('status') ? (
											<div className="manage-volunteer current-state opportunity-status">
												{(() => {
													if (opportunity.status === 'Pending HR Approval') {
														return (
															<span className="current-state-outer submitted">
																<span className="current-state-text">Pending Approval</span>
															</span>
														)
													} else if (opportunity.status === 'Completed') {
														return (
															<span className="current-state-outer completed">
																<span className="current-state-text">Completed</span>
															</span>
														)
													} else if (opportunity.status === 'Live') {
														return (
															<span className="current-state-outer live">
																<span className="current-state-text">Upcoming</span>
															</span>
														)
													} else {
														return (
															<span className = { opportunity.status === 'Cancelled'? "current-state-outer cancelled" : "current-state-outer live" }>
																<span className="current-state-text">{opportunity.status}</span>
															</span>
														)
													}
												})()}
											</div>
										) : (null)}
										{ opportunity.status === 'Completed' && opportunity.absentAttendees > 0 && opportunity.employeeAttendanceOverride ?<div className="volunteer current-state"><span className="current-state-outer live"><span className="outer-arrow"> </span><span className="current-state-text">Admin action pending! </span></span></div>:null}
										<Col md={3} className="ee-single-opportunity-img-container">
											{opportunity.image ? (
												<div className="ee-single-opportunity-img" style={{ backgroundImage: `url('${generatePreSignedGetUrl(opportunity.image)}')`, backgroundSize: 'contain' }}></div>
											) : (
												<div className="ee-single-opportunity-img" style={{ backgroundImage: 'url(https://assets.letsendorse.com/sahaj/project-placeholder.jpg)', backgroundSize: 'contain' }}></div>
											)}
										</Col>
										<Col md={9} className="ee-single-opportunity-details-container">
											<div className="ee-title-section">
												<a className="ee-single-opportunity-title" onClick={() => window.open("/opportunity/" + opportunity.customUrl, "_blank")} title={opportunity.title}>{unescape(opportunity.title)}</a>
											</div>
											<div className="ee-location-date-section">
												{opportunity.physicalOpportunity ? (
													<span className="ee-location">
														<Icon className="pad-right-10" icon={locationArrow} />
														<span>{opportunity.city}</span>
													</span>
												) : (<span className="ee-location">
													<Icon className="pad-right-5" icon={airplay} />Virtual </span>)}
												<span className="ee-calendar">
													<Icon className="pad-right-10" icon={calendar} />
													<span>{moment(opportunity.date, 'DD/MM/YYYY').format('ddd, DD MMM YYYY')}</span>
												</span>
												{opportunity.hasOwnProperty('status') && opportunity.status !== 'Draft' ? (
													<span className="ee-slots">
														{opportunity.status === 'Completed' ? `${opportunity.attendeesLength + opportunity.friendsLength} signups` :
															`${opportunity.volunteersRequired - (opportunity.attendeesLength + opportunity.friendsLength)}/${opportunity.volunteersRequired} slots available`}
													</span>
												) : (null)}
											</div>
											<div className="ee-related-project-section">
												{opportunity.hasOwnProperty('status') && opportunity.status !== 'Draft' ? (
													// window.open("/admin/opportunity/" + opportunity.customUrl, "_blank")
													// <a href={"/admin/opportunity/" + opportunity.customUrl} className="btn-pad-5-20 btn-sq btn-view mr5">View</a>
													<button onClick={() => window.open("/opportunity/" + opportunity.customUrl, "_blank")
														// window.location.pathname = "/admin/opportunity/" + opportunity.customUrl
													} type="button" class="btn btn-outline-primary">View</button>
												) : (null)}
												{opportunity.hasOwnProperty('status') && (opportunity.status === 'Pending HR Approval' || opportunity.status === 'Live' || opportunity.status === 'Completed') ? (
													// <a className="btn-pad-5-20 btn-sq btn-cancel ml5 mr5" data-id={opportunity.id} onClick={(e) => { this.openReserveModal(e, opportunity) }}>
													// 	{opportunity.status === 'Pending HR Approval' ? 'Make Live' : 'Make Unlive'}
													// </a>
													<button data-id={opportunity.id} onClick={(e) => { this.openReserveModal(e, opportunity) }} type="button" class="btn btn-outline-primary ml5 mr5">
														{opportunity.status === 'Pending HR Approval' ? 'Make Live' : opportunity.status === 'Completed' ? 'Update': 'Make Unlive'}
													</button>

												) : (null)}
													{/* edit buuton */}
												{opportunity.type === 'Internal' && opportunity.hasOwnProperty('status') 
												// && (opportunity.status !== 'Completed') 
												? (
													// <a className="btn-pad-5-20 btn-sq btn-cancel ml5 mr5" href={"/admin/volunteer-opportunity/" + opportunity.id}>
													// 	Edit
													// </a>
													<button onClick={() => window.location.pathname = "/admin/volunteer-opportunity/" + opportunity.id} type="button" class="btn btn-outline-primary ml5 mr5">Edit</button>
												) : (null)}

												{opportunity.id && opportunity.type === 'Internal' ? (
													// <a className="btn-pad-5-20 btn-sq btn-le ml5" href={"/admin/volunteer-opportunity/" + opportunity.id + "/clone"} target="_blank">
													// 	Clone
													// </a>
													<button disabled={true} onClick={() => window.location.pathname = "/admin/volunteer-opportunity/" + opportunity.id + "/clone"} type="button" class="btn btn-outline-primary ml5 mr5">Clone</button>
												) : (null)}

												{opportunity.type === 'Internal' && opportunity.status !== 'Completed' ? (
													// <a className="btn-pad-5-20 btn-sq btn-danger btn-le ml10" onClick={this.deleteOpportunity.bind(this, opportunity.id)}>
													// 	Delete
													// </a>
													<button onClick={this.deleteOpportunity.bind(this, opportunity.id)} type="button" class="btn btn-outline-primary ml5 mr5">Delete</button>
												) : (null)}
											</div>
										</Col>
									</Row>
								</Col>
							)
						}.bind(this))}
					</Row>

					{this.state.currentOpportunity ? (
						<MakeLive pageNumber={this.props.pageNumber} volunteerOpportunityId={this.state.volunteerOpportunityId}
							updateOpportunities={this.props.updateOpportunities}
							showReserveModal={this.state.showReserveModal}
							opportunity={this.state.currentOpportunity}
							closeReserveModal={this.closeReserveModal}
							openReserveModal={this.openReserveModal} />
					) : (null)}
					<Pagination rowsPerPage={this.state.rowsPerPage}
								totalRows={this.props.totalOpportunities}
								paginate={this.paginate}
						pageSize={this.pageSize}
						currentPage={this.state.currentPage}
								url="/volunteer-management"/>
				</div>
			);
		} else {
			return (
				<Row>
					<Col md={12} className="no-volunteering-opportunity-container">
						<p className="no-data-text">No Volunteering Opportunities Found!</p>
						<button onClick={() => { window.location.pathname = '/volunteer-opportunity/create' }} className="btn-le btn-sq btn-pad-5-20 m10">START CREATING VOLUNTEERING OPPORTUNITIES</button>
					</Col>
				</Row>
			);
		}
	}
}