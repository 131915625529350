const AWS = require('aws-sdk');
const {Base64} = require('js-base64');

const OPENAPI_KEY=Base64.atob('QUtJQTU3NTVaNjJTR0FUNzVIVk0=')
const OPENAPI_SECRET_KEY=Base64.atob('RkpxakZLdDR6L2FyRHdFVnptU3VoQ3cvWVNFOHNwQkoxclhDRjBZbg==')

AWS.config.update({
    accessKeyId:OPENAPI_KEY,
    secretAccessKey:OPENAPI_SECRET_KEY,
})

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_BUCKET_REGION;
const URL_EXPIRATION_TIME = 120; // in seconds
const s3PrependText = process.env.REACT_APP_S3_PREPEND_TEXT;
const s3PrependTextExpanded = process.env.REACT_APP_S3_PREPEND_TEXT_EXPANDED;

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})
function generatePreSignedPutUrl(fileName, fileType) {
    if (!fileName || typeof fileName !== 'string') return "";
    if (fileName && typeof fileName === 'string' && fileName.includes(s3PrependText)) {
        fileName = fileName.split(s3PrependText)[1];
    } else if (fileName && typeof fileName === 'string' && fileName.includes(s3PrependTextExpanded)) {
        fileName = fileName.split(s3PrependTextExpanded)[1];
    }
    return myBucket.getSignedUrl('putObject', {
        Key: fileName,
        ContentType: fileType,
        Expires: URL_EXPIRATION_TIME
    }, (err, url) => {
        return url // API Response Here
    });
}

function generatePreSignedGetUrl (fileName, fileType) {
    if (!fileName || typeof fileName !== 'string') return "";
    if (fileName && typeof fileName === 'string' && fileName.includes(s3PrependText)) {
        fileName = fileName.split(s3PrependText)[1];
    } else if (fileName && typeof fileName === 'string' && fileName.includes(s3PrependTextExpanded)) {
        fileName = fileName.split(s3PrependTextExpanded)[1];
    }
    
    return myBucket.getSignedUrl('getObject', {
        Key: fileName,
        ContentType: fileType,
        Expires: URL_EXPIRATION_TIME
    });
}


exports.generatePreSignedGetUrl = generatePreSignedGetUrl;
exports.generatePreSignedPutUrl = generatePreSignedPutUrl;