import React, { Component } from 'react';
import { Row, Col, Breadcrumb, Modal, Button, Tabs, Tab, FormControl } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';
import { Menu } from 'antd';
import Gallery from 'react-grid-gallery';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { saveAs } from 'file-saver'
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";
import SingleOpportunityGalleryUploadModal from './SingleOpportunityGalleryUploadModal';

import 'sweetalert2/dist/sweetalert2.min.css';
import { OrgDataContext } from '../../../../../routes.js';
import configs from '../../../../../config/urlConfigs.js';

const readCookie = require('../../../../../cookie.js').readCookie;
let organisationDataContext = null;



export default class SingleOpportunityGallery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			images: [],
			loading: false,
			selectedOpportunity: props.selectedOpportunity,
			// selectedOpportunity: null,
			showModal: false,
			uploadImage: null,
			compressedImage: null,
			caption: '',
			title: '',
			displayImage: null,
			allowUpload: true,
			galleryLimit: 0,
			individualLimit: 0,
			galleryLimitExceeded: true,
			individualLimitExceeded: true,
			currentUserId: null,
			editMode: false,
			uploading: false,
			showLightBox: false,
			lightBoxIndex: 0,
			attendeeData : false, 
			currentUserUploadsCount : 0,
			aactualUploadedImageUrl:null,
			currentUserInfo:JSON.parse(readCookie('userData'))
		}
		this.onGalleryImagesAdded = this.onGalleryImagesAdded.bind(this);
		this.decideAllowUpload = this.decideAllowUpload.bind(this);
		this.toggleUserKey=this.toggleUserKey.bind(this)
	}

	componentDidMount() {
		const userData = JSON.parse(readCookie('userData'));
		// this.fetchImages();
		this.decideAllowUpload(userData,this.props.currentUserUploadsCount, this.props.imageArray);
		let userId = JSON.parse(readCookie('userData')).id;
		let attendee = this.props.selectedOpportunity.attendees.filter((x) => x.id === userId);
		  if(attendee && attendee.length) this.setState({attendeeData :attendee[0]})
	}

	async toggleUserKey(index, obj, like) {
		// Get user ID
		const userData = await JSON.parse(readCookie('userData'));
		const userKey = userData._id;
		console.log(index, like, obj, this.props.images[index], userKey);
	  
		// Create a deep copy of the images array
		const updatedImages = [...this.props.images];
	  
		// Ensure `likeDislike` exists
		 
		 
		console.log(updatedImages[index]);
		this.updateImage1(updatedImages[index],userKey,like)
	  }
	  
	// fetchImages() {
	// 	let that = this;
	// 	let path = this.props.path;
	// 	// fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path + '/analytics', {
	// 	// 	method: "GET",
	// 	// 	headers: {
	// 	// 		'Auth': JSON.parse(readCookie('access_token')).access_token
	// 	// 	}
	// 	// }).then((data) => data.json())
	// 	// 	.then(function (data) {
	// 	// 		if (data.status === 'ok') {
	// 				// this.setState({ selectedOpportunity: data.selectedOpportunity });
	// 				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.state.selectedOpportunity.id + '/gallery-images', {
	// 					method: "GET",
	// 					headers: {
	// 						'Auth': JSON.parse(readCookie('access_token')).access_token
	// 					}
	// 				}).then((data) => data.json())
	// 					.then(function (data) {
	// 						if (data.status === 'ok') {
	// 							var that = this;
	// 							const userData = JSON.parse(readCookie('userData'));
	// 							let imageArray = data.images || [], updatedImages = [];
	// 							let currentUserUploadsCount = 0;
	// 							for (let i = 0; i < imageArray.length; i++) {
	// 								const img = imageArray[i];
	// 								if (userData.id === img.uploadedBy.id) {
	// 									currentUserUploadsCount++;
	// 								}
	// 								const imgObject = {
	// 									src: img.imageUrl,
	// 									actualUploadedImageUrl: img.actualUploadedImageUrl,
	// 									thumbnail: img.compressedImageUrl || img.imageUrl,
	// 									thumbnailWidth: 400,
	// 									thumbnailHeight: 300,
	// 									isSelected: JSON.parse(readCookie('access_token')).id ? true : false,
	// 									// caption: `${img.caption} - uploaded by: ${img.uploadedBy.name}`,
	// 									caption: img.caption,
	// 									uploadedBy: img.uploadedBy
	// 								}
	// 								updatedImages.push(imgObject);
	// 							}
	// 							this.setState({ images: updatedImages })
	// 							this.setState({ currentUserUploadsCount : currentUserUploadsCount });
	// 							this.decideAllowUpload(userData, currentUserUploadsCount, imageArray.length)
	// 						}
	// 						this.setState({ loading: false });
	// 					}.bind(this)).catch(() => { this.setState({ loading: false }) });
	// } 
				// else {
				// 	this.gotoOverviewPage();
				// }
			// }.bind(this));
	// }

	decideAllowUpload(userData, currentUserUploadsCount, imageArray) {

		// this.setState({
		// 	allowUpload: true, individualLimitExceeded: false,
		// 			currentUserId: userData.id
		// });

		this.setState(
			{
				individualLimit: organisationDataContext.eePreferences.individualLimit,
				galleryLimit: organisationDataContext.eePreferences.galleryLimit,
				currentUserId: userData.id
			}
			);
		if (organisationDataContext.eePreferences.galleryLimit > imageArray) {
			this.setState({ galleryLimitExceeded: false });
			if (organisationDataContext.eePreferences.individualLimit > currentUserUploadsCount) {
				this.setState({ allowUpload: true, individualLimitExceeded: false });
			}
		}
		if (currentUserUploadsCount >= organisationDataContext.eePreferences.individualLimit) {
			this.setState({ allowUpload: false, individualLimitExceeded: true });
		}
	}

	sortImages() {
		let images = this.props.images;
		for (var i = 0; i < images.length; i++) {
			images.splice(images[i]['pos'], 0, images[i]);
		}
		this.setState({ images });
	}

	gotoOverviewPage() {
		window.location.pathname = window.location.pathname.split('/gallery')[0];
	}

	gotoAnalyticsPage() {
		window.location.pathname = window.location.pathname.split('/gallery')[0] + '/analytics';
	}
    
	gotoParticipantsPage(){
		window.location.pathname = window.location.pathname.split('/gallery')[0] + '/participants';
	}
	gcd(a, b) {
		return (b == 0) ? a : this.gcd(b, a % b);
	}

	addImagesButtonClick() {
		$('.gallery .gallery-header input#add-gallery-images-input').click();
	}

	onImageChange = async(event) => {
		if (event.target.files && event.target.files[0]) {
			let image = event.target.files[0];
			if ((image.type).includes('hei')) {
				// image =
				this.convertImage(image);
				// .then(async (x) => {
				// 	image = x;
				// 	const options = {
				// 		maxSizeMB: 1,
				// 		maxWidthOrHeight: 1920,
				// 		useWebWorker: true
				// 	}
				// 	try {
				// 		const compressedImage = await imageCompression(image, options);
				// 		this.setState({ compressedImage });
				// 		// data.append('compressedImage', compressedImage); // write your own logic
				// 	} catch (error) {
				// 		console.log(error);
				// 	}
				// 	this.setState({
				// 		// displayImage: URL.createObjectURL(image),
				// 		displayImage: image,
				// 		uploadImage: image
				// 	});
				// });

			}
else {			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			try {
				const compressedImage = await imageCompression(image, options);
				this.setState({ compressedImage });
				// data.append('compressedImage', compressedImage); // write your own logic
			} catch (error) {
				console.log(error);
			}
			this.setState({
				displayImage: URL.createObjectURL(image),
				uploadImage: image
			});
}		}
	}

	downloadImage1 = (url) => {
		// saveAs(imageUrl, 'img.jpg',
		// 	// imageUrl.split('/')[imageUrl.split('/').length - 1]
		// );
		// var a = document.createElement('a');
		// a.setAttribute('href', imageUrl);
		// a.setAttribute('download', imageUrl.split('/')[imageUrl.split('/').length - 1] || '');
		// document.body.appendChild(a);
		// a.onclick = function () { setTimeout(function () { document.body.removeChild(a) }, 100) }
		// a.click();

		// var link = document.createElement('a');
		// link.href = '/img/business.jpg';
		// link.download = 'Download.jpg';
		// document.body.appendChild(link);
		// link.click();
		// document.body.removeChild(link);
		fileName = imageUrl.split('/')[imageUrl.split('/').length - 1] || '';
		var xhr = new XMLHttpRequest();
		xhr.open("GET", url, true);
		xhr.responseType = "blob";
		xhr.onload = function () {
			var urlCreator = window.URL || window.webkitURL;
			var imageUrl = urlCreator.createObjectURL(this.response);
			var tag = document.createElement('a');
			tag.href = imageUrl;
			tag.download = fileName;
			document.body.appendChild(tag);
			tag.click();
			document.body.removeChild(tag);
		}
		xhr.send();
	}

	downloadImageu = async (imageUrl) => {
		imageUrl = imageUrl.replace("le-uploaded-image-bucket.s3.amazonaws.com", "assets.letsendorse.com");
		// console.log("🚀 ~ file: SingleOpportunityGallery.js:249 ~ SingleOpportunityGallery ~ downloadImage= ~ imageUrl:", imageUrl)
	const fileName = imageUrl.split('/')[imageUrl.split('/').length - 1] || '';
	// console.log("🚀 ~ file: SingleOpportunityGallery.js:251 ~ SingleOpportunityGallery ~ downloadImage= ~ fileName:", fileName)

	const a = document.createElement("a");
	a.href = await this.toDataURL(imageUrl);
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	}
	
	downloadImage2(imageUrl) {
		imageUrl = imageUrl.replace("le-uploaded-image-bucket.s3.amazonaws.com", "assets.letsendorse.com");
		// console.log("🚀 ~ file: SingleOpportunityGallery.js:249 ~ SingleOpportunityGallery ~ downloadImage= ~ imageUrl:", imageUrl)
		const fileName = imageUrl.split('/')[imageUrl.split('/').length - 1] || '';
		fetch(imageUrl, {
			mode: 'no-cors',
			// headers: {
			// 	// 'Accept': 'application/json',
			// 	// 'Content-Type': 'application/json'
			// 	"Accept": "image/avif,image/webp,*/*",
			// 	// "Accept-Language": "en-US,en;q=0.5",
			// 	"Sec-Fetch-Dest": "image",
			// 	"Sec-Fetch-Mode": "no-cors",
			// 	"Sec-Fetch-Site": "cross-site",
				
			// }
		}).then((response) => {
		return response.blob().then((b) => {
			const a = document.createElement("a");
			a.setAttribute("download", fileName);
			a.href = URL.createObjectURL(b);
			a.click();
		});
	});
}

	 convertImage = (file) => {
		const blobURL = URL.createObjectURL(file);
		fetch(blobURL)
			.then((res) => res.blob())
			.then((blob) => heic2any({ blob }))
			.then(async(conversionResult) => {
				let image = conversionResult;
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 1920,
					useWebWorker: true
				}
				try {
					const compressedImage = await imageCompression(image, options);
					this.setState({ compressedImage });
					// data.append('compressedImage', compressedImage); // write your own logic
				} catch (error) {
					console.log(error);
				}
				this.setState({
					displayImage: URL.createObjectURL(image),
					// displayImage: image,
					uploadImage: image
				});
			})
			.catch((e) => { 
				console.log(e); this.notifyError('Something went wrong, please try again!');

			});
	};

	convertImage1 = async (file) => {
		// console.log('Converting images!', file);
		const blobURL = URL.createObjectURL(file);
		await fetch(blobURL)
			.then((res) => res.blob())
			.then((blob) => heic2any({ blob }))
			.then(async (conversionResult) => {
				let image = conversionResult;
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 1920,
					useWebWorker: true
				}
				try {
					const compressedImage = await imageCompression(image, options);
					this.setState({ compressedImage });
					// data.append('compressedImage', compressedImage); // write your own logic
				} catch (error) {
					console.log(error);
				}
				this.setState({
					displayImage: URL.createObjectURL(image),
					// displayImage: image,
					uploadImage: image
				});
				return conversionResult;	
				// setImgSrc(conversionResult);
				this.setState({uploadImage: conversionResult})
			})
			.catch((e) => { console.log(e);this.notifyError('Something went wrong, please try again!')});
	}

	 toDataURL = async(url) => {
	const blob = await fetch(url, {mode: ''}).then(res => res.blob());
	return URL.createObjectURL(blob);
}

	downloadImage(imageUrl) {
		
	// Replace "YOUR_S3_BUCKET_URL" with the actual URL of your S3 bucket
	// var s3ImageUrl = 'https://YOUR_S3_BUCKET_URL/image.jpg';

	// Create a temporary anchor element
	// var downloadLink = document.createElement('a');
	// 	downloadLink.href = imageUrl;
	// // downloadLink.target = '_blank'; // Open the image in a new tab
	// downloadLink.download = 'image'+new Date().getMilliseconds() + '.jpg'; // Specify the file name

	// // Append the anchor element to the document body
	// document.body.appendChild(downloadLink);

	// // Trigger the click event to start the download
	// downloadLink.click();

	// // Remove the anchor element from the document body
		// document.body.removeChild(downloadLink);
		console.log(imageUrl);
		window.open(imageUrl, "_blank");
		// fetch(imageUrl)
		// 	.then(response => response.blob())
		// 	.then(blob => {
		// 		// Create a temporary anchor element
		// 		var downloadLink = document.createElement('a');
		// 		downloadLink.href = URL.createObjectURL(blob);
		// 		downloadLink.download = 'image'+new Date().getMilliseconds() + '.jpg'; // Specify the file name

		// 		// Append the anchor element to the document body
		// 		document.body.appendChild(downloadLink);

		// 		// Trigger the click event to start the download
		// 		downloadLink.click();

		// 		// Remove the anchor element from the document body
		// 		document.body.removeChild(downloadLink);
		// 	});
}
	onGalleryImagesAdded(e) {
		let files = e.target.files,
			data = new FormData();
		if (files.length > 0) {
			 if ( /\.(jpe?g|png|heic|heif)$/i.test(files[0].name) === false ) {  
				toast.error("Only png, heic/heif and jpeg formats are accepted.")
               }
    else {
		let imageArr = Array.from(files);
				//  convertImage(file);
				//  exifr
				// 	 .parse(file)
				// 	 .then((output) => {
				// 		 setImgData(output);
				// 	 })
				// 	 .catch((e) => { });
				 imageArr.forEach(async(image) => {
					 if ((image.type).includes('hei')) {
						 image = await this.convertImage(image).then(async(x) => {
							 data.append("image", image);
							 const options = {
								 maxSizeMB: 1,
								 maxWidthOrHeight: 1920,
								 useWebWorker: true
							 }
							 try {
								 const compressedImage = await imageCompression(image, options);
								 data.append('compressedImage', compressedImage); // write your own logic
							 } catch (error) {
								 console.log(error);
							 }
						 } )}
					 else {
			
						 data.append("image", image);
						 //  const imageFile = event.target.files[0];
						 //  console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
						 //  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

						 const options = {
							 maxSizeMB: 1,
							 maxWidthOrHeight: 1920,
							 useWebWorker: true
						 }
						 try {
							 const compressedImage = await imageCompression(image, options);
							 data.append('compressedImage', compressedImage); // write your own logic
						 } catch (error) {
							 console.log(error);
						 }
				}
			});
			swal({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				type: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, Upload!',
				cancelButtonText: 'No, cancel!'
			}).then(function (result) {
				if (result) {
					var toastId = this.notifyInfo(<div>Uploading<i class="fa fa-spin fa-spinner" style="margin-left: 5px;"></i></div>)
					fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.state.selectedOpportunity.id + '/add-gallery-images', {
						method: 'POST',
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
						body: data
					}).then((response) => response.json())
						.then(function (response) {
							if (response['status'] === 'ok') {
								toast.dismiss(toastId);
								let images = this.props.images, newImages = response['images'];
								var that = this;
								newImages.forEach(async (imgObj) => {
									var img = new Image();
									if (!imgObj['thumbUrl']) imgObj['thumbnail'] = imgObj['url'];
									else imgObj['thumbnail'] = imgObj['thumbUrl'];
									img.onload = await function () {
										imgObj['src'] = imgObj['url'];
										imgObj['thumbnailWidth'] = 1;
										imgObj['thumbnailHeight'] = this.height / this.width;
										if (imgObj['createdBy'] && imgObj['createdBy']['name']) {
											imgObj['caption'] = 'Photo By ' + imgObj['createdBy']['name'] + ' - ' + imgObj['createdAt'];
										}
										images.unshift(imgObj);
										that.setState({ images: [] }, function () {
											that.setState({ images });
										});
									};
									img.src = imgObj['thumbnail'];
								});
							} else {
								toast.dismiss(toastId);
								swal(
									'Oops!',
									'Your file(s) could not be uploaded.',
									'error'
								)
							}
						}.bind(this));
				}
			}.bind(this)).catch(swal.noop);
            }
			} else {
			this.notifyError('There were no images selected.')
		}
	}

	addImage() {
		const data = new FormData();
		data.append('image', this.state.uploadImage);
		data.append('compressedImage', this.state.compressedImage);
		data.append('caption', this.state.caption);
		let that = this;
		that.setState({ uploading: true });
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.state.selectedOpportunity.id + '/add-gallery-images', {
			method: 'POST',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: data
		}).then((response) => response.json())
			.then(function (response) {
				if (response['status'] === 'ok') {
					that.setState({
						showModal: false,
						uploadImage: null,
						compressedImage: null,
						caption: '',
						displayImage: null,
						uploading: false
					});
					this.props.fetchImages();
					// toast.dismiss(toastId);
					// window.location.reload();
				} else {
					swal(
						'Oops!',
						'Your file(s) could not be uploaded.',
						'error'
					)
				}
			})
	}

	updateImage() {
		const data = {
			// imageUrl: this.state.displayImage,
			imageUrl :this.state.aactualUploadedImageUrl,
			caption: this.state.caption,
		}
		let that = this;
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.state.selectedOpportunity.id + '/gallery-images', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then((response) => response.json())
			.then(function (response) {
				if (response['status'] === 'ok') {
					that.setState({
						showModal: false,
						caption: '',
						displayImage: null
					});
					that.closeModal();
					that.props.fetchImages();
				} else {
					swal(
						'Oops!',
						'Your file(s) could not be updated.',
						'error'
					)
				}
			})
	}

	updateImage1(obj,userKey,like) {
		const data = {
			// imageUrl: this.state.displayImage,
			imageUrl :obj.actualUploadedImageUrl,
			userKey:userKey,
			like:like,
			updatelike:true
		}
		console.log(data,obj);
		let that = this;
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.state.selectedOpportunity.id + '/gallery-images', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then((response) => response.json())
			.then(function (response) {
				if (response['status'] === 'ok') {
					 
					that.props.fetchImages();
				} else {
					swal(
						'Oops!',
						'Something Went Wrong.',
						'error'
					)
				}
			})
	}

	deleteImage(imageUrl) {
		const data = {
			imageUrl,
			delete: true,
		}
		const that = this;
		swal({
			title: 'Are you sure you want to delete this image?',
			// text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'No, cancel!'
		}).then(function (result) {
			if (result) {
				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + that.state.selectedOpportunity.id + '/gallery-images', {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then((response) => response.json())
					.then(function (response) {
						if (response['status'] === 'ok') {
							// window.location.reload();
							that.props.fetchImages();
						}
						else {
							swal(
								'Oops!',
								'Your file(s) could not be deleted.',
								'error'
							)
						}
					})
			}
		})
	}

	closeModal() {
		this.setState({
			showModal: false,
			uploadImage: null,
			compressedImage: null,
			caption: '',
			displayImage: null,
			editMode:false
		});
		this.props.fetchImages();
	}

	notifyError = (text) => toast.error(text);
	notifyInfo = (text) => toast.info(text);

	handleLightBoxMove(direction) {
		if (direction === 'next') {
			this.setState({ lightBoxIndex: this.state.lightBoxIndex + 1 })
		} else {
			this.setState({ lightBoxIndex: this.state.lightBoxIndex - 1 })
		}
	}

	render() {
		if (this.state.selectedOpportunity !== null && this.state.selectedOpportunity.hasOwnProperty('title')) {
			return (
				<OrgDataContext.Consumer>
					{(organisationData) => {
						organisationDataContext = organisationData;
						return (
              <div className="single-opportunity-gallery-page">
                {/* <DocumentTitle title={this.state.selectedOpportunity.title} />
					<div className="single-vo-menu-container">
						<Menu selectedKeys={["gallery"]} mode="horizontal">
							<Menu.Item key="overview">
								<span onClick={this.gotoOverviewPage}>Overview</span>
							</Menu.Item>
							{window.location.pathname.match('/admin/') !== null && JSON.parse(readCookie('userData')).role === 'admin' ? (
								<Menu.Item key="analytics">
									<span onClick={this.gotoAnalyticsPage}>Analytics</span>
								</Menu.Item>
							) : (null)}
							<Menu.Item key="gallery">
								<span>Gallery</span>
							</Menu.Item>
							<Menu.Item key ="participants">
								<span onClick={this.gotoParticipantsPage}>Participants</span>
							</Menu.Item>
						</Menu>
					</div> */}
                {/* <Col md={12} className="mt30"> */}
                <Col md={12} className="mt30">
                  <ToastContainer
                    position="bottom-right"
                    autoClose={50000}
                    hideProgressBar={true}
                    closeOnClick={false}
                    newestOnTop={false}
                    pauseOnHover={true}
                  />
                  {this.props.loading ? (
                    <div className="data-loader" />
                  ) : (
                    // <i className="fa fa-spin fa-spinner fa-5x"></i>
                    <div className="gallery">
                      <div className="gallery-header">
                        <span
                          className="ee-page-title"
                          style={{ marginTop: 10 }}
                        >
                          Gallery
                        </span>
                        <div>
                          {this.props.isAdmin === "admin" ? (
                            <button
                              className={
                                this.state.allowUpload
                                  ? "btn btn-primary"
                                  : "btn btn-primary-disabled"
                              }
                              onClick={() =>
                                this.state.allowUpload &&
                                this.setState({ showModal: true })
                              }
                            >
                              <i className="fa fa-plus"></i>Add Image(s)
                            </button>
                          ) : this.state.allowUpload &&
                            this.state.attendeeData
                              .updatedParticipatedAttendence === true ? (
                            <button
                              className={
                                this.state.allowUpload
                                  ? "btn btn-primary"
                                  : "btn btn-primary-disabled"
                              }
                              onClick={() =>
                                this.state.allowUpload &&
                                this.setState({ showModal: true })
                              }
                            >
                              <i className="fa fa-plus"></i>Add Image(s)
                            </button>
                          ) : this.state.allowUpload &&
                            this.state.attendeeData &&
                            this.state.attendeeData
                              .updatedParticipatedAttendence === "" ? (
                            <button
                              className={
                                this.state.allowUpload
                                  ? "btn btn-primary"
                                  : "btn btn-primary-disabled"
                              }
                              onClick={() =>
                                this.state.allowUpload &&
                                this.setState({ showModal: true })
                              }
                            >
                              <i className="fa fa-plus"></i>Add Image(s)
                            </button>
                          ) : (
                            <button className="btn btn-primary-disabled">
                              <i className="fa fa-plus"></i>Add Image(s)
                            </button>
                          )}
                        </div>
                        <input
                          className="hidden"
                          type="file"
                          id="add-gallery-images-input"
                          // accept="image/*"
                          accept="image/*,.heic,.heif"
                          onChange={this.onGalleryImagesAdded}
                          multiple
                        />
                      </div>
                      <div align="right">
                        {!this.state.allowUpload && (
                          <>
                            {this.state.galleryLimitExceeded ? (
                              <p style={{ color: "red" }}>
                                Gallery image limit {this.state.galleryLimit}{" "}
                                reached
                              </p>
                            ) : (
                              <p style={{ color: "red" }}>
                                You can only upload {this.state.individualLimit}{" "}
                                image(s) per event
                              </p>
                            )}
                          </>
                        )}
                      </div>
                      <span style={{ fontWeight: "600", fontSize: 16 }}>
                        You can share the images of this event with your
                        colleagues by generating a sharable link. [
                        <CopyToClipboard
                          text={
                            (window.location.href || "").replace(
                              "/admin/",
                              "/"
                            ) + "#gallery"
                          }
                          onCopy={() =>
                            this.props.notifySuccess("Gallery link copied!")
                          }
                        >
                          <i
                            class="fa fa-share-alt"
                            style={{ cursor: "pointer", color: "#84181F" }}
                            aria-hidden="true"
                          >
                            {" "}
                            Click Here
                          </i>
                        </CopyToClipboard>
                        ]
                      </span>
                      {this.props.images.length ? (
                        <div>
                          <Row>
                            {this.props.images.map((imgObj, index) => (
                              <Col md={3} lg={3} style={{ marginTop: 30 }}>
                                <div>
                                  <img
                                    src={imgObj.thumbnail}
                                    onClick={() =>
                                      this.setState({
                                        showLightBox: true,
                                        lightBoxIndex: index,
                                      })
                                    }
                                    style={{
                                      width: "100%",
                                      height: 200,
                                      cursor: "pointer",
                                    }}
                                  />
                                  <div
                                    className="check-gallery"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    {this.props.isAdmin === "admin" ? (
                                      <>
                                        <button
                                          className="btn btn-primary btn-sm"
                                          title="Download image"
                                          onClick={() =>
                                            this.downloadImage(imgObj.src)
                                          }
                                          // onClick={() => this.deleteImage(imgObj.src)}
                                        >
                                          <i
                                            class="fa fa-download"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <button
                                          className="btn btn-primary-delete btn-sm"
                                          onClick={() =>
                                            this.deleteImage(
                                              imgObj.actualUploadedImageUrl
                                            )
                                          }
                                          title="Delete Image"
                                        >
                                          {/* Delete */}
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </>
                                    ) : this.state.currentUserId ===
                                      imgObj.uploadedBy.id ? (
                                      <>
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={() =>
                                            this.setState({
                                              editMode: true,
                                              displayImage: imgObj.src,
                                              caption: imgObj.caption,
                                              showModal: true,
                                              aactualUploadedImageUrl:
                                                imgObj.actualUploadedImageUrl,
                                            })
                                          }
                                          title="Edit Image"
                                        >
                                          {/* edit */}
                                          <i class="fa fa-edit"></i>
                                        </button>
                                        <button
                                          className="btn btn-primary-delete btn-sm"
                                          onClick={() =>
                                            this.deleteImage(
                                              imgObj.actualUploadedImageUrl
                                            )
                                          }
                                          title="Delete Image"
                                        >
                                          {/* Delete */}
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        {/* <a href={imgObj.src}
																	// download={imgObj.src.split('/')[imgObj.src.split('/').length - 1]}
																	target='_blank'> */}
                                        <button
                                          className="btn btn-primary btn-sm"
                                          title="Download image"
                                          onClick={() =>
                                            this.downloadImage(imgObj.src)
                                          }
                                          // onClick={() => this.deleteImage(imgObj.src)}
                                        >
                                          <i
                                            class="fa fa-download"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        {/* </a> */}
                                      </>
                                    ) : (
                                      <></>
                                    )
                                    // <button className='btn btn-primary btn-sm'
                                    // 		title="Download image"
                                    // 		onClick = {()=>this.downloadImage(imgObj.src)}
                                    // 	// onClick={() => this.deleteImage(imgObj.src)}
                                    // 	>
                                    // 		<i class="fa fa-download" aria-hidden="true" ></i>
                                    // 		</button>
                                    }
                                  </div>

                                  <div
                                    className="likebox"
                                    style={{
                                      position: "absolute",
                                      bottom: -10,
                                      right: 10,
                                      gap: 5,
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <button
                                      style={
                                        imgObj.likeDislike &&
                                        imgObj.likeDislike.hasOwnProperty(
                                          this.state.currentUserInfo._id
                                        )
                                          ? { padding: 0 }
                                          : { padding: "6px 10px" }
                                      }
                                      className="btn btn-primary btn-sm"
                                      // onClick = {()=>this.toggleUserKey(index,imgObj,1)}
                                      // onClick={() => this.deleteImage(imgObj.src)}
                                    >
                                      {imgObj.likeDislike &&
                                      imgObj.likeDislike.hasOwnProperty(
                                        this.state.currentUserInfo._id
                                      ) ? (
                                        imgObj.likeDislike[
                                          this.state.currentUserInfo._id
                                        ] === 1 ? (
                                          <img
                                            style={{ width: 32, height: 30,padding:4 }}
                                            src="/img/Like.png"
                                            alt="like"
                                          />
                                        ) : imgObj.likeDislike[
                                            this.state.currentUserInfo._id
                                          ] === 2 ? (
                                          <img
                                            style={{ width: 32, height: 30 }}
                                            src="/img/Celebrate.png"
                                            alt="like"
                                          />
                                        ) : imgObj.likeDislike[
                                            this.state.currentUserInfo._id
                                          ] === 3 ? (
                                          <img
                                            style={{ width: 32, height: 30 }}
                                            src="/img/Insightful2.png"
                                            alt="like"
                                          />
                                        ) : imgObj.likeDislike[
                                            this.state.currentUserInfo._id
                                          ] === 4 ? (
                                          <img
                                            style={{ width: 32, height: 30 }}
                                            src="/img/Love.png"
                                            alt="like"
                                          />
                                        ) : imgObj.likeDislike[
                                            this.state.currentUserInfo._id
                                          ] === 5 ? (
                                          <img
                                            style={{ width: 32, height: 30 }}
                                            src="/img/Inspire.png"
                                            alt="like"
                                          />
                                        ) : imgObj.likeDislike[
                                            this.state.currentUserInfo._id
                                          ] === 6 ? (
                                          <img
                                            style={{ width: 32, height: 30 }}
                                            src="/img/Support.png"
                                            alt="like"
                                          />
                                        ) : (
                                          <i
                                            class="fa fa-thumbs-up"
                                            aria-hidden="true"
                                          ></i>
                                        )
                                      ) : (
                                        <i
                                          class="fa fa-thumbs-up"
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      border: "0.5px solid black",
                                      borderRadius: 50,
                                      padding: "0px 5px",
                                      background:'white'
                                    }}
                                    className="activeonlikehover"
                                  >
                                    <button
                                      style={{
                                        border: "none",
                                        borderRadius: 50,
                                        backgroundColor: "transparent",
                                      }}
                                      className="activeonlikehoverItem"
                                      title="Like"
                                      onClick={() =>
                                        this.toggleUserKey(index, imgObj, 1)
                                      }
                                      // onClick={() => this.deleteImage(imgObj.src)}
                                    >
                                      <img
                                        style={{ width: 27, height: 27,marginLeft:5 }}
                                        src="/img/Like.png"
                                        alt="like"
                                      />
                                    </button>
									<button
                                      style={{
                                        border: "none",
                                        borderRadius: 50,
                                        backgroundColor: "transparent",
                                      }}
                                      className="activeonlikehoverItem"
                                      title="Love"
                                      onClick={() =>
                                        this.toggleUserKey(index, imgObj, 4)
                                      }
                                      // onClick={() => this.deleteImage(imgObj.src)}
                                    >
                                      <img
                                        style={{ width: 40, height: 40 }}
                                        src="/img/Love.png"
                                        alt="love"
                                      />
                                    </button>
                                    <button
                                      style={{
                                        border: "none",
                                        borderRadius: 50,
                                        backgroundColor: "transparent",
                                      }}
                                      className="activeonlikehoverItem"
                                      title="Celebrate"
                                      onClick={() =>
                                        this.toggleUserKey(index, imgObj, 2)
                                      }
                                      // onClick={() => this.deleteImage(imgObj.src)}
                                    >
                                      <img
                                        style={{ width: 40, height: 40 }}
                                        src="/img/Celebrate.png"
                                        alt="like"
                                      />
                                    </button>
									<button
                                      style={{
                                        border: "none",
                                        borderRadius: 50,
                                        backgroundColor: "transparent",
                                      }}
                                      className="activeonlikehoverItem"
                                      title="Inspire"
                                      onClick={() =>
                                        this.toggleUserKey(index, imgObj, 5)
                                      }
                                      // onClick={() => this.deleteImage(imgObj.src)}
                                    >
                                      <img
                                        style={{ width: 30, height: 30 }}
                                        src="/img/Inspire.png"
                                        alt="Inspire"
                                      />
                                    </button>             
                                    <button
                                      style={{
                                        border: "none",
                                        borderRadius: 50,
                                        backgroundColor: "transparent",
                                      }}
                                      className="activeonlikehoverItem"
                                      title="Support"
                                      onClick={() =>
                                        this.toggleUserKey(index, imgObj, 6)
                                      }
                                      // onClick={() => this.deleteImage(imgObj.src)}
                                    >
                                      <img
                                        style={{ width: 40, height: 40 }}
                                        src="/img/Support.png"
                                        alt="Support"
                                      />
                                    </button>
									<button
                                      style={{
                                        border: "none",
                                        borderRadius: 50,
                                        backgroundColor: "transparent",
                                      }}
                                      className="activeonlikehoverItem"
                                      title="Insightful"
                                      onClick={() =>
                                        this.toggleUserKey(index, imgObj, 3)
                                      }
                                      // onClick={() => this.deleteImage(imgObj.src)}
                                    >
                                      <img
                                        style={{ width: 32, height: 30 }}
                                        src="/img/Insightful2.png"
                                        alt="like"
                                      />
                                    </button>
                                  </div>
                                  <p
                                    style={{ paddingLeft: 5, paddingRight: 5 }}
                                  >
                                    {imgObj.caption}
                                  </p>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ) : (
                        <div className="no-images">
                          <span>There are no images to show</span>
                        </div>
                      )}
                    </div>
                  )}
                </Col>
                {this.state.editMode && this.state.showModal && (
                  <Modal
                    show={this.state.showModal}
                    bsSize="md"
                    onHide={() => {
                      this.closeModal();
                    }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Upload Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {!this.state.displayImage && (
                        <FormControl
                          type="file"
                          // accept="image/*"
                          accept="image/*,.heic,.heif"
                          onChange={(e) => this.onImageChange(e)}
                        />
                      )}
                      {this.state.displayImage && (
                        <>
                          <div align="center">
                            <img
                              src={this.state.displayImage}
                              alt="my image"
                              style={{
                                width: "100%",
                                marginTop: 10,
                                objectFit: "contain",
                                maxHeight: 300,
                              }}
                            />
                          </div>
                          <div align="right">
                            {!this.state.editMode && (
                              <button
                                className="btn btn-primary btn-sm sm"
                                onClick={() =>
                                  this.setState({
                                    displayImage: null,
                                    uploadImage: null,
                                    compressedImage: null,
                                  })
                                }
                                style={{ marginTop: 5 }}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </>
                      )}
                      <div class="mb-3" style={{ marginTop: 10 }}>
                        <label
                          for="exampleFormControlTextarea1"
                          class="form-label"
                        >
                          Caption
                        </label>
                        <textarea
                          class="form-control"
                          id="Caption"
                          placeholder="Caption"
                          rows="3"
                          value={this.state.caption}
                          onChange={(e) =>
                            this.setState({ caption: e.target.value })
                          }
                        />
                      </div>
                      <div align="right" style={{ marginTop: 20 }}>
                        {!this.state.uploading ? (
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              this.state.editMode
                                ? this.updateImage()
                                : this.addImage()
                            }
                          >
                            Submit
                          </button>
                        ) : (
                          <div align="center">
                            <i className="fa fa-spin fa-spinner fa-5x"></i>
                          </div>
                        )}
                      </div>
                    </Modal.Body>
                  </Modal>
                )}
                {/*================= lightbox Modal =====================*/}
                <Modal
                  show={this.state.showLightBox}
                  bsSize="lg"
                  onHide={() => this.setState({ showLightBox: false })}
                >
                  {this.props.images.length && (
                    <>
                      <Modal.Header closeButton closeLabel="Close">
                        <Modal.Title>
                          <div align="left">
                            <p>
                              {"By: "}
                              <a
                                href={
                                  this.props.isAdmin > -1
                                    ? "/admin/team-management/profile/" +
                                      this.props.images[
                                        this.state.lightBoxIndex
                                      ].uploadedBy.id
                                    : "/profile/" +
                                      this.props.images[
                                        this.state.lightBoxIndex
                                      ].uploadedBy.id
                                }
                                className="gallery-lightbox-user"
                              >
                                {
                                  this.props.images[this.state.lightBoxIndex]
                                    .uploadedBy.name
                                }
                                {this.props.images[this.state.lightBoxIndex]
                                  .uploadedBy.department
                                  ? `, ${
                                      this.props.images[
                                        this.state.lightBoxIndex
                                      ].uploadedBy.department
                                    }`
                                  : ""}
                              </a>
                            </p>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div
                          style={{
                            height: 300,
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              this.props.images[this.state.lightBoxIndex].src
                            }
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              maxHeight: 300,
                            }}
                          />
                        </div>
                        <p className="gallery-lightbox-caption">
                          {this.props.images[this.state.lightBoxIndex].caption}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 10,
                          }}
                        >
                          {this.state.lightBoxIndex > 0 ? (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => this.handleLightBoxMove("prev")}
                            >
                              Prev
                            </button>
                          ) : (
                            <p></p>
                          )}
                          {this.state.lightBoxIndex <
                            this.props.images.length - 1 && (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => this.handleLightBoxMove("next")}
                            >
                              Next
                            </button>
                          )}
                        </div>
                      </Modal.Body>
                    </>
                  )}
                </Modal>
                {!this.state.editMode && this.state.showModal ? (
                  <SingleOpportunityGalleryUploadModal
                    showModal={this.state.showModal}
                    selectedOpportunity={this.props.selectedOpportunity}
                    hideModal={this.closeModal.bind(this)}
                    allowUpload={this.state.allowUpload}
                    individualLimitExceeded={this.state.individualLimitExceeded}
                    attendeeData={this.state.attendeeData}
                    currentUserUploadsCount={this.props.currentUserUploadsCount}
                    individualLimit={
                      organisationDataContext.eePreferences.individualLimit
                    }
                    fetchImages={() => this.props.fetchImages()}
                  />
                ) : null}
              </div>
            );}
					}
				</OrgDataContext.Consumer>
			);
		} else {
			return null;
		}
	}
}
